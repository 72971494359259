import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Dialog } from "@headlessui/react";
import { LuPlus } from "react-icons/lu";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SelectDropdown from "../../components/common/SelectDropdown";
import ImageUpload from "../../components/common/ImageUpload";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import * as Yup from "yup";
import shared from "./shared";
const AddResources = ({
  setIsOpenResources,
  isOpenResources,
  openResourcesModal,
  submitted,
  showError,
  category,
  setCategory,
  options,
  setCategories,
  setImages,
  images,
  closeResourcesModal,
  formik,
  resourcesForm,
  setShowError,
  setResourcesForm,
}) => {

  const closeModal = () => {
    closeResourcesModal();
  };
  const reactQuillRef = useRef(null);
 
  const handleDomain = (e) => {
    setResourcesForm({ ...resourcesForm, type: e.value });
    if (e) {
      let category_type = "master";
      let url = `categories/list?category_type=${category_type}&type=${e.value}`;
      getCategory(url);
    }
  };
  const getCategory = (url) => {
    loader(true);
    ApiClient.get(url).then((res) => {
      if (res.success) {
        loader(false);
        setCategories(res?.data);
      }
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setResourcesForm({ ...resourcesForm, uploadDocument: [e?.value] });
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot", // Optional if using custom formats
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };
  return (
    <Transition appear show={isOpenResources} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform  rounded-2xl bg-[#F4EAFE] px-6 py-2 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end items-end">
                  <IoCloseCircleOutline
                    onClick={closeModal}
                    className="text-2xl cursor-pointer"
                  />
                </div>
                <div className="mt-2">
                  <div className="modal_inners">
                    <form onSubmit={formik?.handleSubmit}>
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                          <div className="">
                            <input
                              type="text"
                              name="name"
                              placeholder=" Add resource Name"
                              className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                              onChange={formik?.handleChange}
                              onBlur={formik?.onBlur}
                              value={formik?.values?.name}
                            ></input>
                            {showError &&
                            formik.touched.name &&
                            formik.errors.name ? (
                              <span className="text-danger text-xs fs-6">
                                {formik.errors.name}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="quills">
                            <ReactQuill
                              ref={reactQuillRef}
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              value={resourcesForm.discription}
                              onChange={(e) =>
                                setResourcesForm({
                                  ...resourcesForm,
                                  discription: e,
                                })
                              }
                              className=""
                              required
                            />
                            {submitted && !resourcesForm.discription && (
                              <div className="invalid-feedback d-block">
                                Discription is required.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                          <div className="">
                            <textarea
                              name="pros"
                              placeholder=" Add resource pros"
                              className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                              onChange={formik?.handleChange}
                              onBlur={formik?.onBlur}
                              value={formik?.values?.pros}
                            ></textarea>
                            {showError &&
                            formik.touched.pros &&
                            formik.errors.pros ? (
                              <span className="text-danger text-xs fs-6">
                                {formik.errors.pros}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <div className="">
                            <textarea
                              name="cons"
                              placeholder=" Add resource cons"
                              className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                              onChange={formik?.handleChange}
                              onBlur={formik?.onBlur}
                              value={formik?.values?.cons}
                            ></textarea>
                            {showError &&
                            formik.touched.cons &&
                            formik.errors.cons ? (
                              <span className="text-danger text-xs fs-6">
                                {formik.errors.cons}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="filters_all flex items-start  gap-2 mt-6">
                        <div className="flex items-start gap-2">
                          <div className="menus_items">
                            <div className="">
                              <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Domain"
                                intialValue={resourcesForm.type}
                                result={handleDomain}
                                options={shared.types}
                                className="w-full"
                              />
                              {resourcesForm?.type == "" && showError ? (
                                <span className="text-danger text-xs fs-6">
                                  Domain Name is required
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="menus_items">
                            <div className="">
                              <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Module"
                                intialValue={category}
                                result={(e) => {
                                  setCategory(e.value);
                                }}
                                options={options}
                                className="w-full"
                              />
                              {category == "" && showError ? (
                                <span className="text-danger text-xs fs-6">
                                  Module Name is required
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center gap-2 mt-3">
                          <input
                            type="checkbox"
                            className="h-5 w-5 cursor-pointer "
                            style={{ accentColor: "#5C0BAD" }}
                            checked={resourcesForm.priortyResources}
                            onChange={(e) =>
                              setResourcesForm({
                                ...resourcesForm,
                                priortyResources: e.target.checked,
                              })
                            }
                          />
                          <span>Priority Resources</span>
                        </div>
                      </div>

                      <div className="flex items-center  mt-10 mb-8 justify-between">
                        <div className="flex gap-4">
                          <div className="">
                            <label className="underline cursor-pointer text-sm underline-offset-4 relative flex flex-col items-center gap-2">
                              <ImageUpload
                                model="users"
                                name="upload"
                                result={(e) => imageResult(e, "image")}
                                value={images.image}
                                multiple={false}
                              />
                              Upload File
                            </label>
                          </div>

                          <div className="max-w-96">
                            <div className="flex flex-col items-center gap-2">
                              <img
                                src="../assets/img/lyfnew/a.svg"
                                className="h-6"
                              />
                              <input
                                type="text"
                                placeholder="Insert Link"
                                value={resourcesForm.insertLink}
                                onChange={(e) =>
                                  setResourcesForm({
                                    ...resourcesForm,
                                    insertLink: [e?.target?.value],
                                  })
                                }
                                className="border-none text-sm text-center placholder_color bg-transparent p-0 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <button
                            onClick={() => {
                              setShowError(true);
                            }}
                            type="submit"
                            className="bg-primary  px-6 py-2 text-[#fff] text-[16px] font-semibold rounded-full"
                          >
                            Add Resources
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddResources;
