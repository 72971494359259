const types=[
  {id:'self',name:'Self'},
  {id:'social',name:'Social'},
  {id:'wealth',name:'Wealth'},
]

const shared = {
  check: "Category",
  title: "Categories",
  addTitle: "Category",
  url: "category",
  addApi: "category",
  editApi: "category",
  detailApi: "category",
  listApi: "categories/list",
  statusApi: "category",
  deleteApi: "category",
  types:[...types]
};

export default shared;
