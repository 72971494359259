import { RiArrowDropRightLine } from "react-icons/ri";
import Layout from "../../components/global/layout";
import React, { useEffect, useState ,useRef } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Tab } from "@headlessui/react";
import { IoMdCheckmark } from "react-icons/io";
import { useParams } from "react-router-dom";
import shared from "./shared";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import Barchat from "../../components/chart/Barchat";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { GrRotateRight } from "react-icons/gr";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import moment from "moment";
import { useStateManager } from "react-select";
import { FiChevronsLeft } from "react-icons/fi";
import { login_success } from "../../actions/user";
import axios from "axios";
function View() {
  const navigate = useNavigate();
  const { id, name, category } = useParams();
  const user = useSelector((state) => state?.user);
  const [getDataList, setGetDataList] = useState();
  const [getVedioDataList, setGetVedioDataList] = useState();
  const [lengthData, setLengthData] = useState();
  const [quizId, setQuizId] = useState();
  const [recomdedTasks, setRecomdedTasks] = useState();
  const [VideoWatching, setVideoWatching] = useState(false);
  const [SelectedVideo, setSelectedVideo] = useState(null);
  const [selectedName, setSelectedName] = useState();
  const [notificationShow, setNotificationShow] = useState();
  const [watchAllVideos, setWatchedAllVideos] = useState(false);
  const [modalOpen, setmodalopen] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [showRecommend, setShowRecommend] = useState(false);
  const [quizzId, setQuizzId] = useState();
  const [combinationData, setCombinationData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [resourseData, setResourseData] = useState([]);
  const [taskData, setTaskdata] = useState([]);
  const [showTab, setShowTab] = useState("task");
  const [RecommandedEventList, setRecommandedEventList] = useState();
  const [RecomdedResources, setRecomdedResources] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [options, setOptions] = useState(
    Array.from({ length: quizData.length }, () => null)
  );
  const ModuleListCancelTokenSource = useRef(null);
  const RecommandedtaskCancelTokenSource = useRef(null);
  const RecommandedeventCancelTokenSource = useRef(null);
  const RecommandedresourcesCancelTokenSource = useRef(null);
  const notifyListCancelTokenSource = useRef(null);
  useEffect(() => {
    let element = document.getElementById("myElement");
    element.classList.remove("mainarea");
    element.classList.add("mainarea_views");
  });
  // const getData = (p) => {
  //   loader(true);
  //   ApiClient.get(
  //     `${shared?.sub_categoryApi}?category_type=${shared?.subCategory_type}&category_id=${p}&user=${user?.id}`
  //   ).then((res) => {
  //     const data = res?.data?.find((ele) => !ele?.isWatched);
  //     setLengthData(res);
  //     setSelectedName(data?.name);
  //     handleVedio(data?.id);
  //     setGetDataList(res?.data);
  //     loader(false);
  //     const allWatched =
  //       res.data?.length > 0
  //         ? res?.data?.every((item) => item.isWatched === true)
  //         : "";
  //     setWatchedAllVideos(allWatched);
  //   });
  // };
  const getData = (p) => {
    loader(true);
    ApiClient.get(
      `${shared?.sub_categoryApi}?category_type=${shared?.subCategory_type}&category_id=${p}&user=${user?.id}&status=active` ,"","","",ModuleListCancelTokenSource.current.token).then((res) => {
      setSubCategoryId(res?.data?.[0]?.id);
      // Check if all items are watched
      const allWatched =
        res.data?.length > 0
          ? res.data.every((item) => item.isWatched === true)
          : false;
      let data;
      if (allWatched) {
        // If all items are watched, use the first item in the array
        data = res.data[0];
      } else {
        // Otherwise, find the first item where isWatched is false or undefined
        data = res?.data?.find((ele) => !ele?.isWatched);
      }
      setLengthData(res);
      setSelectedName(data?.name);
      handleVedio(data?.id);
      setGetDataList(res?.data);
      setWatchedAllVideos(allWatched);
      loader(false);
    });
  };

  const handleVedio = (ele) => {
    if (ele) {
      ApiClient.get(
        `${shared?.vedioListApi}?category=${id}&sub_category=${ele}`
      ).then((res) => {
        setGetVedioDataList(res?.data);
      });
    }
  };
  const dispatch = useDispatch();
  const gallaryData = () => {
    loader(true);
    ApiClient.get("user/" + user.id, {}).then(async (res) => {
      if (res.success) {
        let data = { ...user, ...res.data };
        dispatch(login_success(data));
      }
    });
  };
  const handleVedioData = (ele) => {
    let payload = {
      category: ele?.category?.id.toString(),
      sub_category: ele?.sub_category?.id.toString(),
      user: user?.id.toString(),
      video: ele?.id.toString(),
    };
    if (VideoWatching) {
      return;
    }
    ApiClient.post(`${shared?.completeVedio}`, payload).then((res) => {
      // setVideoWatching(true);
      gallaryData();
      getData(ele?.category?.id.toString());
      let payload = {
        notifyTo: user?.id,
        type: "quiz",
        category: id,
      };
      ApiClient.get(`${shared?.notificationList}`, payload ,"","",notifyListCancelTokenSource.current.token).then((res) => {
        setNotificationShow(res?.data[0]);
        setQuizId(res?.data[0]?.quiz);
      });
    });
  };
  useEffect(() => {
    ModuleListCancelTokenSource.current = axios.CancelToken.source();
    getData(id);
    return () => {
      if (ModuleListCancelTokenSource.current) {
        ModuleListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      
    };
  }, [id]);

  useEffect(() => {
    notifyListCancelTokenSource.current = axios.CancelToken.source();
    handleGetNotification();
    return () => {
      if (notifyListCancelTokenSource.current) {
        notifyListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      
    };
  }, []);

  const handleGetNotification = () => {
    let payload = {
      notifyTo: user?.id,
      type: "quiz",
      category: id,
    };
    ApiClient.get(`${shared?.notificationList}`, payload , "","",notifyListCancelTokenSource.current.token).then((res) => {
      setQuizId(res?.data[0]?.quiz);
      setNotificationShow(res?.data[0]);
      
    });
  };

  const handleQuiz = () => {
    if (watchAllVideos) {
      if (quizId) {
        handleQuizList();
        let payload = {
          status: "deactive",
        };
        ApiClient.put(`${shared?.deleteNotification}/${quizId}`, payload).then(
          (res) => {}
        );
        navigate(`/quiz/${name}/${category}/${id}/${subCategoryId}`, {
          state: quizId,
        });
        handleQuizSaveList();
      }
    } else {
      toast?.error("Please watch all the videos before creating quiz");
    }
  };

  const handleQuizList = () => {
    loader(true);
    ApiClient.get(`${shared?.quizdetail}/${quizId}`).then((res) => {
      if (res?.success == true) {
        loader(false);
        setQuizData(res?.data[0]?.question || []);
        setQuizzId(res?.data[0]?.id || "");
        setCombinationData(res?.data[0]?.permutationCombination || []);
      }
    });
  };
  const checkAndStoreData = (data, sets) => {
    const setNameToCheck = sets.join("");
    const matchedSet = data.find((item) => item.setName === setNameToCheck);
    if (matchedSet) {
      let payload = {
        user: user ? JSON?.stringify(user?.id) : "",
        quiz: quizzId ? JSON?.stringify(quizzId) : "",
        quizResult: setNameToCheck,
        resources: matchedSet.resources,
        task: matchedSet.task,
        event: matchedSet.event,
      };
      loader(true);
      ApiClient.post(`quizsave`, payload).then((res) => {
        if (res?.success == true) {
          loader(false);

         gallaryData()
          // setShowRecommend(!showRecommend);
          handleQuizSaveList();
        } else {
          toast?.error(res?.message);
        }
      });
    } else {
    }
  };

  const handleNextOrSave = () => {
    if (currentQuizIndex === quizData.length - 1) {
      checkAndStoreData(combinationData, options);
    } else {
      if (options[currentQuizIndex] === null) {
        toast?.error("Please select an option first");
      } else {
        setCurrentQuizIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handlePrevious = () => {
    setCurrentQuizIndex((prevIndex) => prevIndex - 1);
  };

  const handleQuizSaveList = () => {
    let payload = {
      user: user ? JSON?.stringify(user?.id) : "",
      quiz: quizId ? quizId : "",
    };
    loader(true);
    ApiClient.get(`${shared?.quizsavelist}`, payload).then((res) => {
      if (res?.success == true) {
        if (res?.data?.length > 0) {
          setShowRecommend(true);
          loader(false);
          gallaryData()
          setTaskdata(res?.data[0]?.task || []);
          setEventData(res?.data[0]?.event || []);
          setResourseData(res?.data[0]?.resources || []);
        } else {
          handleQuizList();
        }
      } else {
      }
    });
  };
  const handleOptionSelect = (option) => {
    const newOptions = [...options];
    newOptions[currentQuizIndex] = option;
    setOptions(newOptions);
  };

  const handleCloseModal = () => {
    setmodalopen(false);
  };
  const recommendedTaskList = () => {
    let payload = {
      user: user?.id,
      quiz: quizId,
      category: id,
      status: "active",
    };

    ApiClient.get(`${shared?.recommandedTasklistApi}`, payload ,"","",RecommandedtaskCancelTokenSource.current.token).then((res) => {
      setRecomdedTasks(res?.data);
    });
  };
  const RecommendEventList = () => {
    loader(true);
    let payload = {
      user: user?.id,
      quiz: quizId,
      category: id,
      status: "active",
    };
    ApiClient.get(`${shared?.recommandedlistApi}`, payload ,"","",RecommandedeventCancelTokenSource.current.token).then((res) => {
      setRecommandedEventList(res?.data);
      loader(false);
    });
  };
  const recommendResourseList = () => {
    let payload = {
      user: user?.id,
      quiz: quizId,
      category: id,
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.recommandedResorceslistApi}`, payload ,"","",RecommandedresourcesCancelTokenSource.current.token).then(
      (res) => {
        setRecomdedResources(res?.data);
      }
    );
  };

  useEffect(() => {
    RecommandedtaskCancelTokenSource.current = axios.CancelToken.source();
    RecommandedeventCancelTokenSource.current = axios.CancelToken.source();
    RecommandedresourcesCancelTokenSource.current = axios.CancelToken.source();
    recommendedTaskList();
    RecommendEventList();
    recommendResourseList();
    return () => {
      if (RecommandedtaskCancelTokenSource.current) {
        RecommandedtaskCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (RecommandedeventCancelTokenSource.current) {
        RecommandedeventCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (RecommandedresourcesCancelTokenSource.current) {
        RecommandedresourcesCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  
  }, [quizId]);

  return (
    <>
      <Layout>
        <div className="views_learings h-full relative">
          <Tab.Group>
            <div className="flex justify-between xl:h-full gap-4 relative">
              <div className="px-8 py-4 w-full">
                <Tab.List>
                  <div className="flex justify-between ">
                    <div className="flex items-center gap-8">
                      <div
                        className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                          name === "wealth" && " bg-[#58A541] !text-neutral-100"
                        }`}
                      >
                        Wealth
                      </div>
                      <div
                        className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                          name === "self" && " bg-[#0079B3] !text-neutral-100"
                        }`}
                      >
                        Self
                      </div>
                      <div
                        className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                          name === "social" && " bg-[#FFA03B] !text-neutral-100"
                        }`}
                      >
                        Social
                      </div>
                    </div>
                    <div className="seargings">
                      <div className="border flex items-center gap-2 border-neutral-400 p-3 rounded-full">
                        <img src="/assets/img/lyfnew/search.svg" />
                        <input
                          type="search"
                          className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-32"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className="flex justify-center h-full">
                      <div className="w-full mt-8">
                        <div className="flex items-center gap-2 mb-8">
                          <BsChevronLeft
                            onClick={() => navigate(-1)}
                            className="text-[16px] 2xl:text-[22px] cursor-pointer"
                          />
                          <div className="flex  text-[16px] 2xl:text-[22px] items-center gap-2">
                            <span className="font-semibold capitalize">
                              {category}
                            </span>
                            <span className="font-semibold">
                              <RiArrowDropRightLine className="text-[16px] 2xl:text-[22px]  " />
                            </span>
                            <span className="font-semibold capitalize">
                              {selectedName}
                            </span>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-8">
                          <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            <div className="flex flex-col gap-4">
                              <div className="">
                                <div className="wealth_checks">
                                  <div className="flex flex-col gap-2 2xl:gap-6 flex-wrap  overflow-x-auto">
                                    {getDataList?.map((ele, ind) => (
                                      <div key={ele.id} className="checksboxes">
                                        <label
                                          onClick={() => {
                                            handleVedio(ele?.id);
                                            setSelectedVideo(ele?.id);
                                            setVideoWatching(ele?.isWatched);
                                            setSelectedName(ele?.name);
                                          }}
                                          className={`w-full border border-neutral-300 font-regular text-[12px] 2xl:text-[18px] ${
                                            selectedName === ele.name
                                              ? " bg-neutral-200"
                                              : " bg-neutral-100"
                                          } rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4 `}
                                        >
                                          <input
                                            className="sr-only peer"
                                            name=""
                                            type="checkbox"
                                            checked={
                                              ele.isWatched === true

                                              // ||   SelectedVideo == ele?.id
                                            }
                                          />
                                          <div
                                            className={`w-5  h-5 2xl:w-7 2xl:h-7 text-xl text-white flex items-center justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100 ${
                                              name === "wealth"
                                                ? " border-[#58A541]  peer-checked:bg-[#58A541] "
                                                : ""
                                            }${
                                              name === "self"
                                                ? " border-[#0079B3]  peer-checked:bg-[#0079B3] "
                                                : ""
                                            }${
                                              name === "social"
                                                ? " border-[#FFA03B]  peer-checked:bg-[#FFA03B] "
                                                : ""
                                            }`}
                                          >
                                            <IoMdCheckmark />
                                          </div>
                                          <p>{ele.name}</p>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="summary_data border border-neutral-300 p-4 rounded-[16px] mt-8">
                              <p className="mb-6 text-[16px] 2xl:text-[24px] font-[700] text-[#727272]">
                                Summary
                              </p>
                              {getVedioDataList?.map((ele) => {
                                return (
                                  <div className="custom-scrollbar-new">
                                    <p className="text-[12px] 2xl:text-[18px] text-neutral-800">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: ele?.summary
                                            ? ele?.summary
                                            : "There is no summary available.",
                                        }}
                                      />
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            {getVedioDataList?.map((ele) => {
                              return (
                                <div className="videos_tags w-[100%]">
                                  <video
                                    controls
                                    width="100%"
                                    height="300px"
                                    src={methodModel.noImg(ele?.video)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    onEnded={() => {
                                      handleVedioData(ele);
                                    }}
                                  ></video>
                                </div>
                              );
                            })}

                            <div className="chatrs_expls mt-8">
                              <div className="bg-gray-100 overflow-hidden relative group rounded-lg p-2">
                                {/* <h5 className="font-semibold text-[16px]  2xl:text-[27px]">
                                  Chart example
                                </h5>
                                <p className="font-regular text-[13px]  2xl:text-[20px]">
                                  Supporting text
                                </p> */}
                                
                                 <img
                                  src={methodModel.userImg(getVedioDataList && getVedioDataList[0]?.image)}
                                  className="w-full  shadow-lg object-contain bg-[#a1282f24] transform transition-transform duration-300 ease-in-out group-hover:scale-110"
                                />
                              </div>
                              {/* <Barchat /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </div>

              <div className="bg-[#FFFDF5] px-2 lg:px-6">
                <div className="flex flex-col h-full gap-4 justify-center items-center">
                  <div className="flex items-center justify-center gap-1">
                    <p>
                      <FiChevronsLeft />
                    </p>
                    <div
                      className="relative cursor-pointer"
                      onClick={() => {
                        handleQuiz();
                      }}
                    >
                      {notificationShow?.isDeleted == false ? (
                        <img
                          className="h-20 object-contain"
                          src="/assets/img/lyfnew/lock.svg"
                        />
                      ) : (
                        <img
                          className="h-20 object-contain grayscale"
                          src="/assets/img/lyfnew/lock.svg"
                        />
                      )}

                      {/* {notificationShow?.isDeleted != true ? (
                      <div className="absolute top-0 bg-red-500 text-xs right-0 shadow  rounded-full p-2.5 "></div>
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                </div>
              </div>

              {modalOpen ? (
                <div className="absolute right-0 top-0 bg-black/30 h-screen  w-full overflow-x-hidden overflow-y-auto">
                  <div className="flex flex-col lg:flex-row  h-full">
                    <div className="flex w-full h-full overflow-auto flex-col gap-6 mb-8 bg-white border-r border-gray-200 xl:px-6 px-2 xl:py-6 py-2">
                      <div className="flex gap-4">
                        <div className="flex items-center gap-4">
                          <div className="px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer bg-[#58A541] !text-neutral-100 ">
                            Wealth
                          </div>
                          <div className="px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer  bg-[#0079B3] !text-neutral-100 ">
                            Self
                          </div>
                          <div className="px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer bg-[#FFA03B] !text-neutral-100">
                            Social
                          </div>
                        </div>
                        <div className="seargings">
                          <div className="border flex items-center gap-2 border-neutral-400 p-3 rounded-full">
                            <img src="/assets/img/lyfnew/search.svg" />
                            <input
                              type="search"
                              className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-32"
                              placeholder="Search"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-6 mb-8">
                        <BsChevronLeft className="text-[25px]" />
                        <div className="flex text-[25px] items-center gap-2">
                          <span className="font-semibold">sdgfdg</span>
                          <span className="font-semibold">
                            <RiArrowDropRightLine />
                          </span>
                          <span className="font-semibold">gdsfg</span>
                        </div>
                      </div>

                      <div className="videos_tags w-[100%]">
                        <video
                          controls
                          width="100%"
                          height="250px"
                          src=""
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></video>
                      </div>

                      <div className="summary_data border border-neutral-300 p-4 rounded-[16px] mt-4">
                        <p className="mb-6 text-[16px] 2xl:text-[24px] font-[700] text-[#727272]">
                          Summary
                        </p>

                        <div className="custom-scrollbar-new">
                          <p className="text-[12px] 2xl:text-[18px] text-neutral-800">
                            Lorepum ipsum
                          </p>
                        </div>
                      </div>

                      <div className="chatrs_expls mt-4">
                        <div className="">
                          <h5 className="font-semibold text-[16px] 2xl:text-[27px]">
                            Chart example
                          </h5>
                          <p className="font-regular text-[20px]">
                            Supporting text
                          </p>
                        </div>
                        <Barchat />
                      </div>
                    </div>

                    <div className="h-full flex items-center justify-center bg-[#f8f9fb]">
                      <MdKeyboardDoubleArrowRight
                        onClick={() => handleCloseModal()}
                        className="h-8 w-8 cursor-pointer"
                      />
                    </div>
                    <div className="bg-white border-l border-gray-200 w-full h-full ">
                      <div className="ques_ions l w-full  bg-white xl:px-6 px-2 xl:py-6 py-2">
                        {!showRecommend ? (
                          <div className="mb-4">
                            <div className=" mb-4 first_appears">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: quizData[currentQuizIndex]?.question,
                                }}
                                className="flex items-center gap-2 text-[22px] text-neutral-800 font-regular"
                              ></p>
                            </div>
                            <div className="checksboxes w-full mb-4">
                              <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                                <input
                                  className="sr-only peer"
                                  checked={options[currentQuizIndex] === "A"}
                                  onChange={() => handleOptionSelect("A")}
                                  type="radio"
                                />

                                <div className="w-5 h-5 text-xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                                  <IoMdCheckmark className="" />
                                </div>
                                <p
                                  className="text-[14px] xl:text-[18px] line-clamp-1"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      quizData[currentQuizIndex]?.option_1,
                                  }}
                                ></p>
                              </label>
                            </div>
                            <div className="checksboxes w-full mb-4">
                              <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                                <input
                                  className="sr-only peer"
                                  checked={options[currentQuizIndex] === "B"}
                                  onChange={() => handleOptionSelect("B")}
                                  type="radio"
                                />

                                <div className="w-5 h-5 text-xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                                  <IoMdCheckmark className="" />
                                </div>
                                <p className="text-[14px] xl:text-[18px] line-clamp-1">
                                  {quizData[currentQuizIndex]?.option_2}
                                </p>
                              </label>
                            </div>
                            <div className="checksboxes w-full mb-4">
                              <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                                <input
                                  className="sr-only peer"
                                  checked={options[currentQuizIndex] === "C"}
                                  onChange={() => handleOptionSelect("C")}
                                  type="radio"
                                />

                                <div className="w-5 h-5 text-xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                                  <IoMdCheckmark className="" />
                                </div>
                                <p className="text-[14px] xl:text-[18px] line-clamp-1">
                                  {quizData[currentQuizIndex]?.option_3}
                                </p>
                              </label>
                            </div>
                            <div className="flex items-center justify-between">
                              {currentQuizIndex >= 1 ? (
                                <button
                                  className="bg-primary flex items-center gap-1 px-6 py-2 rounded-xl text-white text-sm"
                                  onClick={handlePrevious}
                                >
                                  <GoChevronLeft />
                                  Previous
                                </button>
                              ) : (
                                ""
                              )}
                              <p>{`${currentQuizIndex + 1}/${
                                quizData.length
                              }`}</p>
                              <button
                                className="bg-primary px-6 py-2 rounded-xl text-white text-sm"
                                onClick={handleNextOrSave}
                              >
                                {currentQuizIndex === quizData.length - 1 ? (
                                  "Save"
                                ) : (
                                  <>
                                    <p className="flex items-center gap-1">
                                      Next
                                      <GoChevronRight />
                                    </p>
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="bg_cards after_questin_update">
                              <p className="font-semibold text-[28px] mb-6">
                                Lifestyle quiz
                              </p>
                              <div className="cards_news flex items-center justify-between bg-[#5C0BAD] rounded-[16px] px-4 py-4 w-full">
                                <div className="flex flex-col gap-2">
                                  <p className="text-[22px] xl:text-[32px] text-white">
                                    Congratulations!
                                  </p>
                                  <p className="text-white text-[14px]">
                                    See your recommendations below!
                                  </p>
                                  <div className="mt-2">
                                    <p className="bg-[#9B51E0] cursor-pointer inline-flex px-4 flex gap-2 items-center rounded-full text-white py-1">
                                      Go again? <GrRotateRight />{" "}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src="/assets/img/lyfnew/sparkles.svg"
                                    className="w-32"
                                  />
                                </div>
                              </div>
                              {/* <p>5 of 5</p> */}
                            </div>
                            <div className="wizards mt-8">
                              <p className="font-semibold text-[28px] mb-6">
                                Recommendations
                              </p>

                              <Tab.Group>
                                <Tab.List className="flex gap-2">
                                  <Tab
                                    onClick={() => {
                                      setShowTab("task");
                                    }}
                                    className={`${
                                      showTab == "task"
                                        ? "bg-[#9B51E0] text-white"
                                        : "bg-white"
                                    } border border-neutral-300 rounded-md py-2 px-4`}
                                  >
                                    Tasks
                                  </Tab>
                                  <Tab
                                    onClick={() => {
                                      setShowTab("resourse");
                                    }}
                                    className={`${
                                      showTab == "resourse"
                                        ? "bg-[#9B51E0] text-white"
                                        : "bg-white"
                                    } border border-neutral-300 rounded-md py-2 px-4`}
                                  >
                                    Resources
                                  </Tab>
                                  <Tab
                                    onClick={() => {
                                      setShowTab("event");
                                    }}
                                    className={`${
                                      showTab == "event"
                                        ? "bg-[#9B51E0] text-white"
                                        : "bg-white"
                                    } border border-neutral-300 rounded-md py-2 px-4`}
                                  >
                                    Events
                                  </Tab>
                                </Tab.List>
                                <Tab.Panels className="mt-4">
                                  <Tab.Panel>
                                    {taskData.map((item) => (
                                      <div
                                        key={item?.id}
                                        className="border border-neutral-300 rounded-[8px] p-3"
                                      >
                                        <p className="text-wealth text-[10px] font-medium mb-1">
                                          {item?.type}
                                        </p>
                                        <div className="images_rows flex items-start gap-4">
                                          <img src="/assets/img/lyfnew/user.png" />
                                          <div>
                                            <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                {moment(
                                                  item?.created_at
                                                ).format("DD/MM")}
                                              </p>
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                North University
                                              </p>
                                            </div>
                                            <p className="text-[12px] text-neutral-800">
                                              Bussiness talk about development
                                              with Kate Musk
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Tab.Panel>
                                  <Tab.Panel>
                                    {resourseData.map((item) => (
                                      <div
                                        key={item?.id}
                                        className="border border-neutral-300 rounded-[8px] p-3"
                                      >
                                        <p className="text-wealth text-[10px] font-medium mb-1">
                                          {item?.type}
                                        </p>
                                        <div className="images_rows flex items-start gap-4">
                                          <img src="/assets/img/lyfnew/user.png" />
                                          <div>
                                            <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                {moment(
                                                  item?.created_at
                                                ).format("DD/MM")}
                                              </p>
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                {item?.address}
                                              </p>
                                            </div>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: item.discription,
                                              }}
                                              className="text-[12px] text-neutral-800"
                                            ></p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Tab.Panel>
                                  <Tab.Panel>
                                    {eventData.map((item) => (
                                      <div
                                        key={item?.id}
                                        className="border border-neutral-300 rounded-[8px] p-3"
                                      >
                                        <p className="text-wealth text-[10px] font-medium mb-1">
                                          {item?.type}
                                        </p>
                                        <div className="images_rows flex items-start gap-4">
                                          <img src="/assets/img/lyfnew/user.png" />
                                          <div>
                                            <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                {moment(
                                                  item?.created_at
                                                ).format("DD/MM")}
                                              </p>
                                              <p className="text-[12px] text-neutral-600 font-regular">
                                                {item?.address}
                                              </p>
                                            </div>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: item.description,
                                              }}
                                              className="text-[12px] text-neutral-800"
                                            ></p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Tab.Panel>
                                </Tab.Panels>
                              </Tab.Group>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Tab.Group>
        </div>
      </Layout>
    </>
  );
}

export default View;
