import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { IoCloseOutline } from "react-icons/io5";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import shared from "./shared";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
// import demoimage from ".././../../public/assets/img/demo.png"
function View({
  openModal,
  closeModal,
  data,
  hideAddButton,
  handleMoveTask,
  handleaddToMyTask,
  isLayout = true,
  DiscoverTaskList,
  priorityTaskList,
}) {

  const handleDelete = (ele) => {
    loader(true);
    ApiClient.delete(`${shared?.userDelete}/${ele}`).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        closeModal();
        loader(false);
        DiscoverTaskList(2);
        priorityTaskList();
      } else {
        toast.error(res?.message);
      }
    });
  };

  // Function to render the modal content
  const renderModalContent = () => (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white px-6 py-2 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end items-end">
                  <IoCloseOutline
                    onClick={closeModal}
                    className="text-2xl cursor-pointer"
                  />
                </div>
                <div className="mt-2">
                  <div className="modal_inners">
                    <div className="flex gap-4 items-start">
                      <div className="imsg_data shrink-0">
                        
                        <img
                          className="h-56 w-32 object-cover"
                          src={
                            data?.uploadDocument?.[0]
                              ? `${environment?.api}${data?.uploadDocument[0]} `
                              : "../../assets/img/demo.png"
                          }
                          alt={data?.type}
                        />
                      </div>

                      <div className="flex flex-col gap-4">
                        <h2 className="text-[16px] xl:text-[20px] text-[#004AAD] 2xl:text-[30px] capitalize ">
                          {hideAddButton == "ArchivedTask"
                            ? data?.task?.type
                            : data?.type}
                        </h2>

                        <div className="decriptions mt-4">
                          <h4 className="text-[14px] mb-4 text-[#404040] font-[800] lg:text-[18px]">
                            Description
                          </h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                hideAddButton == "ArchivedTask"
                                  ? data?.task?.discription
                                  : data?.discription,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>

                    <div className="px-10 pt-16 py-6 flex justify-between">
                      <div>
                        {data?.insertLink?.[0] ? (
                          <div>
                            <h5>Link</h5>
                            <p> {data?.insertLink?.[0]}</p>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="mt-4">
                          {(hideAddButton === "MyTasks" ||
                            hideAddButton === "PriorityTasks") && (
                            <p
                              className="bg-gray-200 w-32 text-center px-2 py-1 text-sm"
                              onClick={() => handleMoveTask(data?.id)}
                            >
                              Archive Task
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="flex justify-end items-end flex-col gap-4">
                          {hideAddButton !== "MyTasks" &&
                            hideAddButton !== "PriorityTasks" && (
                              <button
                                className="bg-primary px-4 py-2 text-white text-sm rounded-full"
                                onClick={() => handleaddToMyTask(data?.id)}
                              >
                                Add to My Task
                              </button>
                            )}
                          {hideAddButton == "MyTasks" ? (
                            <button onClick={() => handleDelete(data?.id)}>
                              Delete
                            </button>
                          ) : (
                            ""
                          )}
                          {/* <div className="flex text-primary text-sm items-center gap-4">
                            <button>Download</button>
                            <button>Share with friends</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  return isLayout ? (
    <Layout>{renderModalContent()}</Layout>
  ) : (
    renderModalContent()
  );
}

export default View;
