import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import shared from "./shared";
import loader from "../../methods/loader";

const View = () => {
    const [data, setData] = useState()
    const history = useNavigate()
    const { id } = useParams()

    const getDetail = () => {
        loader(true)
        ApiClient.get(`${shared.detailApi}/${id}`, { id: id }).then(res => {
            loader(false)
            if (res.success) {
                setData(res.data)
            }
        })
    }

    useEffect(() => {
        getDetail()
    }, [])

    return <>
        <Layout>
        
                <div className="">
                <h3 className="flex justify-between items-center py-2 bg-[#283A97] rounded-[30px] px-5 text-[#fff]"><span>{shared.title} Details</span>
                
                <span className="cursor-pointer" onClick={() => history(-1)}>  <i className="fa fa-arrow-left text-[#fff] " title='Back' aria-hidden="true"></i></span>
                </h3>
                  
                </div>
            <div className='bg-white shadow-box rounded-lg w-full p-4 mt-6'>

                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">
                      
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Name</label>
                                <div className='profiledetailscls capitalize'>{data?.name || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Type</label>
                                <div className='profiledetailscls capitalize'>{data?.type || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Created Date</label>
                                <div className='profiledetailscls'>{datepipeModel.date(data?.created_at)}</div>
                            </div>
                        

                        </div>


                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default View;