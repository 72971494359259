import { createStore } from "redux";

import PersistReducer from "../Reducer/Main";
import { persistStore } from "redux-persist";

const Store = createStore(PersistReducer);

const persistor = persistStore(Store);

export { Store, persistor };
