import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss';
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/AuthLayout';
import { toast } from 'react-toastify';


const Forgotpassword = () => {
  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history('/dashboard')
    }
  }, [])

  const [form, setForm] = useState({ email: '' });

  useEffect(() => {

  }, [])

  const hendleSubmit = (e: any) => {
    e.preventDefault();
    loader(true)

    ApiClient.put('forgot/password',{},form).then(res => {
      if (res.success) {
        history('/resetpassword');
        setTimeout(() => {
          toast.success(res.message)
        }, 100);
      }
      loader(false)
    })
  };

  return (
    <>

      <AuthLayout>
        <form
          className="max-w-lg mx-auto overflow-y-auto px-4 py-4"
          onSubmit={hendleSubmit}
        >
          <div className=" mb-4">

          

            <h3 className="mb-4 text-[18px] text-[32px] font-regular  text-neutral-800">Forgot Password</h3>
            <p className=''>No worries! Just enter your email and we’ll send you a reset password link.</p>
          </div>
        
          <div className="mb-3">
          <label className='text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]  '>Email</label>
            <div className="inputWrapper">
              <input
                type="email"
                className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1" placeholder='Email*'
                value={form.email}
                required
                onChange={e => setForm({ ...form, email: e.target.value })}
              />

            </div>
          </div>


          <div className="buttons mt-4">

            <button type="submit" className="w-full bg-[#5C0BAD] rounded-[30px] text-neutral-100 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center  mb-4">
              Send Recovery Email
            </button>
          </div>

          <p className=' text-neutal-800 mt-0 text-center'> Just Remember?<Link  className=" text-[#5C0BAD] underline decoration-4 font-semibold underline-offset-4" to="/login"> Sign In</Link></p>
        </form>
      </AuthLayout>


    </>
  );
};

export default Forgotpassword;
