import React from "react";
import SweetAlert2 from "react-sweetalert2";

function SweetAlert({
  show,
  title,
  message,
  buttonText,
  onConfirm,
  showCancelButton,
  showCloseButton,
  onClose,
  didClose,
  content,
}) {
  return (
    <>
      <SweetAlert2
        show={show}
        title={title}
        text={message}
        html={content}
        confirmButtonText={buttonText}
        showCancelButton={showCancelButton}
        showCloseButton={showCloseButton}
        onConfirm={onConfirm}
        buttonsStyling={false}
        didClose={didClose}
        customClass={{
          confirmButton:
            "text-white bg-blue-700 rounded-lg text-sm px-5 py-2.5 me-5",
          cancelButton: "text-white bg-red-700 rounded-lg text-sm px-5 py-2.5 ",
        }}
        focusConfirm={false}
        didDestroy={onClose}
      />
    </>
  );
}

SweetAlert.defaultProps = {
  showCancelButton: true,
  showCloseButton: false,
};

export default SweetAlert;
