import React, { useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { Fragment, useState } from "react";
import { Dialog } from "@headlessui/react";
import ReactQuill from "react-quill";
import SelectDropdown from "../../components/common/SelectDropdown";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import shared from "./shared";
import ImageUpload from "../../components/common/ImageUpload";
const AddTask = ({isOpen ,closeModal ,setForm ,options ,setShowError ,setCategory ,showError , setCategories,imageResult ,HandleSubmit ,form ,category , images}) => {
    const reactQuillRef = useRef(null);
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "imageBlot", // Optional if using custom formats
      ];
      const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["clean"],
        ],
      };
      const handleDomain = (e) => {
        setForm({ ...form, type: e.value });
        if (e) {
          let category_type = "master";
          let url = `categories/list?category_type=${category_type}&type=${e.value}`;
          getCategory(url);
        }
      };
      const getCategory = (url) => {
        loader(true);
        ApiClient.get(url).then((res) => {
          if (res.success) {
            loader(false);
            setCategories(res?.data);
          }
        });
      };
  return (
    <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-2xl transform  rounded-2xl bg-[#F4EAFE] px-6 py-2 text-left align-middle shadow-xl transition-all">
              <div className="flex justify-end items-end">
                <IoCloseCircleOutline
                  onClick={closeModal}
                  className="text-2xl cursor-pointer"
                />
              </div>
              <div className="mt-2">
                <div className="modal_inners">
                  <form onSubmit={HandleSubmit}>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12">
                        <div className="quills">
                          <ReactQuill
                            ref={reactQuillRef}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={form.discription}
                            onChange={(e) =>
                              setForm({ ...form, discription: e })
                            }
                            className=""
                          />
                          {form?.discription == "" && showError ? (
                            <span className="text-danger text-xs fs-6">
                              Description is required
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="filters_all flex items-center  gap-2 mt-6">
                      <div className="flex items-start gap-2">
                        <div className="menus_items">
                          <div className="">
                            <SelectDropdown
                              isSingle={true}
                              id="statusDropdown"
                              displayValue="name"
                              placeholder="Select Domain"
                              intialValue={form.type}
                              result={handleDomain}
                              options={shared.types}
                              className="w-full"
                            />
                            {form?.type == "" && showError ? (
                              <span className="text-danger text-xs fs-6">
                                Domain Name is required
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="menus_items">
                          <div className="">
                            <SelectDropdown
                              isSingle={true}
                              id="statusDropdown"
                              displayValue="name"
                              placeholder="Select Module"
                              intialValue={category}
                              result={(e) => {
                                setCategory(e.value);
                              }}
                              options={options}
                              className="w-full"
                            />
                            {category == "" && showError ? (
                              <span className="text-danger text-xs fs-6">
                                Module Name is required
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="h-5 w-5 cursor-pointer "
                          style={{ accentColor: "#5C0BAD" }}
                          checked={form.priortyTask}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              priortyTask: e.target.checked,
                            })
                          }
                        />
                        <span>Priority Task</span>
                      </div>
                    </div>

                    <div className="flex items-center  mt-10 mb-8 justify-between">
                      <div className="flex gap-4">
                        <div className="">
                          <label className="underline cursor-pointer text-center text-sm underline-offset-4 relative flex flex-col items-center gap-2">
                            <ImageUpload
                              model="users"
                              name="upload"
                              result={(e) => imageResult(e, "image")}
                              value={images.image || form.image}
                              multiple={false}
                            />
                            Upload File
                          </label>
                        </div>

                        <div className="max-w-96">
                          <div className="flex flex-col items-center gap-2">
                            <img
                              src="../assets/img/lyfnew/a.svg"
                              className="h-6"
                            />
                            <input
                              type="text"
                              placeholder="Insert Link"
                              value={form.insertLink}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  insertLink: [e?.target?.value],
                                })
                              }
                              className="border-none text-sm text-center placholder_color bg-transparent p-0 focus:outline-none"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <button
                          onClick={() => {
                            setShowError(true);

                          }}
                          type="submit"
                          className="bg-primary  px-6 py-2 text-[#fff] text-[16px] font-semibold rounded-full"
                        >
                          Add Task
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  )
}

export default AddTask