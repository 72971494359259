import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect } from 'react';

import Home from "./Pages/Home";
import Login from "./Pages/Login";
import NotFoundPage from "./Pages/NotFoundPage";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "./scss/main.scss";
import Profile from "./Pages/Profile";
import Settings from "./Pages/Settings";
import Forgotpassword from "./Pages/Forgotpassword";
import Resetpassword from "./Pages/Resetpassword";
import Roles from "./Pages/Roles";
import AddEditRole from "./Pages/Roles/AddEdit";
import Dashboard from "./Pages/Dashboard";

import Signup from "./Pages/Signup";
import Plan from "./Pages/Plan";
import ActivePlan from "./Pages/Plan/ActivePlan";
import Thanku from "./Pages/Thanku";
import Users from "./Pages/Users";
import AddEditUser from "./Pages/Users/AddEdit";
import ViewUser from "./Pages/Users/View";
import Category from "./Pages/Categories";
import ViewCategory from "./Pages/Categories/View";
import AddEditCategory from "./Pages/Categories/AddEdit";

import Learn from "./Pages/Learn";
import Tasks from "./Pages/Tasks";
import View from "./Pages/Learn/subModule.js";

import configureStoreProd from "./config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import Events from "./Pages/Events";
import Marketplace from "./Pages/Reward";
import Resources from "./Pages/Resources";
import Modals from "./Pages/Learn/Module";
import Cart from "./Pages/Reward/cart"
import Quiz from "./Pages/Learn/quiz";
import SeeAll from "./Pages/Events/seeAll";
import Recommendations from "./Pages/Learn/recomended";
const { persistor, store } = configureStoreProd();
function App() {

//   useEffect(() => {
//     const zoomLevel = 0.8; // 80% zoom
//     if(document){
//       document.body.style.setProperty('zoom', `${zoomLevel}`);

//     }

//     // Adjust the body dimensions to fill the viewport
//     const adjustBodyDimensions = () => {
//         document.body.style.width = `${100 / zoomLevel}vw`;
//         document.body.style.height = `${100 / zoomLevel}vh`;
//         document.body.style.minHeight = `${window.innerHeight / zoomLevel}px`;
//         const adjustedHeight = window.innerHeight / zoomLevel;
//             document.body.style.height = `${adjustedHeight}px`;
//     };

//     adjustBodyDimensions();
//     window.addEventListener('resize', adjustBodyDimensions);

//     return () => {
//         window.removeEventListener('resize', adjustBodyDimensions);
//     };
// }, []);
  const routes = [
    { url: "", element: <Login /> },
    { url: "/login", element: <Login /> },
    { url: "/signup", element: <Signup /> },
    { url: "/dashboard", element: <Dashboard /> },
    { url: "*", element: <NotFoundPage /> },
    { url: "/profile", element: <Profile /> },
    { url: "/profile/:tab", element: <Settings /> },
    { url: "/forgotpassword", element: <Forgotpassword /> },
    { url: "/resetpassword", element: <Resetpassword /> },
    { url: "/roles", element: <Roles /> },
    { url: "/roles/add", element: <AddEditRole /> },
    { url: "/roles/edit/:id", element: <AddEditRole /> },
    { url: "/user", element: <Users /> },
    { url: "/user/add", element: <AddEditUser /> },
    { url: "/user/edit/:id", element: <AddEditUser /> },
    { url: "/user/detail/:id", element: <ViewUser /> },
    { url: "/plan", element: <Plan /> },
    { url: "/activeplan", element: <ActivePlan /> },
    { url: "/thanku", element: <Thanku /> },
    { url: "/category", element: <Category /> },
    { url: "/category/detail/:id", element: <ViewCategory /> },
    { url: "/category/add", element: <AddEditCategory /> },
    { url: "/category/edit/:id", element: <AddEditCategory /> },
    // { url: "/:name", element: <Modals/> },
    { url: "/learn", element: <Modals /> },
    { url: "/subModules/:name/:category/:id", element: <View /> },
    { url: "/quiz/:name/:category/:id/:subId", element: <Quiz /> },
    { url: "/tasks", element: <Tasks /> },
    { url: "/task/:task", element: <Tasks /> },
    
    { url: "/resources", element: <Resources /> },
    { url: "/resources/:resources", element: <Resources /> },
    { url: "/events", element: <Events /> },
    { url: "/allevents", element: <SeeAll/> },
    { url: "/rewards", element: <Marketplace /> },
    { url: "/cart", element: <Cart /> },
    { url: "/:recommendations/:quizId", element: <Recommendations /> },
  ];



  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Router>
            <Routes>
              {routes.map((itm) => {
                return <Route path={itm.url} element={itm.element} />;
              })}
              {/* <Route path='/' element={ <Navigate to="/login" /> }/> */}
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
