import React, { useEffect, useState } from "react";
import { Fragment } from 'react'
import Layout from "../../components/global/layout";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { IoCloseOutline, IoFlashOutline } from "react-icons/io5";
import { ImPlus } from "react-icons/im";
import { Dialog } from '@headlessui/react'
import { LuPlus } from "react-icons/lu";
import { PiCurrencyCircleDollarFill, PiCurrencyDollarSimpleFill } from "react-icons/pi";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { MdKeyboardArrowLeft } from "react-icons/md";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import shared from "./shared";
import methodModel from "../../methods/methods";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import SelectDropdown from "../../components/common/SelectDropdown";
const Cart = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate()
  const user = useSelector((state) => state?.user)
  const [filters, setFilter] = useState({ page: 1, count: 50, search: "" });
  const [total, setTotal] = useState(0);
  const [cartList, setCartList] = useState([])
  const [count, setCount] = useState()
  const [type, setType] = useState("");
  const [singleList, setSingleList] = useState([]);
  const searchState = { data: "" };
  const handleModule = (e) => {
    setType(e?.value);
  };
  const getData = (p = {}) => {
    loader(true)
    let filter = { ...filters, ...p };

    ApiClient.get(shared.listApi, filter).then((res) => {
      if (res.success) {
        setData(res.data);
        setTotal(res.total);
      }
      loader(false)
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal(ele) {
    setSingleList(ele)
    setIsOpen(true);
  }
  useEffect(() => {

    setFilter({ ...filters, search: searchState.data });
    getData({ search: searchState.data, page: 1 });

  }, []);
  const handleOrder = (ele) => {
    let payload = {
      user: user?.id.toString(),
      product_id: ele?.id.toString(),
      quantity: "1",
      coins: ele?.coin.toString()

    }
    loader(true)
    ApiClient.post(shared.addCart, payload).then((res) => {
      if (res.success) {
        toast(res?.message)
        handleOrderList()
        loader(false)
      }
    });
  }
  const handleOrderList = () => {
    ApiClient.get(`${shared.cartList}?user=${user?.id}`).then((res) => {
      if (res.success) {

        setCartList(res?.data)
        setCount(res?.total)
      }
    });
  }
  useEffect(() => {
    handleOrderList()
  }, [])
  useEffect(() => {

    setFilter({ ...filters, search: searchState.data });
    getData({ search: searchState.data, page: 1, category: type });

  }, [type]);
  return (


    <Layout>


      <div className="flex items-center gap-4 flex-wrap justify-between mb-8">
        <div className="flex gap-4 items-center flex-wrap">
          <p className="font-semibold text-[20px] lg:text-[32px] flex items-center gap-2"> <PiCurrencyCircleDollarFill className="text-3xl" />569 Balance</p>
          <div className=" flex items-center flex-wrap gap-2">

            <div className="lg:w-64">
              <Menu as="div" className="relative block">
                <div>
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Domain"
                    intialValue={type}
                    result={handleModule}
                    options={shared.types}
                    className="w-full"
                  />
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-primary text-neutral-100' : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >

                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-primary text-neutral-100' : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >

                            Duplicate
                          </button>
                        )}
                      </Menu.Item>
                    </div>

                  </Menu.Items>
                </Transition>
              </Menu>
            </div>


            <div className="lg:w-64">
              <Menu as="div" className="relative block">
                <div>
                  <Menu.Button className="flex w-full h-12 justify-between items-center border border-neutral-200 rounded-full bg-white  px-4 py-2 text-[14px] xl:text-[17px] font-normal text-[#595959] focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                    Sort By
                    <ChevronDownIcon
                      className="-mr-1 text-[#595959] ml-2 h-4 w-4  "
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-primary text-neutral-100' : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >

                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active ? 'bg-primary text-neutral-100' : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >

                            Duplicate
                          </button>
                        )}
                      </Menu.Item>
                    </div>

                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

          </div>
        </div>

        <div className="menus_items ">



          <Link to="/cart" className="cursor-pointer">
            <div className="searhings relative">
              <img className="h-10 lg:h-12" src="../assets/img/lyfnew/cart.svg" />
              <div className='absolute bg-social h-6 w-6 flex items-center justify-center rounded-full -top-2 right-0'>
                <p className="text-[12px] text-neutral-100">{count}</p>

              </div>
            </div>
          </Link>
        </div>

      </div>


      <div className="mt-10 mb-8">
        <h2 className="flex items-center gap-1 text-[32px]  text-neutral-800 font-regular"><MdKeyboardArrowLeft className="text-[40px] cursor-pointer" onClick={() => navigate(-1)} />Shopping cart
        </h2>
      </div>


      <div className="grid grid-cols-12 gap-6 ">

        <div className="col-span-12 md:col-span-6 lg:col-span-8">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 overflow-auto">

            {data?.map((ele) => {
              return (<div className="border border-neutral-300 g-white rounded-[24px] p-3">
                <div className="flex items-center justify-between">
                  <p class={` px-4 py-2 rounded-full text-[12px] text-neutral-100 ${ele?.category === "wealth"
                    ? " bg-wealth"
                    : ""
                    }${ele?.category === "self"
                      ? " bg-self "
                      : ""
                    }${ele?.category === "social"
                      ? " bg-social "
                      : ""
                    }`}>{ele?.category}</p>
                  <div className="flex items-center gap-2 text-[#7E20DC]">
                    <HiMiniPlusCircle className="text-3xl " />
                    <p className="font-semibold text-[16px]">{ele?.coin}COINS</p>
                  </div>
                </div>
                <div className="images_tage rounded-[24px] mt-4">
                  <img className="w-full h-44 object-contain" src={methodModel.userImg(ele?.image)} />
                </div>

                <div className="mt-4 ">
                  <h5 className="mb-3 text-[18px] font-regular" >{ele?.account_type}</h5>
                  <p className="text-[14px] font-regular" dangerouslySetInnerHTML={{ __html: ele?.description }}></p>

                  <div className="flex items-center justify-between mt-4">
                    <p onClick={() => openModal(ele)} className="text-primary font-three font-semibold">See More</p>
                    <button onClick={() => handleOrder(ele)} className="flex  justify-between items-center gap-2 border border-neutral-200 rounded-full bg-primary   px-6 py-3 text-[14px] lg:text-[17px] font-normal text-neutral-100 focus:outline-none font-semibold font-three">Add to order</button>
                  </div>
                </div>
              </div>)
            })}








          </div>
        </div>

        <div className="col-span-12 md:col-span-6 lg:col-span-4 lg:pl-8">
          <div className="">
            <h2 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular flex items-center gap-2">Summary
            </h2>
            {cartList?.map((ele) => {
              return (
                <div className="border-b border-neutral-300 pb-2 flex items-center justify-between">
                  <p className="text-[18px] font-regular" dangerouslySetInnerHTML={{ __html: ele?.product_id?.account_type }}></p>
                  <p className="text-[18px] font-semibold">{ele?.coins} Coins</p>
                </div>
              )

            })}
            <div className="mt-10 flex flex-col gap-6">




              <div className="flex items-center justify-end mt-2">
                <button className="bg-primary px-6 py-3 font-semibold text-neutral-100  rounded-full">Proceed to Checkout</button>
              </div>
            </div>

          </div>
        </div>

      </div>



      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white px-6 py-2 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end items-end">
                    <IoCloseOutline onClick={closeModal} className="text-2xl cursor-pointer" />

                  </div>
                  <div className="mt-2">
                    <div className="modal_inners">
                      <div className="imsg_data">
                        <img className="h-56 w-full object-contain" src={methodModel.userImg(singleList?.image)} />
                      </div>
                      <div className="px-10 py-2 flex justify-between">
                        <div className="">
                          <h2 className="text-[16px] xl:text-[20px] text-[#004AAD] 2xl:text-[30px] capitalize ">{singleList?.account_type}</h2>
                          {/* <p className="text-[16px] text-[#004AAD] lg:text-[20px]">Planet Fitness</p> */}

                          <div className="decriptions mt-4">
                            <h4 className="text-[14px] mb-4 text-[#404040] font-[600] lg:text-[18px]">Description</h4>
                            <p dangerouslySetInnerHTML={{ __html: singleList?.account_type }}></p>
                          </div>


                        </div>

                        <div className="shrink-0">
                          <div className="flex flex-col items-center gap-3 p-4">
                            <div className="flex flex-col gap-2">
                              <img src="assets/img/lyf/coiny.svg" />
                              <p className="text-[16px] xl:text-[20px] text-[#004AAD] 2xl:text-[30px] capitalize ">{singleList?.coin} coins</p>
                            </div>

                          </div>
                        </div>


                      </div>

                    </div>
                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </Layout>



  )
}

export default Cart