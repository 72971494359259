import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { BsChevronLeft } from "react-icons/bs";
import { RiArrowDropRightLine } from "react-icons/ri";
import Barchat from "../../components/chart/Barchat";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { GrRotateRight } from "react-icons/gr";
import { Tab } from "@headlessui/react";
import ApiClient from "../../methods/api/apiClient";
import { IoMdCheckmark } from "react-icons/io";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import shared from "./shared";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import moment from "moment";
import methodModel from "../../methods/methods";
import { useParams } from "react-router-dom";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { HiMiniPlusCircle } from "react-icons/hi2";
const Recommendations = () => {
  const user = useSelector((state) => state?.user);
  const { recommendations, quizId } = useParams();
  const [RecommandedEventList, setRecommandedEventList] = useState()
  const [RecomdedResources, setRecomdedResources] = useState();
  const [recomdedTasks, setRecomdedTasks] = useState();
  
  const recommendedTaskList = () => {
    loader(true)
    let payload = {
      user: user?.id,
      quiz: quizId
    };

    ApiClient.get(`${shared?.recommandedTasklistApi}`, payload).then((res) => {
      loader(false)
      setRecomdedTasks(res?.data);

    });
  };
  const recommendResourseList = () => {
    loader(true)
    let payload = {
      user: user?.id,
      quiz: quizId
    };

    ApiClient.get(`${shared?.recommandedResorceslistApi}`, payload).then(
      (res) => {

        setRecomdedResources(res?.data);
        loader(false);
      }
    );
  };
  const RecommendEventList = () => {
    loader(true);
    let payload = {
      user: user?.id,
      quiz: quizId
    };
    ApiClient.get(`${shared?.recommandedlistApi}`, payload).then((res) => {
      setRecommandedEventList(res?.data);
      loader(false);
    });
  };
  useEffect(() => {
    recommendResourseList()
    recommendedTaskList()
    RecommendEventList()
  }, [quizId])
  return (
    <>
      <Layout>
        {recommendations === "resources" ? <>  {/* resources */}
          <div>
            <h4 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular mb-4">Recommend resources</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

           
            {RecomdedResources?.map((ele, index) => {
              return (
                <div className="border border-neutral-500 bg-neutral-100 rounded-[24px] p-4">
                  <div className="flex justify-between items-center mb-4">
                    <p
                      className={` px-4 py-1 rounded-full text-[12px] text-neutral-100  ${ele?.type === "wealth" ? " bg-wealth" : ""
                        }${ele?.type === "self" ? " bg-self " : ""}${ele?.type === "social" ? " bg-social " : ""
                        }`}
                    >
                      {ele?.type.charAt(0).toUpperCase() + ele?.type.slice(1)}
                    </p>
                    <div className="flex items-center gap-4">
                      <HiOutlineDotsHorizontal className="text-2xl" />
                    </div>
                  </div>
                  <div className="testings_datas">
                    <p
                      className="line-clamp-2 font-regular text-[14px]"
                      dangerouslySetInnerHTML={{ __html: ele?.discription }}
                    ></p>

                  </div>

                </div>
              )
            })}
             </div>
            
          </div></> : recommendations === "task" ? <>   {/* task */}
          <h4 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular mb-4">Recommend Task</h4>
          
            <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-6">
             
              {recomdedTasks?.map((ele, ind) => {
                return (
                  <div className="border border-neutral-400 bg-neutral-100 rounded-[24px] p-4">
                    <div className="flex justify-between items-center mb-4">
                      <p
                        className={` px-4 py-1 rounded-full text-[12px] text-neutral-100  ${ele?.type === "wealth" ? " bg-wealth" : ""
                          }${ele?.type === "self" ? " bg-self " : ""}${ele?.type === "social" ? " bg-social " : ""
                          }`}
                      >
                        {ele?.type.charAt(0).toUpperCase() + ele?.type.slice(1)}
                      </p>

                    </div>
                    <div className="testings_datas">
                      <p
                        className=" font-regular text-[18px]"
                        dangerouslySetInnerHTML={{ __html: ele?.discription }}
                      ></p>
                    </div>
                  </div>
                );
              })}
            </div></> : recommendations === "event" ? <>{/* event */}
              <div className={`col-span-12  transition-all    `}>

                <h4 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular mb-4">Recommend Events</h4>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">


                  <>
                    {RecommandedEventList?.map((ele, ind) => (
                      <div key={ind} className="border border-neutral-300 g-white rounded-[24px] p-3">
                        <div className="images_tage rounded-[24px] relative">
                          <img className="w-full h-44 object-cover rounded-[24px]" src="../../assets/img/lyfnew/oness.png" alt="Event Image" />
                          <p className={`px-4 py-2 rounded-full text-[12px] text-neutral-100 absolute left-2 top-4 
                            ${ele?.category?.type === "wealth" ? "border-[#58A541] bg-[#58A541]" : ""}
                            ${ele?.category?.type === "self" ? "border-[#0079B3] bg-[#0079B3]" : ""}
                            ${ele?.category?.type === "social" ? "border-[#FFA03B] bg-[#FFA03B]" : ""}`}>
                            {ele?.category?.type}
                          </p>
                        </div>
                        <div className="mt-4">
                          <div className="flex items-center flex-wrap justify-between mt-2 mb-4 gap-2">
                            <p className="text-[12px] text-neutral-600 font-regular">
                              {moment(ele?.start_date ? ele?.start_date : "").format("DD/MM/YY")}
                            </p>
                            <p className="text-neutral-800 text-[14px] font-regular">{ele?.name}</p>
                          </div>
                          <p className="text-[14px] font-regular line-clamp-2" dangerouslySetInnerHTML={{ __html: ele?.description }}></p>

                        </div>
                      </div>
                    ))}
                  </>



                </div>
              </div> </> : ""}



      </Layout>
    </>
  );
};

export default Recommendations;
