import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { BsChevronLeft } from "react-icons/bs";
import { RiArrowDropRightLine } from "react-icons/ri";
import Barchat from "../../components/chart/Barchat";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { GrRotateRight } from "react-icons/gr";
import { Tab } from "@headlessui/react";
import ApiClient from "../../methods/api/apiClient";
import { IoMdCheckmark } from "react-icons/io";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import shared from "./shared";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import moment from "moment";
import methodModel from "../../methods/methods";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login_success } from "../../actions/user";
const Quiz = () => {
  const user = useSelector((state) => state?.user);
  const { id, name, category ,subId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const quizzId = location?.state;
  const dispatch = useDispatch();

  const [quizData, setQuizData] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [options, setOptions] = useState(
    Array.from({ length: quizData.length }, () => null)
  );
  const [showRecommend, setShowRecommend] = useState(false);
  const [combinationData, setCombinationData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [resourseData, setResourseData] = useState([]);
  const [taskData, setTaskdata] = useState([]);
  const [showTab, setShowTab] = useState("task");
  const [getVedioDataList, setGetVedioDataList] = useState();
  const [getDataList, setGetDataList] = useState();
  const [selectedName, setSelectedName] = useState();
  const [saveIdList, setSaveIdList] = useState();
  const [updateDataList, setUpdateDataList] = useState(false);
  const [VideoWatching, setVideoWatching] = useState(false);
  const [SelectedVideo, setSelectedVideo] = useState(null);
  useEffect(() => {
    let element = document.getElementById("myElement");
    element.classList.remove("mainarea");
    element.classList.add("mainarea_views");
  });
  const levelValue = () => {
    ApiClient.get(`settings/point/${user?.level}`).then((res) => {
      if (res.success) {
        // const levelData = res.data;
        // setUserLevelIds(levelData);
        // setMinLevel(levelData.Min || 0);
        // setMaxLevel(levelData.Max || 0);

      }
    });
  };
  const getData = (p) => {
    loader(true);
    ApiClient.get(
      `${shared?.sub_categoryApi}?category_type=${shared?.subCategory_type}&category_id=${p}&user=${user?.id}`
    ).then((res) => {
      const data = res?.data?.find((ele) => !ele?.isWatched);

      setSelectedName(data?.name);
      handleVedio(data?.id);
      setGetDataList(res?.data);
      loader(false);
    });
  };
  useEffect(() => {
    if (quizzId) {
      handleQuizSaveList();
    }
  }, []);

  const handleQuizList = () => {
    loader(true);
    ApiClient.get(`${shared?.quizdetail}/${quizzId}`).then((res) => {
      if (res?.success == true) {
        loader(false);
        setQuizData(res?.data[0]?.question || []);
        // setQuizzId(res?.data[0]?.id || "");
        setCombinationData(res?.data[0]?.permutationCombination || []);
      }
    });
  };
  const gallaryData = () => {
    loader(true);
    ApiClient.get("user/" + user.id, {}).then(async (res) => {
      if (res.success) {
        let data = { ...user, ...res.data };
        dispatch(login_success(data));
      }
    });
  };
  const checkAndStoreData = (data, sets) => {
    const setNameToCheck = sets.join("");
    const matchedSet = data.find((item) => item.setName === setNameToCheck);
    if (matchedSet) {
      if (!updateDataList) {
        let payload = {
          user: user ? JSON?.stringify(user?.id) : "",
          quiz: quizzId ? quizzId : "",
          quizResult: setNameToCheck,
          resources: matchedSet.resources,
          task: matchedSet.task,
          event: matchedSet.event,
        };
        loader(true);
        ApiClient.post(`quizsave`, payload).then((res) => {
          if (res?.success == true) {
            loader(false);
            setShowRecommend(true);
            gallaryData()
            handleQuizSaveList();
          } else {
            toast?.error(res?.message);
          }
        }); 
      } else {
        let payload = {
          user: user ? JSON?.stringify(user?.id) : "",
          quiz: quizzId ? quizzId : "",
          quizResult: setNameToCheck,
          resources: matchedSet.resources,
          task: matchedSet.task,
          event: matchedSet.event,
        };
        loader(true);
        ApiClient.put(`quizsave/${saveIdList}`, payload).then((res) => {
          if (res?.success == true) {
            loader(false);
            setShowRecommend(true);
            handleQuizSaveList();
            gallaryData()
          } else {
            toast?.error(res?.message);
          }
        });
      }
    } else {
    }
  };
  useEffect(() => {
    getData(id);
    handleVedio();
  }, [id]);
  const handleNextOrSave = () => {
    if (currentQuizIndex === quizData.length - 1) {
      checkAndStoreData(combinationData, options);
    } else {
      if (options[currentQuizIndex] === null) {
        toast?.error("Please select an option first");
      } else {
        setCurrentQuizIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handlePrevious = () => {
    setCurrentQuizIndex((prevIndex) => prevIndex - 1);
  };
  const handleOptionSelect = (option) => {
    const newOptions = [...options];
    newOptions[currentQuizIndex] = option;
    setOptions(newOptions);
  };
  const handleQuizSaveList = () => {
    let payload = {
      user: user ? JSON?.stringify(user?.id) : "",
      quiz: quizzId ? quizzId : "",
    };
    loader(true);
    ApiClient.get(`${shared?.quizsavelist}`, payload).then((res) => {
      if (res?.success == true) {
        if (res?.data?.length > 0) {
          setShowRecommend(true);
          loader(false);
          setSaveIdList(res?.data[0]?.id);
          setTaskdata(res?.data[0]?.task || []);
          setEventData(res?.data[0]?.event || []);
          setResourseData(res?.data[0]?.resources || []);
        } else {
          handleQuizList();
        }
      } else {
      }
    });
  };
  const handleVedio = () => {
    ApiClient.get(`${shared?.vedioListApi}?category=${id}&sub_category=${subId}`).then((res) => {
      setGetVedioDataList(res?.data[0]);
    });
  };
  const handleGoBack = () => {
    setShowRecommend(false);
    handleQuizList();
    setCurrentQuizIndex(0);
    setUpdateDataList(true);
  };
  return (
    <>
      <Layout>
        <div className="flex flex-col lg:flex-row gap-4 h-full ">
          <div className="w-full flex h-full">
            <div className="flex w-full h-full overflow-auto flex-col gap-6 mb-8 bg-white border-r border-gray-200 xl:px-6 px-2 xl:py-6 py-2">
              <div className="flex gap-4">
                <div className="flex items-center gap-8">
                  <div
                    className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                      name === "wealth" && " bg-[#58A541] !text-neutral-100"
                    }`}
                  >
                    Wealth
                  </div>
                  <div
                    className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                      name === "self" && " bg-[#0079B3] !text-neutral-100"
                    }`}
                  >
                    Self
                  </div>
                  <div
                    className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${
                      name === "social" && " bg-[#FFA03B] !text-neutral-100"
                    }`}
                  >
                    Social
                  </div>
                </div>
                {/* <div className="seargings">
                  <div className="border flex items-center gap-2 border-neutral-400 p-3 rounded-full">
                    <img src="/assets/img/lyfnew/search.svg" />
                    <input
                      type="search"
                      className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-32"
                      placeholder="Search"
                    />
                  </div>
                </div> */}
              </div>

              <div className="flex items-center gap-2 mb-8">
                <BsChevronLeft
                  className="text-[16px] 2xl:text-[22px]  cursor-pointer"
                  onClick={() => navigate(-1)}
                />
                <div className="flex text-[16px] 2xl:text-[22px]  items-center gap-2">
                  <span className="font-semibold">{category}</span>
                  <span className="font-semibold">
                    <RiArrowDropRightLine />
                  </span>
                  <span className="font-semibold">
                    {getVedioDataList?.sub_category?.name}
                  </span>
                </div>
              </div>

              <div className="videos_tags w-[100%]">
                <div className="videos_tags w-[100%]">
                  <video
                    controls
                    width="100%"
                    height="300px"
                    src={methodModel.noImg(getVedioDataList?.video)}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    // onEnded={() => {
                    //   handleVedioData(ele);
                    // }}
                  ></video>
                </div>
              </div>
              {/* <div className="flex flex-col gap-4">
                <div className="mt-8">
                  <div className="wealth_checks">
                    <div className="flex flex-col gap-6 flex-wrap  overflow-x-auto">
                      {getDataList?.map((ele, ind) => (
                        <div key={ele.id} className="checksboxes">
                          <label
                            onClick={() => {
                              handleVedio(ele?.id);
                              setSelectedVideo(ele?.id);
                              setVideoWatching(ele?.isWatched);
                              setSelectedName(ele?.name);
                            }}
                            className={`w-full border border-neutral-300 font-regular text-[18px] ${
                              selectedName === ele.name
                                ? " bg-neutral-200"
                                : " bg-neutral-100"
                            } rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4 `}
                          >
                            <input
                              className="sr-only peer"
                              name=""
                              type="checkbox"
                              checked={
                                ele.isWatched === true

                                // ||   SelectedVideo == ele?.id
                              }
                            />
                            <div
                              className={`w-7 h-7 text-2xl text-white flex items-center justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100 ${
                                name === "wealth"
                                  ? " border-[#58A541]  peer-checked:bg-[#58A541] "
                                  : ""
                              }${
                                name === "self"
                                  ? " border-[#0079B3]  peer-checked:bg-[#0079B3] "
                                  : ""
                              }${
                                name === "social"
                                  ? " border-[#FFA03B]  peer-checked:bg-[#FFA03B] "
                                  : ""
                              }`}
                            >
                              <IoMdCheckmark />
                            </div>
                            <p>{ele.name}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="summary_data border border-neutral-300 p-4 rounded-[16px] mt-4">
                <p className="mb-6  text-[16px] 2xl:text-[24px] font-[700] text-[#727272]">
                  Summary
                </p>

                <div className="custom-scrollbar-new">
                  <p
                    className="text-[12px] 2xl:text-[18px] text-neutral-800"
                    dangerouslySetInnerHTML={{
                      __html: getVedioDataList?.summary,
                    }}
                  ></p>
                </div>
              </div>

              <div className="chatrs_expls mt-4">
                {/* <div className="">
                  <h5 className="font-semibold text-[16px] 2xl:text-[27px]">Chart example</h5>
                  <p className="font-regular text-[20px]">Supporting text</p>
                </div> */}
                <img
                src={methodModel.userImg(getVedioDataList && getVedioDataList?.image)}
                className="h-28 w-28 mx-auto"
              />
              </div>
            </div>

            <div
              onClick={() => {
                navigate(-1);
              }}
              className="iconsclicks cursor-pointer flex px-4 border-l border-gray-100 bg-gray-100 items-center justify-center"
            >
              <MdKeyboardDoubleArrowRight />
            </div>

            <div className="ques_ions h-full  w-full bg-white xl:px-6 px-2 xl:py-6 py-2">
              {!showRecommend ? (
                <div className="mb-4">
                  <div className=" mb-4 first_appears">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: quizData[currentQuizIndex]?.question,
                      }}
                      className="flex items-center gap-2 text-[22px] text-neutral-800 font-regular"
                    ></p>
                  </div>
                  <div className="checksboxes w-full mb-4">
                    <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                      <input
                        className="sr-only peer"
                        checked={options[currentQuizIndex] === "A"}
                        onChange={() => handleOptionSelect("A")}
                        type="radio"
                      />

                      <div className="w-5 h-5 text-2xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                        <IoMdCheckmark className="" />
                      </div>
                      <p
                        className="text-[14px] xl:text-[18px]"
                        dangerouslySetInnerHTML={{
                          __html: quizData[currentQuizIndex]?.option_1,
                        }}
                      ></p>
                    </label>
                  </div>
                  <div className="checksboxes w-full mb-4">
                    <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                      <input
                        className="sr-only peer"
                        checked={options[currentQuizIndex] === "B"}
                        onChange={() => handleOptionSelect("B")}
                        type="radio"
                      />

                      <div className="w-5 h-5 text-2xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                        <IoMdCheckmark className="" />
                      </div>
                      <p className="text-[14px] xl:text-[18px]">
                        {quizData[currentQuizIndex]?.option_2}
                      </p>
                    </label>
                  </div>
                  <div className="checksboxes w-full mb-4">
                    <label className="w-full border border-neutral-300 font-regular text-[14px] xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4">
                      <input
                        className="sr-only peer"
                        checked={options[currentQuizIndex] === "C"}
                        onChange={() => handleOptionSelect("C")}
                        type="radio"
                      />

                      <div className="w-5 h-5 text-2xl text-neutral-100 flex items-center text-white border-[#58A541] peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100">
                        <IoMdCheckmark className="" />
                      </div>
                      <p className="text-[14px] xl:text-[18px]">
                        {quizData[currentQuizIndex]?.option_3}
                      </p>
                    </label>
                  </div>
                  <div className="flex items-center justify-between">
                    {currentQuizIndex >= 1 ? (
                      <button
                        className="bg-primary flex items-center gap-1 px-6 py-2 rounded-xl text-white text-sm"
                        onClick={handlePrevious}
                      >
                        <GoChevronLeft />
                        Previous
                      </button>
                    ) : (
                      ""
                    )}
                    <p>{`${currentQuizIndex + 1}/${quizData.length}`}</p>
                    <button
                      className="bg-primary px-6 py-2 rounded-xl text-white text-sm"
                      onClick={handleNextOrSave}
                    >
                      {currentQuizIndex === quizData.length - 1 ? (
                        "Save"
                      ) : (
                        <>
                          <p className="flex items-center gap-1">
                            Next
                            <GoChevronRight />
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="bg_cards after_questin_update">
                    <p className="font-semibold text-[18px] 2xl:text-[28px] mb-6">
                      Lifestyle quiz
                    </p>
                    <div className="cards_news flex items-center justify-between bg-[#5C0BAD] rounded-[16px] px-4 py-4 w-full">
                      <div className="flex flex-col gap-2">
                        <p className="text-[22px] 2xl:text-[32px] text-white">
                          Congratulations!
                        </p>
                        <p className="text-white text-[14px]">
                          See your recommendations below!
                        </p>
                        <div className="mt-2" onClick={handleGoBack}>
                          <p className="bg-[#9B51E0] inline-flex px-4 cursor-pointer flex gap-2 items-center rounded-full text-white py-1">
                            Go again? <GrRotateRight />{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <img src="/assets/img/lyfnew/sparkles.svg" />
                      </div>
                    </div>
                    {/* <p>5 of 5</p> */}
                  </div>
                  <div className="wizards mt-8">
                    <p className="font-semibold text-[18px] 2xl:text-[28px] mb-6">
                      Recommendations
                    </p>

                    <Tab.Group>
                      <Tab.List className="flex gap-2">
                        <Tab
                          onClick={() => {
                            setShowTab("task");
                          }}
                          className={`${
                            showTab == "task"
                              ? "bg-[#9B51E0] text-white"
                              : "bg-white"
                          } border border-neutral-300 rounded-md py-2 px-4`}
                        >
                          Tasks
                        </Tab>
                        <Tab
                          onClick={() => {
                            setShowTab("resourse");
                          }}
                          className={`${
                            showTab == "resourse"
                              ? "bg-[#9B51E0] text-white"
                              : "bg-white"
                          } border border-neutral-300 rounded-md py-2 px-4`}
                        >
                          Resources
                        </Tab>
                        <Tab
                          onClick={() => {
                            setShowTab("event");
                          }}
                          className={`${
                            showTab == "event"
                              ? "bg-[#9B51E0] text-white"
                              : "bg-white"
                          } border border-neutral-300 rounded-md py-2 px-4`}
                        >
                          Events
                        </Tab>
                      </Tab.List>
                      <Tab.Panels className="mt-4">
                        <Tab.Panel>
                          {taskData.map((item) => (
                            <div
                              key={item?.id}
                              className="border border-neutral-300  rounded-[8px] p-3 mb-3"
                            >
                              <p
                                className={`text-[10px] font-medium mb-1 capitalize
                            ${item?.type === "wealth" ? "text-[#58A541] " : ""}
                            ${item?.type === "self" ? "text-[#0079B3] " : ""}
                            ${
                              item?.type === "social" ? "text-[#FFA03B] " : ""
                            }`}
                              >
                                {item?.type}
                              </p>
                              <div className="images_rows flex items-start gap-4">
                                <img src="/assets/img/lyfnew/user.png" />
                                <div className="w-full">
                                  <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                    <p className="text-[12px] text-neutral-600 font-regular">
                                      {moment(item?.created_at).format(
                                        "DD/MM//YY"
                                      )}
                                    </p>
                                    {/* <p className="text-[12px] text-neutral-600 font-regular">
                                      North University
                                    </p> */}
                                  </div>
                                  <p
                                    className="text-[12px] text-neutral-800"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.discription,
                                    }}
                                  >
                                    {/* Bussiness talk about development with Kate
                                    Musk */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Tab.Panel>
                        <Tab.Panel>
                          {resourseData.map((item) => (
                            <div
                              key={item?.id}
                              className="border border-neutral-300 rounded-[8px] p-3 mb-3"
                            >
                              <p
                                className={`text-[10px] font-medium mb-1 capitalize
                            ${item?.type === "wealth" ? "text-[#58A541] " : ""}
                            ${item?.type === "self" ? "text-[#0079B3] " : ""}
                            ${
                              item?.type === "social" ? "text-[#FFA03B] " : ""
                            }`}
                              >
                                {item?.type}
                              </p>
                              <div className="images_rows flex items-start gap-4">
                                <img src="/assets/img/lyfnew/user.png" />
                                <div>
                                  <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                    <p className="text-[12px] text-neutral-600 font-regular">
                                      {moment(item?.created_at).format(
                                        "DD/MM/YY"
                                      )}
                                    </p>
                                    <p className="text-[12px] text-neutral-600 font-regular">
                                      {item?.address}
                                    </p>
                                  </div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.discription,
                                    }}
                                    className="text-[12px] text-neutral-800"
                                  ></p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Tab.Panel>
                        <Tab.Panel>
                          {eventData.map((item) => (
                            <div
                              key={item?.id}
                              className="border border-neutral-300 rounded-[8px] p-3 mb-3"
                            >
                              <p
                                className={`text-[10px] font-medium mb-1 capitalize
                            ${item?.type === "wealth" ? "text-[#58A541] " : ""}
                            ${item?.type === "self" ? "text-[#0079B3] " : ""}
                            ${
                              item?.type === "social" ? "text-[#FFA03B] " : ""
                            }`}
                              >
                                {item?.type}
                              </p>
                              <div className="images_rows w-full flex items-start gap-4">
                                <img src="/assets/img/lyfnew/user.png" />
                                <div className="w-full">
                                  <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                    <p className="text-[12px] text-neutral-600 font-regular">
                                      {moment(item?.start_date).format(
                                        "DD/MM//YY"
                                      )}
                                    </p>
                                    <p className="text-[12px] text-neutral-600 font-regular">
                                      {item?.address}
                                    </p>
                                  </div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item?.name,
                                    }}
                                    className="text-[12px] text-neutral-800"
                                  ></p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Quiz;
