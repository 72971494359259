import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import AuthLayout from "../../components/AuthLayout";
import methodModel from "../../methods/methods";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../actions/user";
import { RxEyeClosed } from 'react-icons/rx';
// import socketModel from "../../models/socketModel";
const Login = () => {
  const history = useNavigate();
  const user: any = useSelector((state: any) => state?.user);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user && user?.loggedIn) {
      history("/dashboard");
    }
  }, []);

  const [ip, setIp] = useState("");
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [step, setStep] = useState(1);
  const [otp, setOTP] = useState("");
  const [resp, setRes]: any = useState();
  useEffect(() => {
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }

    // Using an HTTP GET request to ipinfo.io/json

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        let ip = data.ip;
        localStorage.setItem("IP", ip);
        setIp(ip);
      })
      .catch((error) => console.error("Error fetching IP address:", error));

    let email = methodModel.getPrams("email");
    if (email) setUsername(email);
  }, []);

  const setLogin = (data: any) => {
   
    // socketModel.emit("join_socket", { user_id: data.id });
    // socketModel.on("join_socket", (data) => {
    //   console.log("join_socket", data);              
    // });
    localStorage.setItem("token", data.access_token);
    dispatch(login_success(data));
    let url = "/dashboard";
    let eventId = methodModel.getPrams("eventId");
    if (eventId) url = `/dashboard?eventId=${eventId}`;
    history(url);
  };

  const hendleSubmit = (e: any) => {
    e.preventDefault();
    let data: any = {
      email: username,
      password,
      ip_address: ip,
    };

    let url = "login";
    if (step == 2) {
      url = "api/two-factor/auth";
      data = {
        id: resp?._id,
        otp: otp,
        ip_address: ip,
      };
    }

    loader(true);
    ApiClient.post(url, data).then(async (res) => {
      if (res.success == true) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }

        ApiClient.get("profile/detail", {
          access_token: res.access_token,
        }).then((ures) => {
          if (ures.success) {
            setLogin({ ...ures.data, access_token: res.access_token });
          
          }
          loader(false);
        });
      } else {
        loader(false);
      }
    });
  };
  return (
    <>
      <AuthLayout>
        <div className="max-w-lg mx-auto overflow-y-auto px-4 py-4">
       
        <form onSubmit={hendleSubmit}>
          <h4 className=" mb-4 text-[18px] text-[32px] font-regular  text-neutral-800">
            Login
          </h4>
          {step == 1 ? (
            <>
              <div className="mb-4">
                <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                  University E-mail*
                </label>

                <input
                type="email"
                className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1"
                placeholder="Email address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              </div>
             

              <div className="">
                <div className=" mb-2">
                  <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                    Password*
                  </label>
                 
                </div>
                <div className="relative">
                  <input
                    type={eyes.password ? "text" : "password"}
                    className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                 {eyes.password ? (
                    <RxEyeClosed
                      className="font-[12px] cursor-pointer right-3 absolute top-1/2 -translate-y-1/2 text-gray-600"
                      onClick={() => setEyes({ ...eyes, password: !eyes.password })}
                    />
                  ) : (
                    <RxEyeClosed
                      className="font-[12px] cursor-pointer right-3 absolute top-1/2 -translate-y-1/2 text-gray-600"
                      onClick={() => setEyes({ ...eyes, password: !eyes.password })}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="mb-2">OTP sent on email</p>
              <input
                type="text"
                maxLength={6}
                minLength={6}
                className="shadow-box bg-white mb-6 w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
              />
            </>
          )}

          <div className="mt-4">
            <label className="flex justify-between items-center ">
              <div className="flex gap-1">
              <input
                type="checkbox"
                checked={remember}
                style={{ accentColor: '#5C0BAD' }}
                onChange={(e) => {
                  setRemember(e.target.checked);
                
                  // dispatch(SEARCH_STATE("games"))
                }}
                className="mr-2 h-5 w-5 cursor-pointer"
              />{" "}
                <p className="text-md text-gray-600">Remember Me</p>
              </div>
              <div className="">
            
              <Link
                    to="/forgotpassword"
                    className="font-[700] text-[#004AAD] text-[14px]  lg:text-[16px] underline cursor-pointer  decoration-8 underline-offset-4	focus:outline-none	"
                  >
                    Forgot Password
                  </Link>
              </div>
            </label>
          </div>
          <div className="mt-8">
            {/* <label><input type="checkbox" checked={remember} onChange={(e)=>setRemember(e.target.checked)} className="mr-2" /> Remember Me</label> */}
            <button
              type="submit"
              className="!px-4 w-full text-sm font-normal text-neutral-100 h-11 flex items-center justify-center gap-2 bg-[#5C0BAD] rounded-[30px] shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC]  disabled:cursor-not-allowed"
            >
              Login
            </button>
            <p className=" text-neutral-800 text-center mt-0 ml-3 mt-4">
              {" "}
              Create an Account?
              <Link
                className=" text-[#5C0BAD] underline decoration-4 font-semibold underline-offset-4"
                to="/signup"
              >
                {" "}
                Sign Up
              </Link>
            </p>
          </div>
          {/* <p className='text-sm mt-3 text-center'>Don't have an account? <Link to="/signup" className='text-orange-500 text-sm'>Sign Up</Link></p> */}
        </form>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
