import React, { useEffect, useRef, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import Html from "./html";
import "./style.scss";

const ImageUpload = ({
  model,
  result,
  value,
  multiple,
  required,
  err,
  name,
  editData=true,
  label = "",
  disabled="",
  url = "upload/image?modelName=",
  type,
}) => {
  const inputElement = useRef();
  const [img, setImg] = useState("");
  const [loader, setLoader] = useState(false);
  const uploadImage = async (e) => {
    let files = e.target.files;
    let i = 0;
    let imgfile = [];
    for (let item of files) {
      imgfile.push(item);
    }

    let images = [];
    if (img) images = img;
    setLoader(true);
    for await (let item of imgfile) {
      let file = files.item(i);
  
      const res = await ApiClient.postFormData(url + model, { file: file });
      if (res.success) {
        let image = res.path;
        if (!multiple) {
          setImg(image);
          result({ event: "value", value: image });
        } else {
          images.push(image);
        }
      }
      i++;
    }
    setLoader(false);
    if (multiple) {
      setImg(images);
      result({ event: "value", value: images });
    }
  };

  const remove = (index) => {
    setImg(null);
    inputElement.current.value = "";
    result({ event: "remove", value: "" });
  };

  useEffect(() => {
    setImg(value);
  }, [value]);

  return (
    <>
      <Html
        label={label}
        multiple={multiple}
        inputElement={inputElement}
        editData={editData}
        uploadImage={uploadImage}
        img={img}
        model={model}
        required={required}
        loader={loader}
        err={err}
        remove={remove}
        type={type}
        name={name}
        
      />
    </>
  );
};
export default ImageUpload;
