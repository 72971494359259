import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import './style.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from "antd";
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import Table from "../../components/Table";
import SelectDropdown from '../../components/common/SelectDropdown';
import statusModel from '../../models/status.model';
import datepipeModel from '../../models/datepipemodel';
import shared from "./shared";
import { useSelector } from 'react-redux';
import { LuEye, LuPlus } from 'react-icons/lu';
const Html = ({
    sorting,
    filter,
    edit,
    view,
    statusChange,
    pageChange,
    deleteItem,
    clear,
    filters,
    setFilter,
    loaging,
    data,
    changestatus,
    isAllow,
    total = { total }
}) => {
    const user = useSelector((state)=>state?.user)
    const columns = [
        {
            key: 'fullName', name: 'Name', sort: true,
            render: (row) => {
                return <span className='capitalize'>{row?.fullName}</span>
            }
        },
        {
            key: 'role', name: 'Role',
            render: (row) => {
                return <span className='capitalize'>{row?.role}</span>
            }
        },
        {
            key: 'created_at', name: 'Created Date',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.datetime(row?.created_at)}
                </>
            }
        },
        {
            key: 'email', name: 'Email', sort: true,
            render: (row) => {
                return <span className=''>{row?.email}</span>
            }
        },
        {
            key: 'status', name: 'Status',
            render: (row) => {
                return <>
                    <div className='w-32' onClick={() => statusChange(row)}>
                        <Tooltip placement="top" title="Active / Inactive">
                            <span className='bg-[#EEE] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#EBEBEB] text-[#3C3E49A3] !rounded capitalize'>
                                {row.status == 'deactive' ? 'inactive' : 'active'}
                            </span>
                        </Tooltip>
                    </div>
                </>
            }
        },
        {
            key: 'action', name: 'Action',
            render: (itm) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">
                        <Tooltip placement="top" title="View">
                            <a className="border cursor-pointer  hover:opacity-70 rounded-lg  w-10 h-10 !text-primary flex items-center justify-center text-lg" onClick={e => view(itm.id)}>
                            <LuEye />
                            </a>
                        </Tooltip>
                        {isAllow(`edit${shared.check}`) && itm.role!='admin' ?
                            <Tooltip placement="top" title="Edit">
                                <a className="border cursor-pointer  hover:opacity-70 rounded-lg  w-10 h-10 !text-primary flex items-center justify-center text-lg" onClick={e => edit(itm.id)}>
                                    <FiEdit3 />
                                </a>
                            </Tooltip>
                            : <></>}
                        {isAllow(`delete${shared.check}`) && itm.role!='admin' ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer  hover:opacity-70 rounded-lg  w-10 h-10 !text-primary flex items-center justify-center text-lg ' onClick={() => deleteItem(itm.id)}>
                            <BsTrash3 />
                        </span> </Tooltip> : <></>}
                    </div>
                </>
            }
        },
    ]

    return (
        <Layout>
            <div className="flex justify-between items-center">
             
                <div className="flex items-center gap-4">
                      <h2 className="flex items-center gap-2 font-semibold text-xl lg:text-2xl text-neutral-800 font-bold">{shared.title}  
                      </h2>


                        <div>
                          <form onSubmit={e => {
                        e.preventDefault()
                        filter()
                    }} className="search-form">
                              <input type="submit" value="" className="search-submit"/>
                              {/* <input type="submit" value="" className="search-submit"/> */}
                                <input type="search"  name="q" className="search-text pl-2" placeholder="Search..."  autocomplete="off"
                                value={filters.search}
                                onChange={e => {
                                    setFilter({ ...filters, search: e.target.value })
                                }}
                                 required 
                            
                                />
                                            {filters?.search && (
                        <i
                          className="fa fa-times absolute top-1/2 right-2 -translate-y-1/2"
                          aria-hidden="true"
                          onClick={(e) => clear()}
                        ></i>
                      )}
                          </form>
                        </div>
                

                  </div>
               

                {/* <a id='downloadFile'></a> */}

                <div className="flex gap-4 ">
                    {isAllow(`add${shared.check}`) ?
                        <Link className="flex  justify-between items-center rounded-[16px]  !bg-[#5C0BAD] gap-4 px-4 py-2 text-[16px]  font-bold text-neutral-100  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75" to={`/${shared.url}/add`}>
                                
                                
                                <LuPlus /> Add {shared.addTitle}
                        
                        </Link>
                        : <></>}


                    <div className=" ">
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder='All Status'
                            intialValue={filters.status}
                            result={e => { changestatus(e.value) }}
                            options={statusModel.list}
                        />
                        {filters.status || filters.groupId || filters.search ? <>
                            <button
                                className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-neutral-100 rounded-lg"
                                onClick={() => clear()}>
                                Reset
                            </button>
                        </> : <></>}


                    </div>


                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-lg mt-6'>
                {!loaging ? <>
                    <Table
                        className='mb-3'
                        data={data}
                        columns={columns}
                        page={filters.page}
                        count={filters.count}
                        total={total}
                        result={(e) => {
                            if (e.event == 'page') pageChange(e.value)
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />

                </> : <></>}

                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </Layout >
    );
};

export default Html;
