import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import permissionModel from "../../../models/permisstion.model";
import methodModel from "../../../methods/methods";
import environment from "../../../environment";
import { FcOvertime } from "react-icons/fc";
import { BsExclamationTriangle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import { login_success, logout } from "../../../actions/user";
import { FiMenu, FiX } from "react-icons/fi";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoMdArrowBack, IoMdArrowDown } from "react-icons/io";
// import socketModel from "../../../models/socketModel";

const ResIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_201_2108)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C13.05 14.18 13 14.54 13 15H11V14.5C11 14.04 11.08 13.6 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_201_2108">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EventIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_202_124)">
      <path
        d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_202_124">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Layout = ({ children }) => {
  const user = useSelector((state) => state?.user);
  const history = useNavigate();
  const [isOpen, setIsopen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.loggedIn) {
      history("/login");
    } else {
      let permissions = user.roleDetail?.permissions?.[0];
      if (!permissionModel.urlAllow(permissions)) {
        // history("/profile")
      }
      let browseload = localStorage.getItem("browseload");
      if (!browseload) {
        ApiClient.get("user/" + user.id, {}).then(async (res) => {
          if (res.success) {
            let data = { ...user, ...res.data };
            dispatch(login_success(data));
          }
        });
      }
    }
  }, []);

  const logo = () => {
    let value = "/assets/img/logo.png";
    return value;
  };

  const logos = () => {
    let value = "/assets/img/logo-small1.png";
    return value;
  };

  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const [state, setstate] = useState(false);
  useEffect(() => {
    setstate(localStorage.getItem("sidebar"));
  }, [localStorage.getItem("sidebar")]);

  const toggle = () => {
    setIsopen(!isOpen);
  };
  

  const [userLevelIds, setUserLevelIds] = useState();
  const [minLevel, setMinLevel] = useState();
  const [maxLevel, setMaxLevel] = useState();
  const levelValue = () => {
    ApiClient.get(`settings/point/${user?.level ? user?.level :0 }`).then((res) => {
      if (res.success) {
        const levelData = res.data;
        setUserLevelIds(levelData);
        setMinLevel(levelData.Min || 0);
        setMaxLevel(levelData.Max || 0);
      }
    });
  };
  const [achievedCount, setAchievedCount] = useState(0);
  const BadgesValue = () => {
    ApiClient.get(`user/badges/${user?.id}`).then((res) => {
      if (res.success) {
        const data = res?.data
 
        const count =  data?.filter(badge => badge.isAchieved)?.length 
        setAchievedCount(count);
      }
    });
  };

  const calculateProgressWidth = () => {
    if (maxLevel > minLevel) {
      const progress = ((user?.coins ) / (maxLevel )) * 100;
      return Math.min(Math.max(progress, 0), 100); // Ensure it's between 0% and 100%
    }
    return 0;
  };
  const progressWidth = calculateProgressWidth();
  useEffect(() => {
    // levelValue();
    BadgesValue()
  }, []);
  useEffect(() => {
    levelValue();
    // BadgesValue()
  }, [user?.level]);
  const Logout = () => {
    dispatch(logout())
    localStorage.removeItem("persist:admin-app")
    localStorage.removeItem("token")
    // localStorage.removeItem("loginTime")
    history('/login');
  };

  return (
    <>
      <div className="overflow-hidden h-screen " component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        
        <Header isOpen={isOpen} setIsOpen={setIsopen} />
        <div id="logout" onClick={()=>Logout()}></div>
        <div
          className={`main-wrapper  h-screen flex   ${
            isOpen ? "active-sidebar p-[0px]" : "p-[10px] xl:p-[20px]"
          }`}
        >
          <div
            className={`main-sidebar   transition-[width] duration-300 fixed   max-w-[200px] z-50 bg-no-repeat w-[100%] h-[100vh]  pb-[40px] relative ${
              isOpen ? "" : ""
            }`}
          >
            <div className="flex flex-col gap-2   h-full">
              <div className="flex flex-col gap-2 justify-between items-start  h-full overflow-x-hidden overflow-y-auto">
                <div className="flex flex-col gap-2  items-start bg-[#5C0BAD] w-full rounded-[24px] px-4 py-4 xl:py-6 relative">
                  <div
                    className={`flex flex-col absolute top-3  justify-end items-end w-full ${
                      isOpen
                        ? " !justify-center !right-0 !items-center"
                        : "right-3"
                    }`}
                  >
                    <button
                      onClick={toggle}
                      className="h-9 w-9 shrink-0  hover:shadow-none !text-primary"
                    >
                      {!isOpen ? (
                        <img
                          className="text-2xl text-neutral-100 "
                          src="/assets/img/lyfnew/exits.svg"
                        />
                      ) : (
                        <img
                          className="text-2xl text-neutral-100 rotate-[180deg]"
                          src="/assets/img/lyfnew/exits.svg"
                        />
                      )}
                    </button>
                  </div>
                  <div className="sidebar-brand text-center mt-6 flex items-center">
                    <Link to="/profile">
                      <div className="editLogo">
                        <img
                          src={
                            user?.image
                              ? environment.assetsUrl + user?.image
                              : logo()
                          }
                          className=" show-logo w-[40px] h-[40px] rounded-full"
                        />
                        <img
                          src={
                            user?.image
                              ? environment.assetsUrl + user?.image
                              : logos()
                          }
                          className="hide-logo w-[40px] h-[40px]"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/profile"
                      className={`text-[16px] hidentext text-[#fff] ml-2 ${
                        isOpen ? "hidden" : ""
                      }`}
                    >
                      {user?.fullName}
                    </Link>
                  </div>

                  <div
                    className={`flex  gap-2 w-full xl:mt-3 flex-grow sideflexrow ${
                      isOpen ? "flex-wrap" : "justify-between"
                    }`}
                  >
                    <p
                      className={`sideflex flex flex-grow justify-center items-center text-[#fff] text-[16px] 2xl:text-[20px] bg-[#7E20DC] py-2 px-5 rounded-full  ${
                        isOpen ? "flex-col gap-y-1 !text-[14px] !px-2" : ""
                      }`}
                    >
                      <img
                        src="/assets/img/lyfnew/p1.svg"
                        className={` h-[20px] object-contain mr-2 ${
                          isOpen ? "!w-[20] !h-[20px] !mr-0" : ""
                        }`}
                      />
                      {user?.coins}
                    </p>
                    <p
                      className={`sideflex flex flex-grow justify-center items-center text-[#fff] text-[16px] 2xl:text-[20px] bg-[#7E20DC] py-2 px-5 rounded-full  ${
                        isOpen ? "flex-col gap-y-1 !text-[14px] !px-2" : ""
                      }`}
                    >
                      <img
                        src="/assets/img/lyfnew/p2.png"
                        className={` h-[20px] object-contain mr-2 ${
                          isOpen ? "!w-[20] !h-[20px] !mr-0" : ""
                        }`}
                      />
                      {achievedCount ? achievedCount : 0}
                    </p>
                  </div>

                  <div className="progress_bars text-sm flex mt-2 flex-wrap justify-between w-full text-white">
                    <div className="">
                      <p>Level {user?.level ? user?.level : 0}</p>
                      <p className={`  ${
                      isOpen ? "hidden" : "block"
                    }`}>{user?.coins ? user?.coins : 0} points </p>
                    </div>
                    <div className="text-right ">
                      <div
                        className={`relative  h-4 mt-1 mb-1 bg-gray-200 rounded-full ${
                          isOpen ? "w-8" : "w-12 md:w-20 xl:w-28"
                        }`}
                      >
                        <div
                          className="absolute top-0 left-0 h-4 bg-blue-500 rounded-full"
                          style={{ width: `${progressWidth}%` }}
                        ></div>
                      </div>
                      <p className={`  ${
                      isOpen ? "hidden" : "block"
                    }`}>{maxLevel}</p>
                    </div>
                  </div>
                  
                </div>
                <div className="flex flex-col justify-between bg-[#F4EAFE] rounded-[24px] w-full py-6 h-full mt-2">
                  <div className=" ">
                    <Sidebar isOpen={isOpen} />
                  </div>

                  <div
                    className={`px-[15px]  ${
                      isOpen ? "!px-2 space-y-2" : "!px-2 space-y-2"
                    }`}
                  >
                      <div
                      className={`pt-1 px-2 rounded-md flex items-center gap-[10px] text-[16px] xl:text-[20px] font-normal text-neutral-800 hover:!text-[#7E20DC] !no-underline transition-all mt-4  ${
                        isOpen ? "flex-col" : ""
                      }`}
                    >
                      <Link className="flex gap-2" to="/profile">
                      <img
                        className={`  ${
                          isOpen ? "!mx-auto" : "!px-[0px] w-5 xl:w-8"
                        }`}
                        src="/assets/img/lyfnew/s5.svg"
                        alt=""
                      />
                        <p
                          className={`text-neutral-800 hover:!text-[#7E20DC] cursor-pointer text-[16px] xl:text-[20px] flex  ${
                            isOpen ? "hidden" : ""
                          }`}
                        >
                          Settings
                        </p>
                      </Link>
                    </div>
                    <div className="  pt-1 px-2 rounded-md flex items-center gap-[10px] text-[16px] xl:text-[20px] font-normal text-neutral-800 hover:!text-[#7E20DC] !no-underline transition-all ">
                   
                       <img
                          className={`  ${
                            isOpen ? "!mx-auto" : "!px-[0px] w-5 xl:w-8"
                          }`}
                          src="/assets/img/lyfnew/s4.svg"
                          alt=""
                        />
                      <p
                        className={`text-neutral-800 hover:!text-[#7E20DC] cursor-pointer text-[16px] xl:text-[20px] flex  ${
                          isOpen ? "hidden" : ""
                        }`}
                      >
                        Help
                      </p>
                    </div>

                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <main className="main overflow-auto xl:overflow-hidden h-full">
            <div
              className="mainarea border border-neutral-400 rounded-[25px] xl:rounded-[50px] "
              id={"myElement"}
            >
              {user?.verifiedGroupLeader != "approved" &&
              user.customerRole?._id == environment.glRoleId ? (
                <>
                  <div className="py-3 shadow-md mb-4 bg-gray-100 rounded-md text-red-600 mb-3 text-center">
                    {" "}
                    {user?.verifiedGroupLeader == "decline" ? (
                      <p className="flex items-center gap-x-2 text-lg  justify-center">
                        {" "}
                        <BsExclamationTriangle className="text-4xl animate-bounce" />
                        Your request is declined{" "}
                      </p>
                    ) : (
                      <p className="flex items-center gap-x-2 text-lg  justify-center">
                        <FcOvertime className="text-4xl animate-bounce" />
                        Your profile still is under review
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Layout;
