import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import methodModel from "../../methods/methods";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/user";
import { CHANGE_TAB, SEARCH_STATE } from "../../Redux/Actions/Action";
import { RxEyeClosed } from "react-icons/rx";
import FormControl from "../../components/common/FormControl";
import ImageUpload from "../../components/common/ImageUpload";
import { toast } from "react-toastify";
import { IoMdCheckmark } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Tooltip } from "antd";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const Profile = () => {
  const user = useSelector((state: any) => state?.user);

  const [data, setData]: any = useState("");
  const [profileEnabled, setProfileEnabled] = useState(true);
  const [universities, setUniversities] = useState([]);
  const [level, setLevel] = useState([]);
  const [userLevelIds, setUserLevelIds] = useState<any>({});
  const [badges, setBadges] = useState([]);
  const [minLevel, setMinLevel] = useState<number>(0);
  const [maxLevel, setMaxLevel] = useState<number>(0);
  const [ethnicities, setEthnicities] = useState([]);
  interface FormType {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    birthday: string;
    university: string;
    ethnicity: string;
    fullName: string;
    image: string;
  }
  const [form, setForm] = useState<FormType>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    birthday: "",
    university: "",
    ethnicity: "",
    fullName: "",
    image: "",
  });

  const dispatch = useDispatch();
  const history = useNavigate();

  const Logout = () => {
    dispatch(logout());
    dispatch(SEARCH_STATE(""));
    dispatch(CHANGE_TAB(""));

    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    history("/login");
  };

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`user/` + user.id).then((res) => {
      if (res.success) {
        setData(res.data);
        const value = res.data;
        const payload: { [key: string]: { id: string } } = {};
        const formKeys = Object.keys(form);

        formKeys.forEach((itm) => {
          if (value.hasOwnProperty(itm)) {
            payload[itm] = value[itm];
          }
        });
        if (payload?.university?.id) {
          getEthnicitiesList(payload?.university?.id);
        }
        setForm({
          ...form,
          ...payload,
          university: payload?.university?.id.toString(),
          ethnicity: payload?.ethnicity?.id.toString(),
        });
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user.loggedIn) {
      gallaryData();
    }
  }, []);
  const handleOpen = () => {
    setProfileEnabled(false);
  };

  const handleCancle = () => {
    setProfileEnabled(true);
  };
  const [images, setImages]: any = useState({ image: "" });
  const imageResult = (e: any, key: any = ""): any => {
    images[key] = e.value;
    setForm({ ...form, image: e?.value });
    setImages(images);
 
  };
  const getEthnicitiesList = (e: any) => {
    ApiClient.get(`ethnicities/list?university=${e}&count=10&page=1&status=active`).then(
      (res) => {
        if (res.success) {
          setEthnicities(res.data);
        }
      }
    );
  };
  const handleDateChange = (date :any) => {
    if (date) {
      const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`; // Format to mm-dd-yyyy
      setForm({ ...form, birthday: formattedDate });
    } else {
      setForm({ ...form, birthday: "" });
    }
  };
  const handleUniverity = (e: any) => {
    setForm({ ...form, university: e.toString() });
    getEthnicitiesList(e);
  };
  const getUniversitiesList = () => {
    ApiClient.get("universities/list").then((res) => {
      if (res.success) {
        setUniversities(res.data);
      }
    });
  };
  const getBadgesList = () => {
    ApiClient.get(`user/badges/${user?.id}`).then((res) => {
      if (res.success) {
        setBadges(res.data);
      }
    });
  };
  const universityOptions = universities?.map(({ id, name }) => {
    return { id: id, name: name };
  });
  useEffect(() => {
    getUniversitiesList();
    getBadgesList();
  }, []);
  const handleSubmit = () => {
    const fullName = `${form.firstName} ${form.lastName}`;
    const image = images?.image;
    const updatedForm = { ...form, fullName, image };
    ApiClient.put(`users/${user.id}`, updatedForm).then((res) => {
      if (res.success) {
        const updatedData = res.data;
        setForm((prevForm) => ({
          ...prevForm,
          ...updatedData,
        }));
        gallaryData();
      }

      toast.success(res.message);

      setProfileEnabled(true);
    });
  };
  // const getLevel = () => {
  //   ApiClient.get("settings/points").then((res) => {
  //     if (res.success) {
  //       const levels = res?.data || [];
  //       let minLevel = Infinity;
  //       let maxLevel = -Infinity;

  //       levels.forEach((level :any) => {
  //         if (level.Min < minLevel) minLevel = level.Min;
  //         if (level.Max > maxLevel) maxLevel = level.Max;
  //       });

  //       const matchedLevelIds = levels
  //         .filter((level :any) => user?.coins >= level.Min && user?.coins <= level.Max)
  //         .map((level:any) => level.id);

  //       setUserLevelIds(matchedLevelIds);

  //       setLevel(levels);
  //     }
  //   });
  // };
  const levelValue = () => {
    ApiClient.get(`settings/point/${user?.level ? user?.level : 0}`).then((res) => {
      if (res.success) {
        const levelData = res.data;
        setUserLevelIds(levelData);
        setMinLevel(levelData.Min || 0);
        setMaxLevel(levelData.Max || 0);
      }
    });
  };
  const calculateProgressWidth = () => {
    if (maxLevel > minLevel) {
      const progress = ((user?.coins - minLevel) / (maxLevel - minLevel)) * 100;
      return Math.min(Math.max(progress, 0), 100); // Ensure it's between 0% and 100%
    }
    return 0;
  };
  const progressWidth = calculateProgressWidth();
  useEffect(() => {
    levelValue();
  }, []);
  const slides = [];
  for (let i = 0; i < badges.length; i += 3) {
    slides.push(badges.slice(i, i + 3));
  }
  const currentDate = new Date();
  return (
    <div className="bg-white px-6 py-4  h-auto lg:h-screen flex items-center justify-center overflow-auto">
      <div id="logout" onClick={()=>Logout()}></div>
      <div className="wrapper_section bg-primary w-full  rounded-[32px] overflow-auto h-full flex items-center justify-center">
        <div className="grid grid-cols-12 gap-4 py-8 w-full">
          <div className="col-span-12 md:col-span-12 lg:col-span-3 2xl:col-span-2">
            <div className="flex flex-row shrink-0 lg:flex-col items-start px-2 lg:px-10 2xl:px-8  justify-between h-full">
              <div className="imges_names flex gap-2 items-center relative flex-wrap">
                <img
                  src={methodModel.userImg(images?.image || form?.image)}
                  className="h-16 w-16 xl:h-20 xl:w-20 rounded-full "
                />
                <div>
                  <p className="text-[12px] xl:text-[16px] font-regular text-[#fff] ml-2 ">
                    Hello,
                  </p>
                  <p className="text-[16px] 2xl:text-[30px] font-regular text-[#fff] ml-2 ">
                    {user?.fullName}
                  </p>
                </div>

                <Link
                  className="cursor-pointer absolute right-0 -top-4"
                  to="/dashboard"
                >
                  <img
                    className="cursor-pointer rotate-[180deg]"
                    src="../assets/img/lyfnew/exits.svg"
                  />
                </Link>
              </div>

              <div className="">
                <div className="buts_erors flex flex-col gap-y-4">
                  <button className="text-neutral-100 px-6 py-2 flex items-center font-semibold text-[18px]">
                    {" "}
                    <img src="../assets/img/lyfnew/whitedot.svg" className="" />
                    Report error
                  </button>
                  <button
                    className="text-neutral-100 px-6 py-2 flex items-center gap-2 font-semibold text-[18px]"
                    onClick={() => Logout()}
                  >
                    {" "}
                    <img src="../assets/img/lyfnew/log.svg" className="" />
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-12 lg:col-span-9  2xl:col-span-10">
            <div className="bg-white rounded-[32px] px-6 pl-5 lg:pl-14  2xl:pl-20 py-8 mr-6 ml-6 lg:ml-0 lg:mr-10 ">
              <div className="lg:h-[calc(80vh-100px)] xl:h-[calc(90vh-100px)] overflow-auto onlyscroll">
                <div className="flex items-center  justify-between">
                  <h3 className="text-[32px] font-regular text-[#111827]">
                    Profile Settings
                  </h3>
                </div>

                <div className="grid grid-cols-12 gap-4 xl:gap-8">
                  <div className="col-span-12 lg:col-span-6 xl:col-span-6">
                    <div className="flex  gap-4 xl:gap-8 mt-8">
                      <div className=" ">
                        <div className="flex flex-col justify-between h-full">
                          <div className="flex items-end gap-4">
                            <div className="relative shrink-0">
                              <img
                                src={methodModel.userImg(
                                  images?.image || form?.image
                                )}
                                className="h-24 w-24 rounded-full object-cover"
                              />
                              <p
                                className="cursor-pointer absolute -bottom-[8px] right-0 "
                                onClick={handleOpen}
                              >
                                <img
                                  className=""
                                  src="../assets/img/lyfnew/edit.svg"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-[90%] xl:w-[80%]">
                        <div className="grid grid-cols-12 gap-4 ">
                          <div className="col-span-12 ">
                            <div className="sub_fatch w-full">
                              <FormControl
                                type="text"
                                label="First Name "
                                value={form?.firstName}
                                onChange={(e: any) =>
                                  setForm({ ...form, firstName: e })
                                }
                                disabled={profileEnabled}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-span-12 ">
                            <div className="sub_fatch w-full">
                              <FormControl
                                type="text"
                                label="Last Name "
                                value={form?.lastName}
                                onChange={(e: any) =>
                                  setForm({ ...form, lastName: e })
                                }
                                required
                                disabled={profileEnabled}
                              />
                            </div>
                          </div>

                          <div className="col-span-12 ">
                            <div className="sub_fatch w-full">
                              {/* <FormControl
                                type="date"
                                label="Birthday "
                                value={form?.birthday}
                                onChange={(e: any) =>
                                  setForm({ ...form, birthday: e })
                                }
                                required
                                disabled={profileEnabled}
                              /> */}
                              <label className="text-neutral-800 font-regular  mb-2 flex  text-[14px]">
                      Birthday*
                    </label>
                      <div className="datepickers">
                                <DatePicker
                          selected={form.birthday ? new Date(form.birthday) : null} // Convert to Date object
                          onChange={handleDateChange}
                            className="w-full border border-neutral-300 h-12 bg-white !rounded-[16px] px-4 py-1"
                          dateFormat="MM-dd-yyyy" // Display format with full year
                          maxDate={currentDate} // Set maximum date
                          required
                          disabled={profileEnabled}
                          placeholderText="mm-dd-yyyy" // Placeholder for format guidance
                        />
                            </div>
                            </div>
                          </div>

                          <div className="col-span-12 md:col-span-12">
                            <div className="col-span-12 bg_select">
                              <div className="grid grid-cols-1  gap-4">
                                <div>
                                  <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                                    University*
                                  </label>

                                  <FormControl
                                    type="select"
                                    value={form.university}
                                    className="w-full"
                                    placeholder=" select University"
                                    onChange={handleUniverity}
                                    options={universityOptions}
                                    disabled={profileEnabled}
                                    // theme="search"
                                    // required
                                  />
                                </div>

                                <div>
                                  <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                                    Class*
                                  </label>

                                  <FormControl
                                    type="select"
                                    value={form.ethnicity}
                                    placeholder=" select Class"
                                    className="w-full"
                                    onChange={(e: string) =>
                                      setForm({
                                        ...form,
                                        ethnicity: e.toString(),
                                      })
                                    }
                                    options={ethnicities}
                                    // theme="search"
                                    required
                                    disabled={profileEnabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-12 md:col-span-12">
                            <div className="sub_fatch">
                              <FormControl
                                type="text"
                                label="E-mail "
                                value={form?.email}
                                onChange={(e: any) =>
                                  setForm({ ...form, email: e })
                                }
                                required
                                disabled={profileEnabled}
                              />
                            </div>
                          </div>

                          <div className="col-span-12">
                            <div className="">
                              <label className="lablefontcls block mb-2">
                                Image
                              </label>
                              {profileEnabled ? <button className="inline-block text-neutral-100 bg-[#283A97] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Upload Image</button> :  <ImageUpload
                                model="users"
                                name=""
                                result={(e: any) => imageResult(e, "image")}
                                err=""
                                type="image"
                                required={false}
                                value={images.image || form?.image}
                                editData={!profileEnabled}
                                multiple={false}
                                
                              />}
                             
                            </div>
                          </div>
                          {!profileEnabled ? (
                            <div className="bts_data w-full flex gap-4 items-center col-span-12 mt-6">
                              <button
                                className=" text-neutral-100 px-6 py-2 bg-primary rounded-full"
                                onClick={handleSubmit}
                              >
                                Save Changes
                              </button>
                              <p
                                className="text-primary font-bold cursor-pointer"
                                onClick={handleCancle}
                              >
                                Cancel
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6 xl:col-span-6">
                    <div className="flex flex-col h-full gap-6 2xl:justify-between">
                      <div className="bg-[#F4EAFE] p-4 xl:p-8  flex flex-col items-center justify-center rounded-xl m-6">
                        <div className="text-center">
                          <h4 className="text-[24px] 2xl:text-[32px] mb-6">
                            LevelUp
                          </h4>
                        </div>

                        <div className="progress_bars text-sm flex mt-2 flex-wrap justify-between w-full ">
                          <div className=" flex flex-col gap-4 w-[100%] xl:w-[80%]  mx-auto">
                            <div className="">
                              <div
                                className={`relative  h-8 mt-1 mb-1 bg-gray-200 rounded-full w-full`}
                              >
                                <div
                                  className="absolute top-0 left-0 h-8 bg-[#C186FB] rounded-full"
                                  style={{ width: `${progressWidth}%` }}
                                ></div>
                              </div>
                              <div className="flex items-center justify-between mt-2">
                                <p>{user?.coins} points</p>
                                <p>{maxLevel}</p>
                              </div>
                            </div>

                            <div className="text-center  pt-6  ">
                              <h4 className="text-primary text-[24px] 2xl:text-[32px] leading-10">
                                LEVEL {user?.level}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#F4EAFE] p-4 xl:p-8 rounded-xl m-6">
                        <div className="text-center">
                          <h4 className="text-[24px] 2xl:text-[32px] mb-6">
                            Badges
                          </h4>
                        </div>

                        {/* <div className="slider_rewards">
                          <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            breakpoints={{
                              640: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                              },
                              1100: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                              },
                              1300: {
                                slidesPerView: 3,
                                spaceBetween: 0,
                              },
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className="rewardsswip"
                          >
                            {badges?.map((ele :any, ind ) => {
                              return (
                                
                                 
                                <SwiperSlide>
                                  <div className="icons_images flex justify-around  gap-4">
                                    <div className="">
                                      {ele?.isAchieved == true ? <Tooltip placement="top" title={ele?.name}> <img src="/assets/img/lyfnew/stars.svg" /></Tooltip> : <img
                                        className="mb-4"
                                        src="/assets/img/lyfnew/lockbadge.svg"
                                      />}
                                     
                                      
                                     
                                    </div>

                                    
                                  </div>
                                </SwiperSlide>
                           
                              );
                            })}

                          </Swiper>
                        </div> */}
                          <div className="slider_rewards">                           
      <Swiper
        slidesPerView={1} // We are controlling the slides to show 1 set at a time
        spaceBetween={10} // Space between the sets of slides
        navigation={true} // Enable navigation (next/prev buttons)
        modules={[Navigation]} // Include the Navigation module
        className="rewardsswip"
        loop={false} // Disable looping
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="icons_images flex justify-around gap-4">
              {slide.map((ele :any, ind) => (
                <div key={ind} className="">
                  {ele.isAchieved ? (
                   <>
                     <img  className="mb-4"  src="/assets/img/lyfnew/stars.svg" alt={ele.name} />
                     <p className="capitalize">{ele?.name}</p></>
                    
                    
                  ) : (
                    <>  <img className="mb-4" src="/assets/img/lyfnew/lockbadge.svg" alt="locked badge" />
                    <p>Locked</p></>
                  
                  )}
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
