const types=[
  {id:'self',name:'Self'},
  {id:'social',name:'Social'},
  {id:'wealth',name:'Wealth'},
]
const shared = {
    check: "Quiz",
    title: "Quiz",
    addTitle: "Quiz",
    url: "Quiz",
    addApi: "resource",
    editApi: "resource",
    detailApi: "resource",
    recommandedResorceslistApi: "recommanded/resources",
    upcomingListApi: "events/list",
    statusApi: "task",
    deleteApi: "quiz",
     taskApi:"task/list",
     archivedList:"archived/resources/list",
    resourcesApi:"resource/list",
    archiveResources:"archive/resources",
    moveResources:"archived/resources",
    addToMyResources:"moved/resources",
    types: [...types],
    removedResources:"removed/resources",
    userDelete:"user/resource"
  };
  
  export default shared;