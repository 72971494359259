import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import Layout from "../../components/global/layout";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { IoCloseOutline, IoFlashOutline } from "react-icons/io5";
import { ImPlus } from "react-icons/im";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { LuPlus } from "react-icons/lu";
import {
  PiCurrencyCircleDollarFill,
  PiCurrencyDollarSimpleFill,
} from "react-icons/pi";
import { HiMiniPlusCircle } from "react-icons/hi2";
import ApiClient from "../../methods/api/apiClient";
import shared from "./shared";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../../components/common/SelectDropdown";
import { IoIosInformationCircle } from "react-icons/io";
import axios from "axios";

function Marketplace() {
  const user = useSelector((state) => state?.user);
  const searchState = { data: "" };
  const [loaging, setLoader] = useState(true);
  const cancelTokenSource = useRef(null);
  const initialRender = useRef(true);
  let [isOpen, setIsOpen] = useState(false);
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    level: user.level,
    amount: user?.coins,
    status: "active"
  });
  const [data, setData] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [count, setCount] = useState();
  const [total, setTotal] = useState(0);
  const [singleList, setSingleList] = useState([]);
  const [type, setType] = useState("");
  function closeModal() {
    setIsOpen(false);
  }

  function openModal(ele) {
    setSingleList(ele);
    setIsOpen(true);
  }

 
  useEffect(() => {
 
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }


    cancelTokenSource.current = axios.CancelToken.source();

    setFilter({ ...filters, search: searchState.data });
    getData({ search: searchState.data, page: 1, category: type });
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [type]);

  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };

    ApiClient.get(shared.listApi, filter, "", "", cancelTokenSource.current.token,)
      .then((res) => {
        if (res.success) {
          setData(res.data);
          setTotal(res.total);
        }
        loader(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });
  };

  const handleOrder = (ele) => {
    let payload = {
      user: user?.id.toString(),
      product_id: ele?.id.toString(),
      quantity: "1",
      coins: ele?.coin.toString(),
    };
    loader(true);
    ApiClient.post(shared.addCart, payload).then((res) => {
      if (res.success) {
        toast(res?.message);
        handleOrderList();
        loader(false);
      }
    });
  };
  const handleOrderList = () => {
    ApiClient.get(`${shared.cartList}?user=${user?.id}`).then((res) => {
      if (res.success) {
        setCartList(res?.data);
        setCount(res?.total);
      }
    });
  };
  const handleModule = (e) => {
    setType(e?.value);
  };
  // useEffect(() => {
  //   handleOrderList()
  // }, [])

  return (
    <Layout>
      {/* 
onClick={openModal} */}

      <div className="flex items-center gap-4 flex-wrap justify-between mb-8">
        <div className="flex gap-4 items-center flex-wrap">
          <p className="font-semibold text-[20px] lg:text-[24px] 2xl:text-[32px] flex items-center gap-2">
            {" "}
            <PiCurrencyCircleDollarFill className="text-3xl" />
            {user?.coins} Balance
          </p>
          <div className=" flex items-center gap-2 flex-wrap">
            <div className="lg:w-36 xl:w-64">
             
              <div className="">
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="Select Domain"
                  intialValue={type}
                  result={handleModule}
                  options={shared.types}
                  className="w-full"
                />
              </div>
            </div>

            
          </div>
        </div>

      
      </div>

      <div className="2xl:mt-10 2xl:mb-8 my-6">
        <h2 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular">
          {" "}
          {user?.level == 0
            ? "No Rewards Yet"
            : `Level ${user?.level ? user?.level : 0} Rewards `}
        </h2>
      </div>

      <div className="newscroll mt-2 2xl:mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6  xl:h-custom scrollcolor pr-4">
          {data?.map((ele) => {
            return (
              <div className="border border-neutral-300 g-white rounded-[24px] p-3">
                <div className="flex items-center justify-between">
                  <p
                    class={` px-4 py-2 rounded-full text-[12px] text-neutral-100 capitalize ${ele?.category === "wealth" ? " bg-wealth" : ""
                      }${ele?.category === "self" ? " bg-self " : ""}${ele?.category === "social" ? " bg-social " : ""
                      }`}
                  >
                    {ele?.category}
                  </p>
                 
                </div>
                <div className="images_tage rounded-[24px] mt-4">
                  <img
                    className="w-full h-44 object-contain bg-white"
                    onError={(event) => {
                      event.target.src = "../../assets/img/imagelogo.jpg";
                      event.onerror = null;
                    }}
                    src={methodModel.userImg(ele?.image)}
                  />
                </div>

                <div className="mt-4 ">
                  <h5 className="mb-3 text-[18px] font-regular capitalize">
                    {ele?.account_type}
                  </h5>
                 

                  {/* <p className="text-[14px] font-regular break-all">
                    {ele?.discount?.[0]?.amount.includes("http") ? (
                      <a href={ele?.discount?.[0]?.amount} target="_blank" rel="noopener noreferrer">
                        {ele?.discount?.[0]?.amount}
                      </a>
                    ) : (
                      ele?.discount?.[0]?.amount
                    )}
                  </p> */}

                  <div className="flex items-center justify-end mt-4">
                    <p
                      onClick={() => openModal(ele)}
                      className="text-primary font-three font-semibold text-2xl cursor-pointer"
                    >
                      <IoIosInformationCircle />
                    </p>
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white px-6 py-2 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end items-end">
                    <IoCloseOutline
                      onClick={closeModal}
                      className="text-2xl cursor-pointer"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="modal_inners">
                      <div className="flex items-center justify-between">
                        <p
                          class={` px-4 py-2 rounded-full text-[12px] text-neutral-100 capitalize ${singleList?.category === "wealth"
                            ? " bg-wealth"
                            : ""
                            }${singleList?.category === "self" ? " bg-self " : ""
                            }${singleList?.category === "social"
                              ? " bg-social "
                              : ""
                            }`}
                        >
                          {singleList?.category}
                        </p>
                        {/* <div className="flex items-center gap-2 text-[#7E20DC]">
                <HiMiniPlusCircle className="text-3xl " />
                <p className="font-semibold text-[16px]">{ele?.coin}COINS</p>
              </div> */}
                      </div>
                      <div className="imsg_data">
                        <img
                          className="h-56 w-full object-contain"
                          src={methodModel.userImg(singleList?.image)}
                        />
                      </div>
                      <div className="px-10 py-2 flex justify-between ">
                        <div className="">
                          <div className="  text-[16px] xl:text-[20px] text-[#004AAD] 2xl:text-[30px]">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: singleList?.account_type,
                              }}
                            ></p>
                          </div>




                          <p className="decriptions mt-4 "
                            dangerouslySetInnerHTML={{
                              __html: singleList?.terms,
                            }}
                          ></p>
                          <h2 className="text-[18px] font-regular break-all mt-2">
                            {singleList?.discount?.[0]?.amount.includes("http") ? (
                              <a href={singleList?.discount?.[0]?.amount} target="_blank" rel="noopener noreferrer">
                                {singleList?.discount?.[0]?.amount}
                              </a>
                            ) : (
                              singleList?.discount?.[0]?.amount
                            )}
                          </h2>
                          {/* <p className="text-[16px] text-[#004AAD] lg:text-[20px]">Planet Fitness</p> */}
                        </div>

                       
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Layout>
  );
}

export default Marketplace;
