import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import Layout from "../../components/global/layout";
import { Menu, Transition } from "@headlessui/react";
import { IoCloseCircleOutline, IoMoveSharp } from "react-icons/io5";
import axios from "axios";
import { useSelector } from "react-redux";
import { Dialog } from "@headlessui/react";
import { LuPlus } from "react-icons/lu";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { HiMiniPlusCircle } from "react-icons/hi2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import shared from "./shared";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import SelectDropdown from "../../components/common/SelectDropdown";
import ImageUpload from "../../components/common/ImageUpload";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import { useParams } from "react-router-dom";
import View from "./view";
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
function Tasks() {
  const reactQuillRef = useRef(null);
  const history = useNavigate();
  const user = useSelector((state) => state?.user);
  let [isOpen, setIsOpen] = useState(false);
  const [recomdedTasks, setRecomdedTasks] = useState();
  const [DiscoverTasks, setDiscoverTasks] = useState();
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState("");
  const [module, setModule] = useState("");
  const [images, setImages] = useState({ image: "" });
  const [category, setCategory] = useState("");
  const [showError, setShowError] = useState(false);
  const [selectTask, setSelectTask] = useState(1);
  const [search, setSearch] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const { task } = useParams();
  const ArchivedListCancelTokenSource = useRef(null);
  const RecommendedcancelTokenSource = useRef(null);
  const DiscovercancelTokenSource = useRef(null);
  const mytaskcancelTokenSource = useRef(null);
  const PrioritycancelTokenSource = useRef(null);
  const [taskList, setTaskList] = useState(
    task == "discover"
      ? 1
      : task == "mytask"
      ? 2
      : task == "archivedtask"
      ? 3
      : 1
  );
  const [viewData, setViewData] = useState();
  const [archivedList, setArchivedList] = useState();
  const [hideAddButton, setHideAddButton] = useState();

  const [item, setItem] = useState({
    _item: null,
    isStatus: false,
  });
  const [form, setForm] = useState({
    discription: "",
    type: "",
    priortyTask: false,
    uploadDocument: [],
    insertLink: [],
    name: "",
    status: "active",
  });
  const tasks = [
    {
      id: 1,
      name: "Discover Task",
    },
    {
      id: 2,
      name: "My Tasks",
    },
    {
      id: 3,
      name: "Archived Task",
    },
  ];
  
  //   useEffect(()=>{
  // if (taskList == 1){
  //   history("/task/discover")

  // }
  // else if (taskList == 2){
  //   history("/task/mytask")}
  //   else if (taskList == 3){
  //     history("/task/archivedtask")

  //   }
  //   },[taskList])
  useEffect(() => {
    if (isOpen) {
      if (reactQuillRef.current) {
        const editor = reactQuillRef.current.getEditor();
      }
    }
  }, []);
  //   useEffect(() => {
  //     if(mytask == "mytask"){
  //       setSelectTask(2)
  //     }
  //     else{
  //       setSelectTask(1)
  //     }

  //  }, [mytask]);
  useEffect(() => {
    ArchivedListCancelTokenSource.current = axios.CancelToken.source();
    RecommendedcancelTokenSource.current = axios.CancelToken.source();
    DiscovercancelTokenSource.current = axios.CancelToken.source();
    mytaskcancelTokenSource.current = axios.CancelToken.source();
    PrioritycancelTokenSource.current = axios.CancelToken.source();
    taskfilter(
      task == "discover"
        ? 1
        : task == "mytask"
        ? 2
        : task == "archivedtask"
        ? 3
        : 1
    );
    return () => {
      if (ArchivedListCancelTokenSource.current) {
        ArchivedListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (RecommendedcancelTokenSource.current) {
        RecommendedcancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (DiscovercancelTokenSource.current) {
        DiscovercancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (mytaskcancelTokenSource.current) {
        mytaskcancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (PrioritycancelTokenSource.current) {
        PrioritycancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [type, module, task, search]);

  const taskfilter = (taskList) => {
    if (taskList == 2) {
      DiscoverTaskList(taskList);
      priorityTaskList();
    } else if (taskList == 3) {
      getArchivedTask();
    } else {
      recommendedTaskList();
      DiscoverTaskList(taskList);
    }
  };
  // Move MY TASK AND Priority TASK TO Archived Task
  // const handleMoveTask = (ele) => {
  //   loader(true);
  //   let payload = {
  //     user: user?.id?.toString(),
  //     task: ele?.toString(),
  //   };
  //   ApiClient.post(`${shared?.moveTask}`, payload).then((res) => {
  //     if (res?.success) {
  //       toast.success(res?.message);
  //       taskfilter(2);
  //       CloseViewModal();
  //     } else {
  //       toast.error(res?.message);
  //     }
  //     loader(false);
  //   });
  // };
  const [selectedTasks, setSelectedTasks] = useState([]);

  const handleMoveTask = (eleId) => {
    loader(true);

    // Toggle task selection
    setSelectedTasks((prevSelectedTasks) => {
      if (prevSelectedTasks.includes(eleId)) {
        // Remove task if already selected
        return prevSelectedTasks.filter((id) => id !== eleId);
      } else {
        // Add task if not selected
        return [...prevSelectedTasks, eleId];
      }
    });

    // Your API call can remain the same
    let payload = { user: user?.id?.toString(), task: eleId?.toString() };
    ApiClient.post(`${shared?.moveTask}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        taskfilter(2);
        CloseViewModal();
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };

  //
  // MOVE Archived Task

  const handleRestoreTask = (ele) => {
    loader(true);
    ApiClient.delete(`${shared?.archiveTask}/${ele?.id}`).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        taskfilter(3);
        setSelectedTasks([]);
        loader(false);
      } else {
        toast.error(res?.message);
      }
    });
  };
  //
  // Move recommmend to discover task
  const handleRemoveRecommendedToDiscover = (ele) => {
    loader(true);
    let payload = {
      user: user?.id?.toString(),
      task: ele?.id?.toString(),
    };
    ApiClient.post(`${shared?.removedTask}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        taskfilter(1);
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };
  // Add to Task
  const handleaddToMyTask = (ele) => {
    loader(true);
    let payload = {
      user: user?.id?.toString(),
      task: ele?.toString(),
    };
    ApiClient.post(`${shared?.addToMyTask}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        recommendedTaskList()
        DiscoverTaskList(1)
        CloseViewModal();
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };
  //
  const recommendedTaskList = () => {
    loader(true);
    let payload = {
      user: user?.id,
      type: type,
      category: module,
      search: search,
      status: "active",
    };

    ApiClient.get(`${shared?.recommandedTasklistApi}`, payload ,"","", RecommendedcancelTokenSource.current.token).then((res) => {
      setRecomdedTasks(res?.data);
      loader(false);
    });
  };
  const DiscoverTaskList = (taskList) => {
    let payload;
    if (taskList == 2) {
      payload = {
        addedBy: user?.id,
        isTask: true,
        priortyTask:false,
        page: 1,
        count: 10,
        search: search,
        type: type,
        category: module,
        // status: "active",
      };
    } else {
      payload = {
        page: 1,
        count: 10,
        search: search,
        type: type,
        category: module,
        isRecommanded: false,
        // addedBy: user?.id,
        // isDeleted: false,
        isAdmin: true,
        status: "active",
      };
    }
    loader(true);
    ApiClient.get(`${shared?.taskList}`, payload ,"","", taskList == 2 ? mytaskcancelTokenSource.current.token : DiscovercancelTokenSource.current.token ).then((res) => {
      if (res?.success == true) {
        loader(false);
        setDiscoverTasks(res?.data);
      }
    });
  };

  const getArchivedTask = () => {
    let payload = {
      user: user?.id,
      page: 1,
      count: 10,
      search: search,
      type: type,
      category: module,
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.archivedList}`, payload ,"","",ArchivedListCancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setArchivedList(res?.data);
      }
    });
  };
  const priorityTaskList = () => {
    let payload = {
      addedBy: user?.id,
      priortyTask: true,
      isTask: true,
      page: 1,
      count: 10,
      search: search,
      type: type,
      category: module,
      // status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.taskList}`, payload ,"","", PrioritycancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setRecomdedTasks(res?.data);
      }
    });
  };

  const handleDomain = (e) => {
    setForm({ ...form, type: e.value });
    if (e) {
      let category_type = "master";
      let url = `categories/list?category_type=${category_type}&type=${e.value}`;
      getCategory(url);
    }
  };

  const handleModule = (e) => {
    setType(e?.value);
    if (e) {
      let category_type = "master";
      let url = `categories/list?category_type=${category_type}&type=${e.value}`;
      getCategory(url);
    }
  };
  const options = categories?.map(({ id, name }) => {
    return { id: id, name: name };
  });
  const getCategory = (url) => {
    loader(true);
    ApiClient.get(url).then((res) => {
      if (res.success) {
        loader(false);
        setCategories(res?.data);
      }
    });
  };
  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setForm({ ...form, uploadDocument: [e?.value] });
  };

  function closeModal() {
    setIsOpen(false);
    setForm({
      type: "",
      priortyTask: false,
      uploadDocument: [],
      insertLink: [],
      discription: "",
    });
    setType("");
    setModule("");
    setImages({ image: "" });
    setCategory("");
    setShowError(false); // This will hide all error messages
  }

  function openModal() {
    setIsOpen(true);
    setForm({
      discription: "",
      type: "",
      priortyTask: false,
      uploadDocument: [],
      insertLink: [],
    });
  }
  function openViewModal(ele, taskname) {
    setShowViewModal(true);
    setHideAddButton(taskname);
    setViewData(ele);
  }
  function CloseViewModal() {
    setShowViewModal(false);
  }
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot", // Optional if using custom formats
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // if (form?.discription?.length == 0 && type == "" && module == "") {
    //   toast?.error("Please fill all fields  ");
    //   return;
    // }

    if (form.discription.length === 0 || form.type === "" || category === "") {
      toast.error("Please fill all required fields");
      return;
    } else {
      let value = {
        ...form,
        category: category.toString(),
        name: "",
      };
      let url = "task";
      let method = "post";
      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          setShowError(false);
          toast.success(res?.message);
          loader(false);
          setCategory("");
          setForm({});
          setImages({ image: "" });
          setIsOpen(false);
          setTaskList(2);
          // recommendedTaskList();
          DiscoverTaskList(2);
          priorityTaskList();
        } else {
          setTimeout(() => {
            toast?.error(res?.message);
          }, 1000);
        }
      });
    }
  };

  const handleDeleteItem = () => {
    loader(true);
    ApiClient.delete(shared.deleteApi + `/${itemToDelete}`).then((res) => {
      if (res.success) {
        setSelectTask(2);
        priorityTaskList();
        DiscoverTaskList(2);
      }
      loader(false);
    });
    setShowConfirm(false);
  };
  const toggeleShowConfirm = () => {
    setShowConfirm(!showConfirm);
  };
  const deleteItem = (id) => {
    toggeleShowConfirm();
    setItemToDelete(id);
  };
  const handleOnConfirm = () => {
    handleDeleteItem();
  };

  useEffect(() => {
    let element = document.getElementById("myElement");
    element.classList.remove("mainarea");
    element.classList.add("mainarea_scrool");
  });

  return (
    <>
      <Layout>
        <div className="w-full mx-auto">
          <div className="flex flex-col lg:flex-row lg:items-end 2xl:items-center gap-4 justify-between">
            <div className="flex flex-wrap gap-2 items-center">
              <p className="font-semibold text-[18px]">Filter:</p>
              <div className=" flex flex-wrap items-center gap-2">
                <div className="menus_items">
                  <div className="">
                    <SelectDropdown
                      isSingle={true}
                      id="statusDropdown"
                      displayValue="name"
                      placeholder="Select Domain"
                      intialValue={type}
                      result={handleModule}
                      options={shared.types}
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="">
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Module"
                    intialValue={module}
                    result={(e) => {
                      setModule(e.value);
                    }}
                    options={options}
                    className="w-full"
                  />
                </div>

                <div className="">
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    // placeholder=" Task"
                    intialValue={taskList}
                    result={(e) => {
                      // taskfilter(e.value);
                      setTaskList(e.value);
                      if (e.value == 1) {
                        history("/task/discover");
                      } else if (e.value == 2) {
                        history("/task/mytask");
                      } else if (e.value == 3) {
                        history("/task/archivedtask");
                      }
                    }}
                    options={tasks}
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="menus_items shrink-0 flex items-center gap-2">
              <div className="" onClick={openModal}>
                <Menu as="div" className="relative block">
                  <div>
                    <Tooltip placement="top" title="Add New Task">
                      <Menu.Button className="flex  justify-between items-center gap-2 border border-neutral-200 rounded-full bg-primary h-12  p-3 text-[14px] xl:text-[17px] font-normal text-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                        <LuPlus />
                        Add{" "}
                        <span className="hidden 2xl:inline-flex">New Task</span>
                      </Menu.Button>
                    </Tooltip>
                  </div>
                </Menu>
              </div>

              <div className="searhings">
                <div className="border flex items-center gap-2 border-neutral-400 p-3 h-12 rounded-full">
                  <img src="../assets/img/lyfnew/search.svg" />
                  <input
                    type="search"
                    className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-20 xl:w-32"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {taskList == 3 ? (
            <div className="grid grid-cols-12 gap-4">
              {" "}
              <div className="col-span-12 lg:col-span-6">
                <div className="mt-8">
                  <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular mb-6">
                    Archived Task
                  </h2>
                  <div className="mt-4 pb-10 newscroll">
                    <div className="grid grid-cols-1  gap-6   pr-5">
                      {archivedList?.length > 0 ? (
                        archivedList?.map((ele, ind) => {
                          return (
                            <div className="border-b border-neutral-400 pb-4 ">
                              <p
                                className={` px-4 py-1 rounded-full text-[12px] text-neutral-100 inline-flex  ${
                                  ele?.task?.type === "wealth"
                                    ? " bg-wealth"
                                    : ""
                                }${
                                  ele?.task?.type === "self" ? " bg-self " : ""
                                }${
                                  ele?.task?.type === "social"
                                    ? " bg-social "
                                    : ""
                                }`}
                              >
                                {ele?.task?.type?.charAt(0)?.toUpperCase() +
                                  ele?.task?.type?.slice(1)}
                              </p>
                              <div className="flex items-center gap-4 justify-between">
                                <p
                                  className="font-regular mt-2 text-[18px] cursor-pointer"
                                  onClick={() =>
                                    openViewModal(ele, "ArchivedTask")
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.task?.discription,
                                  }}
                                ></p>

                                <MdRefresh
                                  className="text-xl cursor-pointer shrink-0"
                                  title="Restore"
                                  onClick={() => handleRestoreTask(ele)}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center"> No Tasks Yet</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-12 gap-6 2xl:gap-16">
              <div className="col-span-12 lg:col-span-6">
                <div className="flex items-center gap-2 my-8">
                  <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular">
                    {taskList == 1
                      ? recomdedTasks?.length > 0
                        ? "Recommended Tasks"
                        : ""
                      : "Priority Tasks"}
                  </h2>
                </div>
                <div className="mt-4 pb-10 newscroll">
                  <div className="grid grid-cols-1 gap-6">
                    {recomdedTasks?.map((ele, ind) => {
                      return (
                        <div className="border border-neutral-400 bg-neutral-100 rounded-[24px] p-4">
                          <div className="flex justify-between items-center mb-4">
                            <p
                              className={` px-4 py-1 rounded-full text-[12px] text-neutral-100   ${
                                ele?.type === "wealth" ? " bg-wealth" : ""
                              }${ele?.type === "self" ? " bg-self " : ""}${
                                ele?.type === "social" ? " bg-social " : ""
                              }`}
                            >
                              {ele?.type?.charAt(0)?.toUpperCase() +
                                ele?.type?.slice(1)}
                            </p>
                            <div className="flex items-center gap-4">
                              {taskList == 1 ? (
                                recomdedTasks?.length > 0 ? (
                                  <>
                                    <Tooltip
                                      placement="top"
                                      title="Add to my Task"
                                    >
                                      {" "}
                                      <HiMiniPlusCircle
                                        onClick={() =>
                                          handleaddToMyTask(ele?.id)
                                        }
                                        className={` text-3xl cursor-pointer ${
                                          ele?.type === "wealth"
                                            ? " text-wealth"
                                            : ""
                                        }${
                                          ele?.type === "self"
                                            ? " text-self "
                                            : ""
                                        }${
                                          ele?.type === "social"
                                            ? " text-social "
                                            : ""
                                        }`}
                                      />{" "}
                                    </Tooltip>

                                    <button
                                      className="text-xs px-2 py-1 text-white bg-red-500 rounded-full"
                                      onClick={() =>
                                        handleRemoveRecommendedToDiscover(ele)
                                      }
                                    >
                                      <Tooltip
                                        placement="top"
                                        title="Move the task from Recommended to Discover"
                                      >
                                        {" "}
                                        <IoMoveSharp />
                                      </Tooltip>
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )
                              ) : (
                                <>
                                  <p
                                    className="text-xs bg-red-500 px-2 py-1 text-white rounded-full"
                                    onClick={() => deleteItem(ele?.id)}
                                  >
                                    Delete
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="testings_datas">
                            <p
                              onClick={() =>
                                openViewModal(
                                  ele,
                                  taskList == 1
                                    ? "RecommendedTasks"
                                    : "PriorityTasks"
                                )
                              }
                              className=" font-regular text-[18px] cursor-pointer"
                              dangerouslySetInnerHTML={{
                                __html: ele?.discription,
                              }}
                            ></p>
                          </div>
                          <div className="flex items-center justify-end">
                            {taskList == 2 ? (
                              <>
                                <Tooltip
                                  placement="top"
                                  title="Move to archive Task "
                                >
                                  {" "}
                                  <label className="inline-flex items-center">
                                    {/* <input
                                    type="checkbox"
                                    className="hidden peer"
                                    onClick={() => handleMoveTask(ele?.id)}
                                  /> */}
                                    <input
                                      type="checkbox"
                                      className="hidden peer"
                                      checked={selectedTasks.includes(ele?.id)}
                                      onChange={() => handleMoveTask(ele?.id)}
                                    />
                                    <div
                                      className={`w-6 h-6 border-2 cursor-pointer rounded-full flex items-center justify-center ${
                                        selectedTasks.includes(ele?.id)
                                          ? "border-blue-900 bg-blue-900"
                                          : "border-gray-400"
                                      }`}
                                    ></div>
                                    {/* <div className="w-6 h-6 border-2 border-gray-400 cursor-pointer rounded-full peer-checked:border-blue-900 peer-checked:bg-blue-900 flex items-center justify-center"></div> */}
                                  </label>
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-6">
                <div className="mt-8">
                  <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular mb-6">
                    {taskList == 1 ? "Discover Tasks" : "My Tasks"}
                  </h2>
                  <div className="mt-4 pb-10 newscroll">
                    <div className="grid grid-cols-1  gap-6   pr-5">
                      {DiscoverTasks?.length > 0 ? (
                        DiscoverTasks?.map((ele, ind) => {
                          return (
                            <div className="border-b border-neutral-400 pb-4 ">
                              <p
                                className={` px-4 py-1 rounded-full text-[12px] text-neutral-100 inline-flex  ${
                                  ele?.type === "wealth" ? " bg-wealth" : ""
                                }${ele?.type === "self" ? " bg-self " : ""}${
                                  ele?.type === "social" ? " bg-social " : ""
                                }`}
                              >
                                {ele?.type?.charAt(0)?.toUpperCase() +
                                  ele?.type?.slice(1)}
                              </p>
                              <div className="flex items-center gap-4 justify-between">
                                <p
                                  className="font-regular mt-2 text-[18px] cursor-pointer"
                                  onClick={() =>
                                    openViewModal(
                                      ele,
                                      taskList == 1
                                        ? "DiscoverTasks"
                                        : "MyTasks"
                                    )
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.discription,
                                  }}
                                ></p>
                                {taskList == 2 ? (
                                  <>
                                    {/* <label className="inline-flex items-center shrink-0 pl-6">
                                      <input
                                        type="checkbox"
                                        className="hidden peer"
                                        onClick={() => handleMoveTask(ele?.id)}
                                      />
                                      <div className="w-6 h-6 border-2 border-gray-400 cursor-pointer rounded-full peer-checked:border-blue-900 peer-checked:bg-blue-900 flex items-center justify-center"></div>
                                    </label> */}
                                    <Tooltip
                                      placement="top"
                                      title="Move to archive Task "
                                    >
                                      <label className="inline-flex items-center shrink-0 pl-6">
                                        <input
                                          type="checkbox"
                                          className="hidden peer"
                                          checked={selectedTasks.includes(
                                            ele?.id
                                          )}
                                          onChange={() =>
                                            handleMoveTask(ele?.id)
                                          }
                                        />
                                        <div
                                          className={`w-6 h-6 border-2 cursor-pointer rounded-full flex items-center justify-center ${
                                            selectedTasks.includes(ele?.id)
                                              ? "border-blue-900 bg-blue-900"
                                              : "border-gray-400"
                                          }`}
                                        ></div>
                                        {/* <div className="w-6 h-6 border-2 border-gray-400 cursor-pointer rounded-full peer-checked:border-blue-900 peer-checked:bg-blue-900 flex items-center justify-center"></div> */}
                                      </label>
                                    </Tooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                                {taskList == 1 ? (
                                  <Tooltip
                                    placement="top"
                                    title="Add to my Task"
                                  >
                                    <HiMiniPlusCircle
                                      onClick={() => handleaddToMyTask(ele?.id)}
                                      className={` text-2xl cursor-pointer shrink-0 ${
                                        ele?.type === "wealth"
                                          ? " text-wealth"
                                          : ""
                                      }${
                                        ele?.type === "self"
                                          ? " text-self "
                                          : ""
                                      }${
                                        ele?.type === "social"
                                          ? " text-social "
                                          : ""
                                      }`}
                                    />{" "}
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center"> No Tasks Yet</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl transform  rounded-2xl bg-[#F4EAFE] px-6 py-2 text-left align-middle shadow-xl transition-all">
                      <div className="flex justify-end items-end">
                        <IoCloseCircleOutline
                          onClick={closeModal}
                          className="text-2xl cursor-pointer"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="modal_inners">
                          <form onSubmit={HandleSubmit}>
                            <div className="grid grid-cols-12 gap-4">
                              <div className="col-span-12">
                                <div className="quills">
                                  <ReactQuill
                                    ref={reactQuillRef}
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={form.discription}
                                    onChange={(e) =>
                                      setForm({ ...form, discription: e })
                                    }
                                    className=""
                                  />
                                  {form?.discription == "" && showError ? (
                                    <span className="text-danger text-xs fs-6">
                                      Description is required
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="filters_all flex items-center  gap-2 mt-6">
                              <div className="flex items-start gap-2">
                                <div className="menus_items">
                                  <div className="">
                                    <SelectDropdown
                                      isSingle={true}
                                      id="statusDropdown"
                                      displayValue="name"
                                      placeholder="Select Domain"
                                      intialValue={form.type}
                                      result={handleDomain}
                                      options={shared.types}
                                      className="w-full"
                                    />
                                    {/* {form?.type == "" && showError ? (
                                      <span className="text-danger text-xs fs-6">
                                        Domain Name is required
                                      </span>
                                    ) : (
                                      ""
                                    )} */}
                                    {showError && form.type === "" && (
                                      <span className="text-danger text-xs fs-6">
                                        Domain Name is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="menus_items">
                                  <div className="">
                                    <SelectDropdown
                                      isSingle={true}
                                      id="statusDropdown"
                                      displayValue="name"
                                      placeholder="Select Module"
                                      intialValue={category}
                                      result={(e) => {
                                        setCategory(e.value);
                                      }}
                                      options={options}
                                      className="w-full"
                                    />
                                    {/* {category == "" && showError ? (
                                      <span className="text-danger text-xs fs-6">
                                        Module Name is required
                                      </span>
                                    ) : (
                                      ""
                                    )} */}
                                    {showError && category === "" && (
                                      <span className="text-danger text-xs fs-6">
                                        Module Name is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="flex items-center gap-2">
                                <input
                                  type="checkbox"
                                  className="h-5 w-5 cursor-pointer "
                                  style={{ accentColor: "#5C0BAD" }}
                                  checked={form.priortyTask}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      priortyTask: e.target.checked,
                                    })
                                  }
                                />
                                <span>Priority Task</span>
                              </div>
                            </div>

                            <div className="flex items-center  mt-10 mb-8 justify-between">
                              <div className="flex gap-4">
                                <div className="">
                                  <label className="underline cursor-pointer text-center text-sm underline-offset-4 relative flex flex-col items-center gap-2">
                                    <ImageUpload
                                      model="users"
                                      name="upload"
                                      result={(e) => imageResult(e, "image")}
                                      value={images.image || form.image}
                                      multiple={false}
                                    />
                                    Upload File
                                  </label>
                                </div>

                                <div className="max-w-96">
                                  <div className="flex flex-col items-center gap-2">
                                    <img
                                      src="../assets/img/lyfnew/a.svg"
                                      className="h-6"
                                    />
                                    <input
                                      type="text"
                                      placeholder="Insert Link"
                                      value={form.insertLink}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          insertLink: [e?.target?.value],
                                        })
                                      }
                                      className="border-none text-sm text-center placholder_color bg-transparent p-0 focus:outline-none"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center gap-4">
                                <button
                                  onClick={() => {
                                    setShowError(true);
                                  }}
                                  type="submit"
                                  className="bg-primary  px-6 py-2 text-[#fff] text-[16px] font-semibold rounded-full"
                                >
                                  Add Task
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
        <SweetAlert
          show={showConfirm}
          title={"Are you sure?"}
          message={
            "You will not be able to revert this change. Do you want to delete this?"
          }
          buttonText={item.isStatus ? "Confirm" : "Delete"}
          showCloseButton={false}
          onConfirm={handleOnConfirm}
          didClose={() => {
            setShowConfirm(false);
            setItem({ item: null, isStatus: false });
          }}
        />
        <View
          openModal={showViewModal}
          closeModal={CloseViewModal}
          data={viewData}
          hideAddButton={hideAddButton}
          handleMoveTask={handleMoveTask}
          handleaddToMyTask={handleaddToMyTask}
          isLayout={false}
          DiscoverTaskList={DiscoverTaskList}
          priorityTaskList={priorityTaskList}
        />
      </Layout>
    </>
  );
}

export default Tasks;
