import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import Layout from "../../components/global/layout";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { IoCloseCircleOutline, IoMoveSharp } from "react-icons/io5";
import { Dialog } from "@headlessui/react";
import { LuPlus } from "react-icons/lu";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ApiClient from "../../methods/api/apiClient";
import * as Yup from "yup";
import shared from "./shared";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import ImageUpload from "../../components/common/ImageUpload";
import SelectDropdown from "../../components/common/SelectDropdown";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { MdEdit, MdRefresh } from "react-icons/md";
import View from "./view";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { Tooltip } from "antd";
function Resources() {
  const user = useSelector((state) => state?.user);
  const history = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState({ image: "" });
  const [submitted, setSubmitted] = useState(false);
  const reactQuillRef = useRef(null);
  const { resources } = useParams();
  const [selectedResources, setSelectedResources] = useState([]);
  const [resourcesList, setresourcesList] = useState(
    resources == "discover"
      ? 1
      : resources == "myresources"
      ? 2
      : resources == "archivedresources"
      ? 3
      : 1
  );
  const [showViewModal, setShowViewModal] = useState(false);
  const [RecomdedResources, setRecomdedResources] = useState();
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [viewData, setViewData] = useState();
  const [module, setModule] = useState("");
  const [search, setSearch] = useState("");
  const [archivedList, setArchivedList] = useState();
  const [showError, setShowError] = useState(false);
  const [selectResourse, setSelectResourse] = useState("");
  const [discoverResourse, setDiscoverResourse] = useState();
  const [hideAddButton, setHideAddButton] = useState();
  const [ModalId, setModalId] = useState();
  const recommendListCancelTokenSource = useRef(null);
  const upcomingDiscoverListCancelTokenSource = useRef(null);
  const getResourcesListCancelTokenSource = useRef(null);
  const handlearchivedCancelTokenSource = useRef(null);
  const [form, setForm] = useState({
    discription: "",
    type: "",
    priortyResources: false,
    uploadDocument: [],
    insertLink: [],
  });
  const tasks = [
    {
      id: 1,
      name: "Discover Resources",
    },
    {
      id: 2,
      name: "My Resources",
    },
    {
      id: 3,
      name: " Archived Resources",
    },
  ];
  useEffect(() => {
    if (isOpen) {
      if (reactQuillRef.current) {
        const editor = reactQuillRef.current.getEditor();
      }
    }
  }, []);
  useEffect(() => {
    resourcesfilter(
      resources == "discover"
        ? 1
        : resources == "myresources"
        ? 2
        : resources == "archivedresources"
        ? 3
        : 1
    );
  }, [type, module, resources, search]);
  const resourcesfilter = (resoucesList) => {
    if (resoucesList == 2) {
      DiscoverResourseList(resoucesList);
      priorityResourseList();
    } else if (resoucesList == 3) {
      getArchivedResources();
    } else {
      recommendResourseList();
      DiscoverResourseList(resoucesList);
    }
  };
  // Add to Task
  const handleaddToMyResources = (ele) => {
    loader(true);
    let payload = {
      user: user?.id?.toString(),
      resources: ele?.toString(),
    };
    ApiClient.post(`${shared?.addToMyResources}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        CloseViewModal();
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };
  // MOVE Archived Task

  const handleRestoreResource = (ele) => {
    loader(true);
    ApiClient.delete(`${shared?.archiveResources}/${ele?.id}`).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        resourcesfilter(3);
        setSelectResourse("");
        loader(false);
      } else {
        toast.error(res?.message);
      }
    });
  };
  const getArchivedResources = () => {
    let payload = {
      user: user?.id,
      page: 1,
      count: 10,
      search: search,
      type: type,
      category: module,
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.archivedList}`, payload).then((res) => {
      if (res?.success == true) {
        loader(false);
        setArchivedList(res?.data);
      }
    });
  };
  // useEffect(() => {
  //   if (selectResourse == 2) {
  //     DiscoverResourseList();
  //     priorityResourseList();
  //   } else {
  //     recommendResourseList();
  //     DiscoverResourseList();
  //   }
  // }, [type, module, selectResourse, search]);
  function openViewModal(ele, resourcename) {
    setShowViewModal(true);
    setHideAddButton(resourcename);
    setViewData(ele);
  }
  function CloseViewModal() {
    setShowViewModal(false);
  }
  const recommendResourseList = () => {
    let payload = {
      user: user?.id,
      type: type,
      category: module,
      search: search,
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.recommandedResorceslistApi}`, payload).then(
      (res) => {
        loader(false);
        setRecomdedResources(res?.data);
      }
    );
  };
  const DiscoverResourseList = (resourcesList) => {
    let payload;
    if (resourcesList == 2) {
      payload = {
        addedBy: user?.id,
        isResource: true,
        priortyTask: false,
        page: 1,
        count: 50,
        search: search,
        type: type,
        category: module,
        status: "active",
      };
    } else {
      payload = {
        page: 1,
        count: 50,
        search: search,
        type: type,
        category: module,
        isRecommanded: false,
        // addedBy: user?.id,
        isAdmin: true,
        status: "active",
      };
    }
    loader(true);
    ApiClient.get(`${shared?.resourcesApi}`, payload).then((res) => {
      if (res?.success == true) {
        loader(false);
        setDiscoverResourse(res?.data);
      }
    });
  };

  const priorityResourseList = () => {
    let payload = {
      addedBy: user?.id,
      priortyTask: true,
      isResource: true,
      page: 1,
      count: 10,
      search: search,
      type: type,
      category: module,
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.resourcesApi}`, payload).then((res) => {
      if (res?.success == true) {
        loader(false);
        setRecomdedResources(res?.data);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      pros: "",
      cons: "",
      name: "",
    },
    validationSchema: Yup.object({
      pros: Yup.string().required("Pros is Required"),
      cons: Yup.string().required("Cons is Required"),
      name: Yup.string().required("Name is Required"),
    }),
    onSubmit: async () => {
      setSubmitted(true);
      // Determine the method (post or put) and URL based on ModalId
      const method = ModalId ? "put" : "post";
      const url = ModalId ? `${shared.editApi}/${ModalId}` : shared.addApi;

      if (form?.discription == "") {
        // setSubmitted(true);
        toast?.error("Please fill all fields");
        return;
      }
      // // if (
      // //   form?.insertLink?.length === 0 &&
      // //   form?.uploadDocument?.length === 0
      // // )
      // {
      //   toast?.error(
      //     "Please fill all fields"
      //   );
      //   return;
      // }
      // if (showError) return;
      let value = {
        ...form,
        category: category.toString(),
        props: formik?.values?.pros,
        cons: formik?.values?.cons,
        name: formik?.values?.name,
      };

      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          setShowError(false);
          toast.success(res?.message);
          loader(false);
          setCategory("");
          setForm({
            type: "",
            priortyResources: false,
            uploadDocument: [],
            insertLink: [],
            discription: "",
          });
          formik.setValues({ pros: "", cons: "", name: "" });
          setImages({ image: "" });
          setIsOpen(false);
          setresourcesList(2);
          DiscoverResourseList(2);
          priorityResourseList();
        } else {
          setTimeout(() => {
            toast?.error(res?.message);
          }, 1000);
        }
      });
    },
  });

  const handleDomain = (e) => {
    setForm({ ...form, type: e.value });
    if (e) {
      let category_type = "master";
      let url = `categories/list?category_type=${category_type}&type=${e.value}`;
      getCategory(url);
    }
  };
  const getCategory = (url) => {
    loader(true);
    ApiClient.get(url).then((res) => {
      if (res.success) {
        loader(false);
        setCategories(res?.data);
      }
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setForm({ ...form, uploadDocument: [e?.value] });
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot", // Optional if using custom formats
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };
  function closeModal() {
    setIsOpen(false);
    setIsOpen(false);
    setForm({
      type: "",
      priortyResources: false,
      uploadDocument: [],
      insertLink: [],
      discription: "",
    });
    setType("");
    setModule("");
    setImages({ image: "" });
    setCategory("");
    formik.setValues({ pros: "", cons: "", name: "" });
  }
  function openModal(ele) {
    if (ele) {
      setModalId(ele);
      GetDetails(ele);
    } else {
      setModalId(null);
      setForm({
        discription: "",
        type: "",
        priortyResources: false,
        uploadDocument: [],
        insertLink: [],
      });
    }
    setIsOpen(true);
  }

  const options = categories?.map(({ id, name }) => {
    return { id: id, name: name };
  });
  const handleModule = (e) => {
    setType(e?.value);
    if (e) {
      let category_type = "master";
      let url = `categories/list?category_type=${category_type}&type=${e.value}`;
      getCategory(url);
    }
  };

  // Move MY TASK AND Priority TASK TO Archived Task

  const handleMoveResources = (ele) => {
    loader(true);
    // Toggle task selection
    setSelectResourse((prevSelectedTasks) => {
      if (prevSelectedTasks.includes(ele)) {
        // Remove task if already selected
        return prevSelectedTasks.filter((id) => id !== ele);
      } else {
        // Add task if not selected
        return [...prevSelectedTasks, ele];
      }
    });

    let payload = {
      user: user?.id?.toString(),
      resources: ele?.toString(),
    };
    ApiClient.post(`${shared?.moveResources}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        resourcesfilter(2);
        CloseViewModal();
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };
  const GetDetails = (id) => {
    loader(true);
    ApiClient.get(`${shared.detailApi}/${id}`).then((res) => {
      if (res.success) {
        const value = res.data;
        setForm({
          ...value,
        });
        setCategory(value?.category?.id?.toString());
        formik.setValues({
          pros: value?.props,
          cons: value?.cons,
          name: value?.name,
        });
        if (value.type) {
          let url = `categories/list?category_type=${"master"}&type=${
            value.type
          }`;
          getCategory(url);
        }
        setImages({ image: value.uploadDocument || "" });
      }
      loader(false);
    });
  };

  useEffect(() => {
    let element = document.getElementById("myElement");
    element.classList.remove("mainarea");
    element.classList.add("mainarea_scrool");
  });
  // Move recommmend to discover resources
  const handleRemoveRecommendedToDiscover = (ele) => {
    loader(true);
    let payload = {
      user: user?.id?.toString(),
      resources: ele?.id?.toString(),
    };
    ApiClient.post(`${shared?.removedResources}`, payload).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        resourcesfilter(1);
      } else {
        toast.error(res?.message);
      }
      loader(false);
    });
  };

  return (
    <Layout>
      <div className="flex flex-col lg:flex-row lg:items-end 2xl:items-center gap-4 justify-between mb-10">
        <div className="flex flex-wrap gap-2 items-center">
          <p className="font-semibold text-[18px]">Filter:</p>
          <div className=" flex flex-wrap items-center gap-2 ">
            <div className="menus_items">
              <div className="">
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="Select Domain"
                  intialValue={type}
                  result={handleModule}
                  options={shared.types}
                  className="w-full"
                />
              </div>
            </div>

            <div className="">
              <SelectDropdown
                isSingle={true}
                id="statusDropdown"
                displayValue="name"
                placeholder="Select Module"
                intialValue={module}
                result={(e) => {
                  setModule(e.value);
                }}
                options={options}
                className="w-full"
              />
            </div>

            <div className="">
              <SelectDropdown
                isSingle={true}
                id="statusDropdown"
                displayValue="name"
                placeholder="Select Resource"
                intialValue={resourcesList}
                result={(e) => {
                  setresourcesList(e.value);
                  if (e.value == 1) {
                    history("/resources/discover");
                  } else if (e.value == 2) {
                    history("/resources/myresources");
                  } else if (e.value == 3) {
                    history("/resources/archivedresources");
                  }
                  // resourcesfilter(e.value)
                }}
                options={tasks}
                className="w-full"
              />
            </div>
          </div>
        </div>

        <div className="menus_items flex items-center gap-2 shrink-0">
          <div
            className=""
            onClick={() => {
              openModal();
              setForm({
                discription: "",
                type: "",
                priortyResources: false,
                uploadDocument: [],
                insertLink: [],
              });
            }}
          >
            <Menu as="div" className="relative block">
              <div>
                <Tooltip placement="top" title="Add New Resources">
                  <Menu.Button className="flex  justify-between items-center gap-2 border border-neutral-200 rounded-full bg-primary h-12  p-3 text-[14px] xl:text-[17px] font-normal text-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                    <LuPlus />
                    Add{" "}
                    <span className="hidden 2xl:inline-flex">
                      New Resources
                    </span>
                  </Menu.Button>
                </Tooltip>
              </div>
            </Menu>
          </div>

          <div className="searhings">
            <div className="border flex items-center gap-2 border-neutral-300 p-3 h-12 rounded-full">
              <img src="../assets/img/lyfnew/search.svg" />
              <input
                type="search"
                className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-28 xl:w-32"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {resourcesList == 3 ? (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="col-span-12 lg:col-span-6">
            <div className="mt-8">
              <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular mb-6">
                Archived resources
              </h2>
              <div className="mt-4 mb-10 newscroll">
                <div className="grid grid-cols-1  gap-6   pr-5">
                  {archivedList?.length > 0 ? (
                    archivedList?.map((ele, ind) => {
                      
                      return (
                        <div className="border-b border-neutral-400 pb-4 ">
                          <p
                            className={` px-4 py-1 rounded-full text-[12px] text-neutral-100 inline-flex  ${
                              ele?.resources?.type === "wealth"
                                ? " bg-wealth"
                                : ""
                            }${
                              ele?.resources?.type === "self" ? " bg-self " : ""
                            }${
                              ele?.resources?.type === "social"
                                ? " bg-social "
                                : ""
                            }`}
                          >
                            {ele?.resources?.type.charAt(0).toUpperCase() +
                              ele?.resources?.type.slice(1)}
                          </p>
                          <h2>{ele?.name}</h2>
                          <div className="flex items-center gap-4 justify-between">
                            <p
                              className="font-regular mt-2 text-[18px] cursor-pointer"
                              onClick={() =>
                                openViewModal(ele, "ArchivedResources")
                              }
                              dangerouslySetInnerHTML={{
                                __html: ele?.resources?.discription,
                              }}
                            ></p>
                            <Tooltip placement="top" title="Restore">
                              <MdRefresh
                                className="cursor-pointer"
                                onClick={() => handleRestoreResource(ele)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center"> No Archived Yet</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-6 lg:gap-8 xl:gap-12 xl:h-full">
          <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 ">
            <div className="tiles_names">
              <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular">
                {resourcesList == 1
                  ? RecomdedResources?.length > 0
                    ? "Recommended resources"
                    : ""
                  : "Priority resources"}
              </h2>
            </div>

            <div className="mt-4 mb-10 newscroll">
              <div className=" flex flex-col gap-6 ">
                {RecomdedResources?.map((ele, ind) => {
                 
                  return (
                    <div className="border border-neutral-500 bg-neutral-100 rounded-[24px] p-4">
                      <div className="flex justify-between items-center mb-4">
                        <p
                          className={` px-4 py-1 rounded-full text-[12px] text-neutral-100  ${
                            ele?.type === "wealth" ? " bg-wealth" : ""
                          }${ele?.type === "self" ? " bg-self " : ""}${
                            ele?.type === "social" ? " bg-social " : ""
                          }`}
                        >
                          {ele?.type.charAt(0).toUpperCase() +
                            ele?.type.slice(1)}
                        </p>
                        <div className="flex items-center gap-4">
                          {resourcesList == 2 ? (
                            <>
                              <Tooltip
                                placement="top"
                                title="Move to archive resources "
                              >
                                <label className="inline-flex items-center">
                                  {/* <input
                                  type="checkbox"
                                  className="hidden peer"
                                  onClick={() => handleMoveResources(ele?.id)}
                                /> */}
                                  <input
                                    type="checkbox"
                                    className="hidden peer"
                                    checked={selectResourse.includes(ele?.id)}
                                    onClick={() => handleMoveResources(ele?.id)}
                                  />
                                  <div
                                    className={`w-6 h-6 border-2 cursor-pointer rounded-full flex items-center justify-center ${
                                      selectResourse.includes(ele?.id)
                                        ? "border-blue-900 bg-blue-900"
                                        : "border-gray-400"
                                    }`}
                                  ></div>
                                  {/* <div className="w-6 h-6 border-2 cursor-pointer border-gray-400  rounded-full peer-checked:border-[#58A541] peer-checked:bg-[#0079B3] flex items-center justify-center"></div> */}
                                </label>
                              </Tooltip>
                            </>
                          ) : (
                            ""
                          )}
                          {resourcesList == 1 ? (
                            <button
                              className="text-xs px-2 py-1 text-white bg-red-500 rounded-full"
                              onClick={() =>
                                handleRemoveRecommendedToDiscover(ele)
                              }
                            >
                              <Tooltip
                                placement="top"
                                title="Move the resources from Recommended to Discover"
                              >
                                {" "}
                                <IoMoveSharp />
                              </Tooltip>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="testings_datas">
                        <h2>{ele?.name}</h2>
                        <p
                          onClick={() =>
                            openViewModal(
                              ele,
                              resourcesList == 1
                                ? "Recommendedresources"
                                : "Priorityresources"
                            )
                          }
                          className="line-clamp-2 font-regular text-[14px] cursor-pointer"
                          dangerouslySetInnerHTML={{ __html: ele?.discription }}
                        ></p>
                      </div>

                      <div className="flex justify-end items-center mt-6">
                        {resourcesList == 1 ? (
                          RecomdedResources?.length > 0 ? (
                            <div className="flex items-center gap-4">
                              <Tooltip
                                placement="top"
                                title="Add To My Resources"
                              >
                                {" "}
                                <HiMiniPlusCircle
                                  onClick={() =>
                                    handleaddToMyResources(ele?.id)
                                  }
                                  className={` text-3xl cursor-pointer${
                                    ele?.type === "wealth" ? " text-wealth" : ""
                                  }${
                                    ele?.type === "self" ? " text-self " : ""
                                  }${
                                    ele?.type === "social"
                                      ? " text-social "
                                      : ""
                                  }`}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          <h4
                            className="cursor-pointer"
                            onClick={() => {
                              openModal(ele?.id);
                            }}
                          >
                            <MdEdit />
                          </h4>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className={`col-span-12   ${
              RecomdedResources?.length > 0
                ? "lg:col-span-6 xl:col-span-6 "
                : "lg:col-span-6 xl:col-span-6"
            }`}
          >
            <div className="tiles_names">
              <h2 className="flex items-center gap-2 text-[20px] xl:text-[26px] 2xl:text-[32px] text-neutral-800 font-regular">
                {resourcesList == 1 ? "Discover resources" : "My resources"}
              </h2>
            </div>

            <div className="mt-4 mb-10 newscroll">
              <div className="flex flex-col gap-6 ">
                {discoverResourse?.length > 0 ? (
                  discoverResourse?.map((item) => {
               
                    return (
                      <div className="border cursor-pointer border-neutral-300 bg-neutral-100 rounded-[24px] p-4">
                        <div className="flex justify-between items-center mb-4">
                          <p
                            className={` px-4 py-1 rounded-full text-[12px] text-neutral-100  ${
                              item?.type === "wealth" ? " bg-wealth" : ""
                            }${item?.type === "self" ? " bg-self " : ""}${
                              item?.type === "social" ? " bg-social " : ""
                            }`}
                          >
                            {item?.type.charAt(0).toUpperCase() +
                              item?.type.slice(1)}
                          </p>
                          <div className="flex items-center gap-4">
                            {resourcesList == 2 ? (
                              // <input
                              //   type="checkbox"
                              //   onClick={() => handleMoveResources(item?.id)}
                              // ></input>
                              <>
                                <Tooltip
                                  placement="top"
                                  title="Move to archive Resources "
                                >
                                  <label className="inline-flex items-center">
                                    <input
                                      type="checkbox"
                                      className="hidden peer"
                                      checked={selectResourse.includes(
                                        item?.id
                                      )}
                                      onClick={() =>
                                        handleMoveResources(item?.id)
                                      }
                                    />
                                    {/* <div className="w-6 h-6 border-2 cursor-pointer border-gray-400  rounded-full peer-checked:border-[#58A541] peer-checked:bg-[#0079B3] flex items-center justify-center">
                          
                            </div> */}
                                    <div
                                      className={`w-6 h-6 border-2 cursor-pointer rounded-full flex items-center justify-center ${
                                        selectResourse.includes(item?.id)
                                          ? "border-blue-900 bg-blue-900"
                                          : "border-gray-400"
                                      }`}
                                    ></div>
                                  </label>
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="testings_datas">
                          <h2>{item?.name}</h2>
                          <p
                            onClick={() =>
                              openViewModal(
                                item,
                                resourcesList == 1
                                  ? "Discoverresources"
                                  : "Myresources"
                              )
                            }
                            className="line-clamp-2 font-regular text-[14px] cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: item?.discription,
                            }}
                          ></p>
                        </div>

                        <div className="flex justify-end items-center mt-2">
                          {resourcesList == 1 ? (
                            <div className="flex items-center gap-4">
                              <Tooltip
                                placement="top"
                                title="Add To My Resources"
                                onClick={() => handleaddToMyResources(item?.id)}
                              >
                                {" "}
                                <HiMiniPlusCircle
                                  className={`text-3xl cursor-pointer ${
                                    item?.type === "wealth"
                                      ? " text-wealth"
                                      : ""
                                  }${
                                    item?.type === "self" ? " text-self " : ""
                                  }${
                                    item?.type === "social"
                                      ? " text-social "
                                      : ""
                                  }`}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <h4
                              onClick={() => {
                                openModal(item?.id);
                              }}
                            >
                              <MdEdit />
                            </h4>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center"> No Resources Yet</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform  rounded-2xl bg-[#F4EAFE] px-6 py-2 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end items-end">
                    <IoCloseCircleOutline
                      onClick={closeModal}
                      className="text-2xl cursor-pointer"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="modal_inners">
                      <form onSubmit={formik?.handleSubmit}>
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12 lg:col-span-12">
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                placeholder=" Add resource Name"
                                className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                                onChange={formik?.handleChange}
                                onBlur={formik?.onBlur}
                                value={formik?.values?.name}
                              ></input>
                              {showError &&
                              formik.touched.name &&
                              formik.errors.name ? (
                                <span className="text-danger text-xs fs-6">
                                  {formik.errors.name}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-span-12">
                            <div className="quills">
                              <ReactQuill
                                ref={reactQuillRef}
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={form.discription}
                                onChange={(e) =>
                                  setForm({ ...form, discription: e })
                                }
                                className=""
                                required
                              />
                              {submitted && !form.discription && (
                                <div className="invalid-feedback d-block">
                                  Discription is required.
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-span-12 lg:col-span-6">
                            <div className="">
                              <textarea
                                name="pros"
                                placeholder=" Add resource pros"
                                className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                                onChange={formik?.handleChange}
                                onBlur={formik?.onBlur}
                                value={formik?.values?.pros}
                              ></textarea>
                              {showError &&
                              formik.touched.pros &&
                              formik.errors.pros ? (
                                <span className="text-danger text-xs fs-6">
                                  {formik.errors.pros}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <div className="">
                              <textarea
                                name="cons"
                                placeholder=" Add resource cons"
                                className="w-full p-4 focus:outline-none rounded-3xl h-16 bg-white  "
                                onChange={formik?.handleChange}
                                onBlur={formik?.onBlur}
                                value={formik?.values?.cons}
                              ></textarea>
                              {showError &&
                              formik.touched.cons &&
                              formik.errors.cons ? (
                                <span className="text-danger text-xs fs-6">
                                  {formik.errors.cons}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="filters_all flex items-start  gap-2 mt-6">
                          <div className="flex items-start gap-2">
                            <div className="menus_items">
                              <div className="">
                                <SelectDropdown
                                  isSingle={true}
                                  id="statusDropdown"
                                  displayValue="name"
                                  placeholder="Select Domain"
                                  intialValue={form.type}
                                  result={handleDomain}
                                  options={shared.types}
                                  className="w-full"
                                />
                                {form?.type == "" && showError ? (
                                  <span className="text-danger text-xs fs-6">
                                    Domain Name is required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="menus_items">
                              <div className="">
                                <SelectDropdown
                                  isSingle={true}
                                  id="statusDropdown"
                                  displayValue="name"
                                  placeholder="Select Module"
                                  intialValue={category}
                                  result={(e) => {
                                    setCategory(e.value);
                                  }}
                                  options={options}
                                  className="w-full"
                                />
                                {category == "" && showError ? (
                                  <span className="text-danger text-xs fs-6">
                                    Module Name is required
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 mt-3">
                            <input
                              type="checkbox"
                              className="h-5 w-5 cursor-pointer "
                              style={{ accentColor: "#5C0BAD" }}
                              checked={form.priortyResources}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  priortyResources: e.target.checked,
                                })
                              }
                            />
                            <span>Priority Resources</span>
                          </div>
                        </div>

                        <div className="flex items-center  mt-10 mb-8 justify-between">
                          <div className="flex gap-4">
                            <div className="">
                              <label className="underline cursor-pointer text-sm underline-offset-4 relative flex flex-col items-center gap-2">
                                <ImageUpload
                                  model="users"
                                  name="upload"
                                  result={(e) => imageResult(e, "image")}
                                  value={images.image}
                                  multiple={false}
                                />
                                Upload File
                              </label>
                            </div>

                            <div className="max-w-96">
                              <div className="flex flex-col items-center gap-2">
                                <img
                                  src="../assets/img/lyfnew/a.svg"
                                  className="h-6"
                                />
                                <input
                                  type="text"
                                  placeholder="Insert Link"
                                  value={form.insertLink}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      insertLink: [e?.target?.value],
                                    })
                                  }
                                  className="border-none text-sm text-center placholder_color bg-transparent p-0 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-4">
                            <button
                              onClick={() => {
                                setShowError(true);
                              }}
                              type="submit"
                              className="bg-primary  px-6 py-2 text-[#fff] text-[16px] font-semibold rounded-full"
                            >
                              {ModalId ? "Update Resources" : "Add Resources"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <View
        openModal={showViewModal}
        closeModal={CloseViewModal}
        data={viewData}
        hideAddButton={hideAddButton}
        handleMoveResources={handleMoveResources}
        handleaddToMyResources={handleaddToMyResources}
        isLayout={false}
        DiscoverResourseList={DiscoverResourseList}
        priorityResourseList={priorityResourseList}
      />
    </Layout>
  );
}

export default Resources;
