import React, { useState, useEffect } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import Html from './Html';
import { useNavigate } from 'react-router-dom';
import formModel from '../../../models/form.model';
import { useDispatch, useSelector } from 'react-redux';
import { login_success } from '../../../actions/user';

const EditProfile = () => {
  const user: any = useSelector((state:any)=>state.user)
  const dispatch=useDispatch()
  const [data, setData] = useState('');
  const [form, setForm]: any = useState({
    id:'',
    email: '',
    mobileNo: '',
    fullName: '',
  });
  const history = useNavigate()
  const [submitted, setSubmitted] = useState(false)

  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/${user.id}`).then(res => {
      if (res.data.id) {
        let payload = form
        let value = res.data
        let oarr = Object.keys(form)
        oarr.map(itm => {
          payload[itm] = value[itm] || null
        })
        payload.id = user.id
    
        let img=images
        Object.keys(images).map(itm=>{
          img[itm]=value[itm]
        })
        setImages({...img})
        // if (payload.category?._id) payload.category = payload.category._id
        setForm({ ...payload })
        setData(value)
      }
      loader(false)
    })
  };

  const getError = (key: any) => {
    return formModel.getError('profileForm', key)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true)
    let invalid = formModel.getFormError('profileForm')
    if (invalid) return

    let value = {
      ...form,
      ...images
      // verifiedGroupLeader:'approved'
    }
    Object.keys(value).map(itm => {
      if (!value[itm]) value[itm] = null
    })

    loader(true)
    ApiClient.put(`users/${value.id}`,value).then(res => {
      if (res.success) {
        let uUser = { ...user, ...value }
        dispatch(login_success(uUser))
        history("/profile")
        // ToastsStore.success(res.message)
      }
      loader(false)
    })
  };

  const [images, setImages]:any = useState({ image: '' });
  const imageResult = (e:any, key:any=''):any => {
    images[key] = e.value
    setImages(images)
  
}

  const uploadImage = (e: any) => {
    setForm({ ...form, baseImg: e.target.value })
    let files = e.target.files[0]
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('upload/image', { file: file, modelName: 'users' }).then(res => {
      if (res.fileName) {
        let image = res.fileName
        setForm({ ...form, image: image, baseImg: '' })
      } else {
        setForm({ ...form, baseImg: '' })
      }
      loader(false)
    })
  }

  useEffect(
    () => {
      if (user) {
        gallaryData();
      }
    }, []);

  return (
    <>
      <Html
        handleSubmit={handleSubmit}
        setForm={setForm}
        imageResult={imageResult}
        images={images}
        form={form}
        uploadImage={uploadImage}
        getError={getError}
        submitted={submitted}
      />
    </>
  );
};

export default EditProfile;
