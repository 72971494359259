const types=[
  {id:'self',name:'Self'},
  {id:'social',name:'Social'},
  {id:'wealth',name:'Wealth'},
]
const shared = {
    
    taskList:"task/list",
    resourcesList:"resource/list",
    videoList :"lastviewed/categories/list",
  types:[...types],
    
  };
  
  export default shared;