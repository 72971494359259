import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import Layout from "../../components/global/layout";
// import { Calendar, Badge } from 'antd'; // Import Calendar and Badge from Ant Design
import { Tooltip, Badge, Calendar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./calender.scss";
import shared from "./shared";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import { LuUsers } from "react-icons/lu";
import { IoCloseOutline, IoFlashOutline } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import axios from "axios";
function Events() {
  const user = useSelector((state) => state?.user);
  const [ShowFullScreen, setShowFullScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [upcommingEvents, setUpcommingEvents] = useState([]);
  const [recommandedList, setRecommandedList] = useState([]);
  const [calenderList, setCalenderList] = useState([]);
  const [nextEventList, setNextEventList] = useState([]);
  const [singleList, setSingleList] = useState([]);
  const history = useNavigate();

  // const initialRender = useRef(true);
  const recommendListCancelTokenSource = useRef(null);
  const upcomingEventsListCancelTokenSource = useRef(null);
  const getEventCalendarListCancelTokenSource = useRef(null);
  const handleNextEventListCancelTokenSource = useRef(null);
  // Get the current date and time
  let currentDate = new Date();

  // Extract components
  let year = currentDate.getUTCFullYear();
  let month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  let day = String(currentDate.getUTCDate()).padStart(2, "0");
  let hours = String(currentDate.getUTCHours()).padStart(2, "0");
  let minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  let seconds = "00"; // Fixed to '00' for this format
  let timezoneOffset = -currentDate.getTimezoneOffset(); // Get offset in minutes and convert to positive

  // Create the formatted date string
  let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${formatTimezoneOffset(
    timezoneOffset
  )}`;

  // Function to format timezone offset to 'HH:MM'
  function formatTimezoneOffset(offset) {
    let hours = Math.floor(offset / 60);
    let minutes = offset % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  }



  function closeModal() {
    setIsOpen(false);
  }
  function openModal(ele) {
    setSingleList(ele);
    setIsOpen(true);
  }
  const handleSeeAll = () => {
    history("/allevents");
  };
  const RecommendList = () => {
    let today = new Date();
    loader(true);
    let payload = {
      user: user?.id,
      status:"active",
      startDate: moment(today).format("YYYY-MM-DD"),
    };
    ApiClient.get(`${shared?.listApi}`, payload ,"","",recommendListCancelTokenSource.current.token)
      .then((res) => {
        setRecommandedList(res?.data);
        loader(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });
  };

  const UpcomingEventsList = () => {
    let payload = {
      upcoming: true,
      isRecommanded :false,
      status:"active"
    };
    ApiClient.get(`${shared?.upcomingListApi}`, payload , "","", upcomingEventsListCancelTokenSource.current.token,)
      .then((res) => {
        setUpcommingEvents(res?.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });
  };

  const handleAgenda = (ele) => {
    console.log(ele, "ergtgtgtrgt");
    let payload = {
      user: user?.id.toString(),
      event: ele?.id.toString(),
      eventDate: ele?.start_date,
      category: ele?.category?.id.toString(),
    };
    loader(true);
    ApiClient.post(`${shared?.addCalendarEvent}`, payload).then((res) => {
      toast(res?.message);
      getEventCalendarList();
      handleNextEventList();
      loader(false);
    });
  };

  const getEventCalendarList = () => {
    let payload = {
      user: user?.id,
      status:"active"
    };
    loader(true);
    ApiClient.get(`${shared?.getCalendarList}`, payload , "","", getEventCalendarListCancelTokenSource.current.token,)
      .then((res) => {
    
        setCalenderList(res?.data);
        loader(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });
  };
  const handleNextEventList = () => {
    let today = new Date();
    let payload = {
      user: user?.id,
      startDate: moment(today).format("YYYY-MM-DD"),
      isUpcoming: true,
      status:"active"
    };

    ApiClient.get(`${shared?.getCalendarList}`, payload ,"","",handleNextEventListCancelTokenSource.current.token)
      .then((res) => {
        setNextEventList(res?.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });
  };
 
  useEffect(() => {
    // if (initialRender.current) {
    //   initialRender.current = false;
    //   return;
    // }
    recommendListCancelTokenSource.current = axios.CancelToken.source();
    upcomingEventsListCancelTokenSource.current = axios.CancelToken.source();
    getEventCalendarListCancelTokenSource.current = axios.CancelToken.source();
    handleNextEventListCancelTokenSource.current = axios.CancelToken.source();
    RecommendList();
    UpcomingEventsList();
    getEventCalendarList();
    handleNextEventList();
    return () => {
      if (recommendListCancelTokenSource.current) {
        recommendListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (upcomingEventsListCancelTokenSource.current) {
        upcomingEventsListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (getEventCalendarListCancelTokenSource.current) {
        getEventCalendarListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (handleNextEventListCancelTokenSource.current) {
        handleNextEventListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, []);

  // Function to render event badges on calendar dates
  const dateCellRenderr = (value) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const eventsOnThisDate = calenderList.filter(
      (ele) =>
        moment(ele?.event.start_date).format("YYYY-MM-DD") === formattedDate
    );

    return ShowFullScreen ? (
      <>
        <ul className="events">
          {eventsOnThisDate.map((ele) => (
            <li
              className={`text-[15px] font-medium
            ${ele?.category?.type === "wealth" ? "bg-[#F5FFE8]" : ""}
            ${ele?.category?.type === "self" ? "bg-[#CAF1F7]" : ""}
            ${ele?.category?.type === "social" ? "bg-[#FFE6CF]" : ""}`}
              key={ele.id}
            >
              <Badge
                status="success"
                text={
                  <span
                    className={`text-[15px] font-medium
      ${ele?.category?.type === "wealth" ? "text-[#58A541]" : ""}
      ${ele?.category?.type === "self" ? "text-[#0079B3]" : ""}
      ${ele?.category?.type === "social" ? "text-[#FFA03B]" : ""}`}
                  >
                    {ele?.event?.name}
                  </span>
                }
              />
            </li>
          ))}
        </ul>{" "}
      </>
    ) : (
      <div className="relative">
        {eventsOnThisDate.map((ele) => (
          <Tooltip key={ele.id} title={ele.event.name}>
            <div
              className={`inline-block rounded-full w-2 h-2 mr-1`}
              style={{
                backgroundColor:
                  ele?.category?.type === "wealth"
                    ? "#58A541"
                    : ele?.category?.type === "self"
                    ? "#0079B3"
                    : ele?.category?.type === "social"
                    ? "#FFA03B"
                    : "",
              }}
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      {/* <div className="mb-8">
        <h2 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular">
        </h2>
      </div> */}

      <div className="grid grid-cols-12 gap-6 2xl:gap-12">
        {!ShowFullScreen ? (
          <div
            className={`col-span-12  transition-all  lg:col-span-7  2xl:col-span-8 `}
          >
            {recommandedList?.length > 0 ? (
              <>
                <h4 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular mb-4">
                  Recommend Events
                </h4>

                <div
                  className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 transition-all  ${
                    ShowFullScreen ? null : "xl:grid-cols-2 2xl:grid-cols-3"
                  } gap-6`}
                >
                  {recommandedList?.slice(0, 3).map((ele, ind) => (
                    <div
                      key={ind}
                      className="border border-neutral-300 g-white rounded-[24px] p-3"
                    >
                      <div className="images_tage rounded-[24px] relative">
                        <img
                          className="w-full h-44 object-cover rounded-[24px]"
                          src="../assets/img/lyfnew/oness.png"
                          alt="Event Image"
                        />
                        <p
                          className={`px-4 py-2 rounded-full text-[12px] text-neutral-100 absolute left-2 top-4 capitalize
                            ${
                              ele?.category?.type === "wealth"
                                ? "border-[#58A541] bg-[#58A541]"
                                : ""
                            }
                            ${
                              ele?.category?.type === "self"
                                ? "border-[#0079B3] bg-[#0079B3]"
                                : ""
                            }
                            ${
                              ele?.category?.type === "social"
                                ? "border-[#FFA03B] bg-[#FFA03B]"
                                : ""
                            }`}
                        >
                          {ele?.category?.type}
                        </p>
                      </div>
                      <div className="mt-4">
                        <div className="flex items-center flex-wrap justify-between mt-2 mb-4 gap-2">
                          <p className="text-[12px] text-neutral-600 font-regular">
                            {moment(
                              ele?.start_date ? ele?.start_date : ""
                            ).format("DD/MM/YY")}
                          </p>
                          <p className="text-neutral-800 text-[14px] font-regular">
                            {ele?.name}
                          </p>
                        </div>
                        <p
                          className="text-[14px] font-regular line-clamp-2"
                          dangerouslySetInnerHTML={{ __html: ele?.description }}
                        ></p>
                        <div className="flex items-center justify-between mt-4">
                          <button
                            onClick={() => openModal(ele)}
                            className="border border-neutral-200 rounded-full bg-primary px-6 py-3 text-[14px] font-normal text-neutral-100 focus:outline-none font-semibold font-three"
                          >
                            About
                          </button>
                          {ele?.start_date > formattedDate ? (
                            <p
                              className="text-primary font-three font-semibold"
                              onClick={() => handleAgenda(ele)}
                            >
                              Add to agenda
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {recommandedList?.length >= 3 ? (
                  <div className="flex items-center justify-center mt-4">
                    <button
                      className=" px-4 py-2 flex gap-2 items-center hover:underline hover:text-primary rounded-lg text-neutral-800 text-sm border-y border-gray-200"
                      onClick={handleSeeAll}
                    >
                      See all Events <FaChevronDown />{" "}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <div className="mt-6 xl:mt-10 ">
              <div className="mb-8">
                <h2 className="flex items-center gap-2 text-[22px] xl:text-[32px]  text-neutral-800 font-regular">
                  Upcoming events
                </h2>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 transition-all  ${
                  ShowFullScreen ? null : "xl:grid-cols-2 2xl:grid-cols-3"
                } gap-6`}
              >
                {upcommingEvents.length >= 0 ? (
                  <>
                    {" "}
                    {upcommingEvents?.map((ele, index) => {
                      return (
                        <div className="border border-neutral-300 rounded-[8px] p-3">
                          <p
                            className={`text-[10px] font-medium mb-1 capitalize
                            ${
                              ele?.category?.type === "wealth"
                                ? "text-[#58A541] "
                                : ""
                            }
                            ${
                              ele?.category?.type === "self"
                                ? "text-[#0079B3] "
                                : ""
                            }
                            ${
                              ele?.category?.type === "social"
                                ? "text-[#FFA03B] "
                                : ""
                            }`}
                          >
                            {ele?.category?.type}
                          </p>
                          <div className="images_rows flex items-start gap-4">
                            <img src="../assets/img/lyfnew/user.png" />
                            <div className="w-full">
                              <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {" "}
                                  {moment(ele?.start_date).format("DD/MM/YY")}
                                </p>
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {ele?.address}
                                </p>
                              </div>
                              <p className="text-[12px] text-neutral-800">
                                {ele?.name}
                              </p>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center justify-end">
                            <button
                              onClick={() => openModal(ele)}
                              className="border border-neutral-200 rounded-full bg-primary px-4 py-1 text-[14px] font-normal text-neutral-100 focus:outline-none font-semibold font-three"
                            >
                              About
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-4 h-60">
                    <img src="./assets/img/no-events.png" className="h-20" />
                    <p className="text-neutral-800 font-medium text-sm">
                      There are no upcoming events.{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={`col-span-12   transition-all   ${
            !ShowFullScreen
              ? "lg:col-span-5 2xl:col-span-4"
              : "xl:col-span-12 lg:col-span-12"
          }  `}
        >
          <div className="2xl:ml-6 bg-[#F4EAFE] rounded-[32px] p-4 xl:p-8 h-full">
            <div className="flex flex-col  h-full">
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <p className="flex items-center gap-2 text-[20px] xl:text-[28px]  text-neutral-800 font-regular">
                    Calendar
                  </p>
                  <img
                    className="cursor-pointer"
                    src="../assets/img/lyfnew/arrow.svg"
                    onClick={(e) => setShowFullScreen(!ShowFullScreen)}
                  />
                </div>

                <div className="w-full rounded-full">
                  <Calendar
                    className="antcalender"
                    fullscreen={ShowFullScreen}
                    dateCellRender={dateCellRenderr}
                  />
                </div>
              </div>
              <div className="mt-2">
                <div className="flex items-center gap-2 mb-6">
                  <p className="flex items-center gap-2 text-[28px]  text-neutral-800 font-regular">
                    My next events
                  </p>
                  <img
                    src="../assets/img/lyfnew/arrow.svg"
                    onClick={(e) => setShowFullScreen(!ShowFullScreen)}
                  />
                </div>

                <div
                  className={`grid ${
                    ShowFullScreen
                      ? "md:grid-cols-2 lg:grid-cols-3"
                      : "grid-cols-12 md:grid-cols-1"
                  } gap-2`}
                >
                  {ShowFullScreen ? (
                    nextEventList.length >= 0 ? (
                      nextEventList?.map((ele, ind) => (
                        <div
                          key={ind}
                          className="border border-neutral-300 rounded-[8px] bg-white p-3"
                        >
                          <p
                            className={`text-[10px] font-medium mb-1
                            ${
                              ele?.category?.type === "wealth"
                                ? "text-[#58A541] "
                                : ""
                            }
                            ${
                              ele?.category?.type === "self"
                                ? "text-[#0079B3] "
                                : ""
                            }
                            ${
                              ele?.category?.type === "social"
                                ? "text-[#FFA03B] "
                                : ""
                            }`}
                          >
                            {ele?.category?.type}
                          </p>
                          <div className="images_rows flex items-start gap-4">
                            <img
                              src="../assets/img/lyfnew/user.png"
                              alt="User Avatar"
                            />
                            <div className="w-full">
                              <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {moment(ele?.event?.start_date).format(
                                    "DD/MM/YY"
                                  )}
                                </p>
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {ele?.event?.address}
                                </p>
                              </div>
                              <p className="text-[12px] text-neutral-800">
                                {ele?.event?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="flex flex-col items-center justify-center gap-4 h-40">
                          <img
                            src="./assets/img/no-events.png"
                            className="h-20"
                          />
                          <p className="text-neutral-800 font-medium text-sm">
                            There are no next events.{" "}
                          </p>
                        </div>
                      </>
                    )
                  ) : nextEventList.length >= 0 ? (
                    <>
                      {nextEventList?.slice(0, 2)?.map((ele, ind) => (
                        <div
                          key={ind}
                          className="border border-neutral-300 rounded-[8px] bg-white p-3"
                        >
                          <p
                            className={`text-[10px] font-medium mb-1
                          ${
                            ele?.category?.type === "wealth"
                              ? "text-[#58A541] "
                              : ""
                          }
                          ${
                            ele?.category?.type === "self"
                              ? "text-[#0079B3] "
                              : ""
                          }
                          ${
                            ele?.category?.type === "social"
                              ? "text-[#FFA03B] "
                              : ""
                          }`}
                          >
                            {ele?.category?.type}
                          </p>
                          <div className="images_rows flex items-start gap-4">
                            <img
                              src="../assets/img/lyfnew/user.png"
                              alt="User Avatar"
                            />
                            <div>
                              <div className="flex items-center justify-between flex-wrap w-full mb-2">
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {moment(ele?.event?.start_date).format(
                                    "DD/MM/YY"
                                  )}
                                </p>
                                <p className="text-[12px] text-neutral-600 font-regular">
                                  {ele?.event?.address}
                                </p>
                              </div>
                              <p className="text-[12px] text-neutral-800">
                                {ele?.event?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col items-center justify-center gap-4 h-40">
                        <img
                          src="./assets/img/no-events.png"
                          className="h-20"
                        />
                        <p className="text-neutral-800 font-medium text-sm">
                          There are no next events.{" "}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden  bg-white  text-left align-middle shadow-xl transition-all">
                  <div className="">
                    <div className="modal_inners">
                      <div className="imsg_data relative">
                        <IoCloseOutline
                          onClick={closeModal}
                          className="text-3xl text-neutral-100 cursor-pointer absolute right-5 top-2"
                        />
                        <img
                          className="h-44 w-full object-cover"
                          src="assets/img/lyf/eventsb.png"
                        />
                      </div>
                      <div className="px-10 py-4 flex justify-between gap-4 mb-8">
                        <div className="shrink-0">
                          <div className="text-[#004AAD]">
                            <p className="text-[20px] lg:text-[27px] font-bold flex items-start  leading-6">
                              {moment(singleList?.start_date).format(
                                "DD/MM/YY"
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col gap-y-4">
                          <div className="text-[#004AAD]">
                            <p className="text-[20px] lg:text-[26px]  font-semibold">
                              {singleList?.name}
                            </p>
                            <p className="text-[16px] flex items-center gap-4">
                              {singleList?.address}
                            </p>
                          </div>

                          <div className="decri">
                            <p className="text-[#404040] text-[20px] font-semibold">
                              Description
                            </p>
                            <p
                              className="text-[#404040] text-[15px] font-normal"
                              dangerouslySetInnerHTML={{
                                __html: singleList?.description,
                              }}
                            ></p>
                          </div>

                          {/* <div className="flex items-center gap-10">
                            <p className="text-[#004AAD] text-[15px] font-normal">
                              URL Link Space
                            </p>
                            <p className="text-[#404040] text-[20px] font-semibold">
                              Cost: {singleList?.price}
                            </p>
                          </div> */}

                          <div className="flex items-center gap-10 text-[#004AAD]">
                            {singleList?.start_date > formattedDate ? (
                              <p
                                className="text-primary font-three font-semibold"
                                onClick={() => handleAgenda(singleList)}
                              >
                                Add to agenda
                              </p>
                            ) : (
                              ""
                            )}
                            <button className="border border-[#004AAD] rounded-full px-6 py-2 mt-2 cursor-pointer">
                              Share with friends
                            </button>
                          </div>
                        </div>

                        <div className="shrink-0 text-[#004AAD]">
                          <div className="flex items-center gap-2 ">
                            <p className="text-2xl  flex items-center gap-2">
                              <LuUsers className="cursor-pointer" />
                              {/* <span className="text-[13px]">390</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Layout>
  );
}

export default Events;
