import React from "react";
import methodModel from "../../../methods/methods";

const Html = ({
  inputElement,
  name,
  editData,
  uploadImage,
  img,
  remove,
  loader,
  model,
  multiple,
  required,
  disabled,
  err,
  label = "",
}) => {
  // Function to get data after underscore
  const getDataAfterUnderscore = (path) => {
   
    if(path.includes("_") && path ){
        const parts = path?.split("_");
        // Return everything after the first underscore
        return parts.slice(1)?.join("_");
    }
 
  };

  return (
    <>
      {name === "edit" ? (
        <label className={`absolute bottom-2 right-0 ${img && !multiple ? 'd-none' : ''}`}>
          <input
            type="file"
            className="d-none"
            ref={inputElement}
            accept="image/*"
            multiple={multiple ? true : false}
            disabled={loader}
            onChange={(e) => { uploadImage(e); }}
          />
          <img
            className="cursor-pointer"
            src="../assets/img/lyfnew/edit.svg"
            alt="Click to upload"
          />
        </label>
      ) : name === "upload" ? (
        <label className={`${img && !multiple ? 'd-none' : ''}`}>
          <input
            type="file"
            className="d-none"
            ref={inputElement}
            accept="image/*"
            multiple={multiple ? true : false}
            disabled={loader}
            onChange={(e) => { uploadImage(e); }}
          />
          <img
            src="../assets/img/lyfnew/c.svg"
            className="h-6"
          />
        </label>
      ) : (
        <label className={`inline-block text-neutral-100 bg-[#283A97] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${img && !multiple ? 'd-none' : ''}`}>
          <input
            type="file"
            className="d-none"
            ref={inputElement}
            accept="image/*"
            multiple={multiple ? true : false}
            disabled={loader}
            onChange={(e) => { uploadImage(e); }}
          />
          {label || 'Upload Image'}
        </label>
      )}

      {loader ? (
        <div className="text-success">Uploading... <i className="fa fa-spinner fa-spin"></i></div>
      ) : (
        <></>
      )}

      {multiple ? (
        <div className="imagesRow">
          {img && img.map((itm, i) => (
            <div className="imagethumbWrapper" key={i}>
              <img src={methodModel.noImg(itm, model)} className="thumbnail" />
              <i className="fa fa-times" title="Remove" onClick={(e) => remove(i)}></i>
              {/* Display data after underscore */}
              <div>{getDataAfterUnderscore(itm)}</div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {img ? (
            <div className="relative">
              <img src={methodModel.noImg(img, model)} className="h-28 w-28 rounded-full " />
              {editData ? <i className="fa fa-times absolute top-2 right-0" title="Remove" onClick={(e) => remove()}></i> : null}
              {/* Display data after underscore */}
              <div>{getDataAfterUnderscore(img)}</div>
            </div>
          ) : (
            <>
            
              {/* {name !== "upload" ? <img src="../assets/img/person.jpg" className="h-28 w-28 rounded-full " /> : ""} */}
            </>
          )}
        </>
      )}

      {required && !img ? <div className="text-danger">{err ? err : 'Image is Required'}</div> : <></>}
    </>
  );
};

export default Html;
