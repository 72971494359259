import { Link } from "react-router-dom"

const AuthLayout=({children}:any)=>{
    return <>
    <div className="auth_main  p-2 lg:p-6 h-screen	w-full flex items-center justify-center ">
      <div className="rounded-[30px] scale_sizes items-center w-[100%] lg:w-[80%] mx-auto bg-white p-2 xl:p-6 ">
      <div className="">
     <div className='grid items-start grid-cols-12'>
        
          <div className="col-span-12 md:col-span-4 lg:col-span-4">
            <div className="relative ">
              <img src="/assets/img/logo.png" alt=""  className=" h-24 object-center object-contain" />

              <h2 className="font-2 capitalize -mt-2 font-regular text-[20px] lg:text-[64px]">
                  Dably
              </h2>

              <p className="font-regular mt-4 text-[18px] max-w-md">Knowing how to navigate our societal systems is the foundation upon which we build a better future.</p>
            
            </div>
          </div>
          <div className="col-span-12 md:col-span-8 lg:col-span-8">
            <div className="relative flex flex-col items-center justify-center">
              
              <div className="w-full rounded-lg">
                {children}
              </div>

            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </>
}

export default AuthLayout