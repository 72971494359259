const shared = {
  check: "Users",
  title: "Users",
  addTitle: "User",
  url: "user",
  addApi: "user",
  editApi: "users",
  detailApi: "user",
  listApi: "users/list",
  statusApi: "users",
  deleteApi: "delete/user",
};

export default shared;
