import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { Reducer } from "./Reducer";
import User from "./UserReducer";
import ChangeTab from "./ChangeTab";
import SearchState from "./SearcState";

const RootReducer = combineReducers({
  // Reducer: Reducer,
  changeTab: ChangeTab,
  SearchState: SearchState,
  user: User,
});

const PersistReducer = persistReducer({ key: "root", storage }, RootReducer);

export default PersistReducer;
