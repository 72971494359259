import React, { useEffect, useState } from 'react';
import './style.scss';
import PhoneInput from 'react-phone-input-2';
import ImageUpload from '../../common/ImageUpload';
import FormControl from '../../common/FormControl';


const Html = ({ handleSubmit, setForm, form, getError, uploadImage, submitted, imageResult, images }) => {





  return (
    <>

      <div className='wrapper_section'>
        <div className='flex items-center  justify-between'>
          <h3 className='text-2xl font-semibold text-[#111827]'>Edit Profile </h3>

        </div>


        <form name="profileForm" className="" onSubmit={handleSubmit} >


          <div className="grid grid-cols-12 mb-4 gap-4 shadow p-6 mt-6 gap-4 bg-white rounded-lg">
            <div className="col-span-12 md:col-span-6">
              <FormControl
                type="text"
                label="Name"
                value={form.fullName}
                onChange={e => setForm({ ...form, fullName: e })}
                required
              />
            </div>


            <div className="col-span-12 md:col-span-6 mobile_number">
              <label>Mobile No<span className="star">*</span></label>
              <PhoneInput
                country={'us'}
                value={form.mobileNo}
                enableSearch={true}
                limitMaxLength
                required
                onChange={e => setForm({ ...form, mobileNo: e })}
                countryCodeEditable={true}
                minlegth="10"
              />
              {submitted && getError('mobileNo').invalid ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}
            </div>
            <div className="col-span-12 md:col-span-6">
              <label>Email</label>
              <input
                type="email"
                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                value={form.email}
                autoComplete="false"
                onChange={e => { setForm({ ...form, email: e.target.value }); }}
                required
                disabled
              />

            </div>


            <div className="col-span-12 md:col-span-12">
              <label className='lablefontcls'>Image</label><br></br>
              <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} multiple={false} />
            </div>


          </div>


          <div className="text-right mt-3">
            <button className="text-neutral-100 bg-[#283A97] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
          </div>

        </form>





      </div>


    </>
  );
};

export default Html;
