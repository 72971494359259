const shared={
    check:'Module',
    title:'Module ',
    url:'categories',
    category_type:"master",
    subCategory_type:"child",
    addApi:'api/event/create',
    editApi:'api/event/edit',
    detailApi:'api/event/details',
    listApi:'categories/list',
    sub_categoryApi :"video/categories/list",
    vedioListApi :"videos/list",
    completeVedio:"complete/video",
    notificationList:"notifications/list",
    statusApi:'api/event/edit',
    deleteApi:'api/event/delete',
    deleteNotification:"notifications",
    singleTaskApi :"", 
    quizlist : "quiz/list",
    quizsavelist : "quizsave/list",
    quizdetail : "quiz",
    recommandedTasklistApi:"recommanded/task",
    recommandedResorceslistApi: "recommanded/resources",
    recommandedlistApi: "recommanded/event",
}

export default shared