import React, { useState, useEffect, useRef } from "react";
import { IoMdCheckmark } from "react-icons/io";
import {
  useParams,
  useHistory,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "../../components/global/layout";
import shared from "./shared";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { HiMiniPlusCircle } from "react-icons/hi2";
import environment from "../../environment";
import axios from "axios";
const Modals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams();
  const [getDataList, setGetDataList] = useState([]);
  const [nameData, setNameData] = useState("wealth"); // Default to the category from URL
  const [selectedCheckboxId, setSelectedCheckboxId] = useState(null);
  const ModuleListCancelTokenSource = useRef(null);
  const user = useSelector((state) => state?.user);
  const getData = (p) => {
    setNameData(p);
    loader(true);
    ApiClient.get(`${shared?.sub_categoryApi}?category_type=${"master"}&type=${p}&user=${user?.id}&status=active`,"","","",ModuleListCancelTokenSource.current.token).then((res) => {
      setGetDataList(res?.data);
      loader(false);
    });
  };

  const handleCheckboxChange = (id, category) => {
    setSelectedCheckboxId(id);
    navigate(`/subModules/${nameData}/${category}/${id}`);
  };

  useEffect(() => {
    ModuleListCancelTokenSource.current = axios.CancelToken.source();
    getData(nameData);
    const params = new URLSearchParams(location.search);
    const selectedId = params.get("selectedId");
    if (selectedId) {
      setSelectedCheckboxId(selectedId);
    }
    return () => {
      if (ModuleListCancelTokenSource.current) {
        ModuleListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      
    };
  }, [nameData, location.search]);

  return (
    <Layout>
      <div className="">
        <div className="grid grid-cols-12 gap-4 relative ">
          <div className="col-span-12 lg:col-span-12 mx-auto ">
            <div className="flex items-center justify-center  gap-4">
              <div
                className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${nameData === "wealth" && " bg-[#58A541] !text-neutral-100"
                  }`}
                onClick={() => {
                  getData("wealth");
                }}
              >
                Wealth
              </div>
              <div
                className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${nameData === "self" && " bg-[#0079B3] !text-neutral-100"
                  }`}
                onClick={() => {
                  getData("self");
                }}
              >
                Self
              </div>
              <div
                className={` px-6 py-1 text-[18px] font-semibold rounded-full text-neutral-800 cursor-pointer ${nameData === "social" && " bg-[#FFA03B] !text-neutral-100"
                  }`}
                onClick={() => {
                  getData("social");
                }}
              >
                Social
              </div>
            </div>
          </div>
          <div className="searchings col-span-12 lg:col-span-2 lg:absolute right-0 top-0">
            <div className="border flex items-center gap-2 border-neutral-400 p-3 rounded-full">
              <img src="../assets/img/lyfnew/search.svg" alt="Search icon" />
              <input
                type="search"
                className="bg-transparent pl-1 font-semibold placeholder:font-semibold placeholder:text-black w-32 lg:w-full"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        {/* 
        <div className="mt-8">
          <div className="wealth_checks">
            <div className="mb-6">
              <h6 className="text-[50px] lg:text-[64px] text-neutral-800 font-2 font-regular capitalize ">
                {nameData}
              </h6>
            </div>
            <div className="flex flex-col gap-6 flex-wrap h-[600px] overflow-x-auto">
              {getDataList?.map((ele) => (
                <div key={ele.id} className="checksboxes w-[33.33%]">
                  <label
                    className={`w-full border border-neutral-300 font-regular text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 py-4 ${ele.id === selectedCheckboxId ? "checked" : ""
                      }`}
                    onClick={() => handleCheckboxChange(ele.id, ele?.name)}
                  >
                    <input
                      className="sr-only peer"
                      name=""
                      type="checkbox"
                      checked={ele.id === selectedCheckboxId || ele?.isWatched == true} // Control checked state
                      onChange={() => setSelectedCheckboxId(ele.id)} // Update selected checkbox ID
                    />
                    <div
                      className={`w-7 h-7 text-2xl text-white flex items-center justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-[4px] peer-checked:text-neutral-100 ${nameData === "wealth"
                          ? " border-[#58A541]  peer-checked:bg-[#58A541] "
                          : ""
                        }${nameData === "self"
                          ? " border-[#0079B3]  peer-checked:bg-[#0079B3] "
                          : ""
                        }${nameData === "social"
                          ? " border-[#FFA03B]  peer-checked:bg-[#FFA03B] "
                          : ""
                        }`}
                    >
                      <IoMdCheckmark />
                    </div>
                    <p>{ele.name}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className=" mt-2 2xl:mt-8">
          <div
            className={`grid mt-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 ${getDataList.length < 3 ? "" : ""
              }   pr-4`}
          >
            {getDataList?.map((ele) => {
              return (
                <div
                  className="border border-neutral-300 hover:shadow-lg cursor-pointer overflow-hidden rounded-[24px] p-3 group transition duration-300 ease-in-out "
                  onClick={() => handleCheckboxChange(ele.id, ele?.name)}
                >
                  <div className="flex items-center justify-between capitalize">
                    <p
                      className={`px-4 py-2 rounded-full text-[12px]  text-neutral-100 ${nameData === "wealth"
                          ? " bg-[#58A541] !text-neutral-100"
                          : ""
                        }${nameData === "self"
                          ? "bg-[#0079B3] !text-neutral-100"
                          : ""
                        }${nameData === "social"
                          ? " bg-[#FFA03B] !text-neutral-100"
                          : ""
                        }`}
                    >
                      {ele?.type}
                    </p>
                    <div className="flex items-center gap-2 text-[#7E20DC]">
                      <HiMiniPlusCircle className="text-3xl " />
                      <p className="font-semibold text-[16px]">
                        {ele?.coins} COINS
                      </p>
                    </div>
                  </div>
                  <div className="images_tage rounded-[24px] mt-4">
                    <img
                      className="w-full h-44 object-contain bg-white  transform transition-transform duration-300 ease-in-out group-hover:scale-110 "
                      src={
                        ele?.image
                          ? `${environment?.assetsUrl}${ele?.image}`
                          : "../../assets/img/imagelogo.jpg"
                      }
                      onError={(event) => {
                        event.target.src = "../../assets/img/imagelogo.jpg";
                        event.onerror = null;
                      }}
                    />
                  </div>

                  <div className="mt-4 ">
                    <h5 className="mb-3 text-[18px] font-regular">
                      {ele.name}
                    </h5>
                    <p
                      className="text-[14px] font-regular"
                      dangerouslySetInnerHTML={{
                        __html: ele?.summary,
                      }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Modals;
