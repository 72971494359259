import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import styles from "./index.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip, tooltip } from "antd";
import { RiHome6Line } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import environment from "../../../environment";
import { LuTimerReset } from "react-icons/lu";
import { FiAlertTriangle } from "react-icons/fi";
import { SiPlanet, SiSuperuser } from "react-icons/si";
import {
  MdEventAvailable,
  MdOutlineEmojiEvents,
  MdOutlineEventAvailable,
} from "react-icons/md";
import { LuUser2 } from "react-icons/lu";

const IconDashboard = () => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5828 23.7083V16.4167H16.4161V23.7083C16.4161 24.5104 17.0724 25.1667 17.8745 25.1667H22.2495C23.0516 25.1667 23.7078 24.5104 23.7078 23.7083V13.5H26.187C26.8578 13.5 27.1786 12.6687 26.6682 12.2312L14.4766 1.24999C13.9224 0.754154 13.0766 0.754154 12.5224 1.24999L0.330722 12.2312C-0.165112 12.6687 0.141138 13.5 0.811972 13.5H3.29114V23.7083C3.29114 24.5104 3.94739 25.1667 4.74947 25.1667H9.12447C9.92656 25.1667 10.5828 24.5104 10.5828 23.7083Z"
      fill="black"
    />
  </svg>
);

const Users = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C10.1935 0 11.3381 0.526784 12.182 1.46447C13.0259 2.40215 13.5 3.67392 13.5 5C13.5 6.32608 13.0259 7.59785 12.182 8.53553C11.3381 9.47322 10.1935 10 9 10C7.80653 10 6.66193 9.47322 5.81802 8.53553C4.97411 7.59785 4.5 6.32608 4.5 5C4.5 3.67392 4.97411 2.40215 5.81802 1.46447C6.66193 0.526784 7.80653 0 9 0ZM9 12.5C13.9725 12.5 18 14.7375 18 17.5V20H0V17.5C0 14.7375 4.0275 12.5 9 12.5Z"
      fill="black"
    />
  </svg>
);

const Categories = () => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H9V9H0V0ZM15.5 0C18 0 20 2 20 4.5C20 7 18 9 15.5 9C13 9 11 7 11 4.5C11 2 13 0 15.5 0ZM4.5 12L9 20H0L4.5 12ZM17 15H20V17H17V20H15V17H12V15H15V12H17V15Z"
      fill="black"
    />
  </svg>
);

const Learn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <g clip-path="url(#clip0_202_115)">
      <path
        d="M28.8014 6.13957C28.2327 5.57082 27.3139 5.57082 26.7452 6.13957L26.1764 6.70832C25.6077 7.27707 25.6223 8.19582 26.1764 8.74999L26.191 8.76458C26.7598 9.33332 27.6785 9.33332 28.2473 8.76458L28.816 8.19582C29.3702 7.61249 29.3702 6.70832 28.8014 6.13957ZM17.5139 1.45832H17.4993C16.6973 1.45832 16.0556 2.09999 16.0556 2.90207V4.30207C16.0556 5.10416 16.6973 5.74582 17.4993 5.74582H17.5139C18.316 5.74582 18.9577 5.10416 18.9577 4.30207V2.90207C18.9577 2.09999 18.316 1.45832 17.5139 1.45832ZM30.6098 18.8854H32.0973C32.8993 18.8854 33.541 18.2437 33.541 17.4417V17.4271C33.541 16.625 32.8993 15.9833 32.0973 15.9833H30.6243C29.8223 15.9833 29.1806 16.625 29.1806 17.4271V17.4417C29.166 18.2437 29.8077 18.8854 30.6098 18.8854V18.8854ZM13.1243 24.9958V30.5521C13.1243 31.3542 13.7806 32.0104 14.5827 32.0104H20.416C21.2181 32.0104 21.8743 31.3542 21.8743 30.5521V24.9958C24.8493 23.275 26.716 19.8625 26.1473 16.0562C25.5639 12.25 22.4139 9.20207 18.5931 8.73541C13.2848 8.09374 8.74935 12.2354 8.74935 17.4125C8.74935 20.6646 10.5139 23.4792 13.1243 24.9958V24.9958ZM5.83268 17.4417V17.4271C5.83268 16.625 5.19101 15.9833 4.38893 15.9833H2.91601C2.11393 15.9833 1.47226 16.625 1.47226 17.4271V17.4417C1.47226 18.2437 2.11393 18.8854 2.91601 18.8854H4.38893C5.19101 18.8854 5.83268 18.2437 5.83268 17.4417ZM8.83685 6.69374L8.2681 6.12499C7.69935 5.55624 6.7806 5.55624 6.21185 6.12499C5.6431 6.69374 5.6431 7.61249 6.21185 8.18124L6.7806 8.74999C7.34935 9.31874 8.2681 9.30416 8.82226 8.74999C9.4056 8.16666 9.4056 7.26249 8.83685 6.69374Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_202_115">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="matrix(-1 0 0 -1 35 35)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Tasks = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_201_2110)">
      <path
        d="M30.6257 11.6667C28.5111 11.6667 27.3298 13.7667 27.8111 15.3271L22.634 20.5187C22.1965 20.3875 21.5548 20.3875 21.1173 20.5187L17.3986 16.8C17.8944 15.2396 16.7132 13.125 14.584 13.125C12.4694 13.125 11.2736 15.225 11.7694 16.8L5.1194 23.4354C3.55898 22.9542 1.45898 24.1354 1.45898 26.25C1.45898 27.8542 2.77148 29.1667 4.37565 29.1667C6.49023 29.1667 7.67148 27.0667 7.19023 25.5062L13.8257 18.8562C14.2632 18.9875 14.9048 18.9875 15.3423 18.8562L19.0611 22.575C18.5652 24.1354 19.7465 26.25 21.8757 26.25C23.9902 26.25 25.1861 24.15 24.6902 22.575L29.8819 17.3979C31.4423 17.8792 33.5423 16.6979 33.5423 14.5833C33.5423 12.9792 32.2298 11.6667 30.6257 11.6667Z"
        fill="black"
      />
      <path
        d="M21.875 13.125L23.2458 10.1062L26.25 8.75L23.2458 7.39375L21.875 4.375L20.5333 7.39375L17.5 8.75L20.5333 10.1062L21.875 13.125Z"
        fill="black"
      />
      <path
        d="M5.10482 16.0417L5.83398 13.125L8.75065 12.3958L5.83398 11.6667L5.10482 8.75L4.37565 11.6667L1.45898 12.3958L4.37565 13.125L5.10482 16.0417Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_201_2110">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Resources = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_201_2109)">
      <path
        d="M16.2607 4.95833L10.8357 13.825C10.2378 14.7875 10.9378 16.0417 12.0753 16.0417H22.9107C24.0482 16.0417 24.7482 14.7875 24.1503 13.825L18.7399 4.95833C18.1711 4.025 16.8295 4.025 16.2607 4.95833Z"
        fill="black"
      />
      <path
        d="M25.5215 32.0833C29.1459 32.0833 32.084 29.1452 32.084 25.5208C32.084 21.8965 29.1459 18.9583 25.5215 18.9583C21.8971 18.9583 18.959 21.8965 18.959 25.5208C18.959 29.1452 21.8971 32.0833 25.5215 32.0833Z"
        fill="black"
      />
      <path
        d="M5.83333 31.3542H14.5833C15.3854 31.3542 16.0417 30.6979 16.0417 29.8958V21.1458C16.0417 20.3437 15.3854 19.6875 14.5833 19.6875H5.83333C5.03125 19.6875 4.375 20.3437 4.375 21.1458V29.8958C4.375 30.6979 5.03125 31.3542 5.83333 31.3542Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_201_2109">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Events = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_201_2112)">
      <path
        d="M23.3333 14.5833H11.6667C10.8646 14.5833 10.2083 15.2396 10.2083 16.0416C10.2083 16.8437 10.8646 17.5 11.6667 17.5H23.3333C24.1354 17.5 24.7917 16.8437 24.7917 16.0416C24.7917 15.2396 24.1354 14.5833 23.3333 14.5833ZM27.7083 4.37498H26.25V2.91665C26.25 2.11456 25.5938 1.45831 24.7917 1.45831C23.9896 1.45831 23.3333 2.11456 23.3333 2.91665V4.37498H11.6667V2.91665C11.6667 2.11456 11.0104 1.45831 10.2083 1.45831C9.40625 1.45831 8.75 2.11456 8.75 2.91665V4.37498H7.29167C5.67292 4.37498 4.375 5.68748 4.375 7.29165V27.7083C4.375 29.3125 5.67292 30.625 7.29167 30.625H27.7083C29.3125 30.625 30.625 29.3125 30.625 27.7083V7.29165C30.625 5.68748 29.3125 4.37498 27.7083 4.37498ZM26.25 27.7083H8.75C7.94792 27.7083 7.29167 27.0521 7.29167 26.25V11.6666H27.7083V26.25C27.7083 27.0521 27.0521 27.7083 26.25 27.7083ZM18.9583 20.4166H11.6667C10.8646 20.4166 10.2083 21.0729 10.2083 21.875C10.2083 22.6771 10.8646 23.3333 11.6667 23.3333H18.9583C19.7604 23.3333 20.4167 22.6771 20.4167 21.875C20.4167 21.0729 19.7604 20.4166 18.9583 20.4166Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_201_2112">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Marketplace = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4444 3.55556H24.8889V1.77778C24.8889 0.8 24.0889 0 23.1111 0H8.88889C7.91111 0 7.11111 0.8 7.11111 1.77778V3.55556H3.55556C1.6 3.55556 0 5.15556 0 7.11111V8.88889C0 13.4222 3.41333 17.12 7.80444 17.6711C8.92444 20.3378 11.3244 22.3467 14.2222 22.9333V28.4444H8.88889C7.91111 28.4444 7.11111 29.2444 7.11111 30.2222C7.11111 31.2 7.91111 32 8.88889 32H23.1111C24.0889 32 24.8889 31.2 24.8889 30.2222C24.8889 29.2444 24.0889 28.4444 23.1111 28.4444H17.7778V22.9333C20.6756 22.3467 23.0756 20.3378 24.1956 17.6711C28.5867 17.12 32 13.4222 32 8.88889V7.11111C32 5.15556 30.4 3.55556 28.4444 3.55556ZM3.55556 8.88889V7.11111H7.11111V13.9022C5.04889 13.1556 3.55556 11.2 3.55556 8.88889ZM28.4444 8.88889C28.4444 11.2 26.9511 13.1556 24.8889 13.9022V7.11111H28.4444V8.88889Z"
      fill="black"
    />
  </svg>
);

const Html = ({ ListItemLink, tabclass, isAllow, route, isOpen, user }) => {
  const location = useLocation();

  const menus = [
    // {
    //   name: 'Main Menu',
    // },
    {
      name: "Home",
      icon: <IconDashboard />,
      url: "/dashboard",
      key: "readDashboard",
    },
    // {
    //   name: "Users",
    //   icon: <Users/>,
    //   url: "/user",
    //   key: "",
    // },
    // {
    //   name: "Categories",
    //   icon: <Categories/>,
    //   url: "/category",
    //   key: "",
    // },
    {
      name: "Learn",
      icon: <Learn />,
      url: "/learn",
      key: "",
    },
    {
      name: "Tasks",
      icon: <Tasks />,
      url: "/tasks",
      key: "",
    },
    {
      name: "Resources",
      icon: <Resources />,
      url: "/resources",
      key: "",
    },
    {
      name: "Events",
      icon: <Events />,
      url: "/events",
      key: "",
    },
    {
      name: "Rewards",
      icon: <Marketplace />,
      url: "/rewards",
      key: "",
    },
  ];

  const NavlinkRender = (itm) => {
    return (
      <NavLink onClick={()=>{ localStorage.removeItem('previousPath')}}
        to={itm.url}
        className={(isActive) =>
          " pt-1 2xl:pt-3  px-2 rounded-md flex items-center gap-[10px] text-[14px] xl:text-[20px]  font-normal text-neutral-800 hover:!text-[#7E20DC] !no-underline transition-all " +
          (location?.pathname == itm.url &&
            " !text-[#7E20DC] !font-[600] actives ")
        }
      >
        <span className="w-5 2xl:w-8 text-center inline-flex items-center">
          {itm.icon}
        </span>
        <span className="text-inherit leading-none sidebar_text text-[14px] xl:text-[20px]">
          {itm.name}
        </span>
      </NavLink>
    );
  };
  return (
    <>
      <div className={` ${isOpen && styles.sm_sidebar}`} component="siderbar">
        <ul
          className={`space-y-1 2xl:space-y-2 px-2 md:max-h-[300px] lg:max-h-[400px] xl:max-h-[500px] overflow-auto h-full mb-4 shrink ${
            isOpen ? "flex space-y-2 flex-col items-center" : ""
          }`}
        >
          {menus.map((itm) => {
            return (
              <>  
                {itm.icon ? (
                  <>
                    <li>
                      {itm.menu ? (
                        <>
                          <Disclosure as="div" defaultOpen={tabclass(itm.tab)}>
                            {({ open }) => (
                              <>
                                <Tooltip placement="right" title={itm.name}>
                                  <Disclosure.Button className="w-full p-2 pb-0 rounded-md flex items-center justify-between text-[#4A545E]  hover:!text-[#5577FF] gap-[12px] hover:bg-[#5577FF]/10 transition-all duration-300">
                                    <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                                      {itm.icon}
                                      <span className=" text-inherit leading-none sidebar_text text-[16px] xl:text-[20px]">
                                        {" "}
                                        {itm.name}
                                      </span>
                                    </span>

                                    <TiArrowSortedDown
                                      className={`${
                                        open ? "" : "-rotate-90 transform"
                                      } h-4 w-4 transition-all duration-500  text-[#7E8B99]`}
                                    />
                                  </Disclosure.Button>
                                </Tooltip>
                                <Transition
                                  enter="transition duration-300 ease-in-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-300 opacity-300"
                                  leave="transition duration-300 ease-in-out"
                                  leaveFrom="transform scale-300 opacity-300"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="pl-[30px] mt-[4px] ">
                                    <ul className="space-y-2">
                                      {itm.menu.map((sitm) => {
                                        return (
                                          <>
                                            {isAllow(sitm.key) ? (
                                              <li>
                                                {" "}
                                                <NavLink
                                                  className={(isActive) =>
                                                    " pt-2 2xl:pt-3  px-2 rounded-md block text-[16px] xl:text-[20px] font-normal text-[#4A545E] cursor-pointer hover:!text-[#5577FF] hover:bg-[#5577FF]/10 !no-underline transition-all " +
                                                    (location?.pathname ==
                                                      sitm.url &&
                                                      " !text-[#5577FF] !bg-[#5577FF]/10 !font-medium")
                                                  }
                                                  to={sitm.url}
                                                >
                                                  <span
                                                    className="text-inherit leading-none sidebar_text text-[16px] xl:text-[20px]"
                                                    title={sitm.name}
                                                  >
                                                    {" "}
                                                    {sitm.name}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        </>
                      ) : (
                        <>
                          {isAllow(itm.key) ? (
                            <>
                              {isOpen ? (
                                <Tooltip placement="right" title={itm.name}>
                                  {NavlinkRender(itm)}
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="right"
                                  title={itm.name}
                                  visible={false}
                                >
                                  {NavlinkRender(itm)}
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <h6
                        className={`${
                          isOpen
                            ? "py-[12px] text-center"
                            : "p-[12px] text-center text-md"
                        } text-xs font-medium text-[#7E8B99]`}
                      >
                        <span className=" sidebar_text text-center text-[#fff]">
                          {" "}
                          {itm.name}{" "}
                        </span>
                      </h6>
                    </li>
                  </>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Html;
