const types = [
    { id: "self", name: "Self" },
    { id: "social", name: "Social" },
    { id: "wealth", name: "Wealth" },
  ];
  
  const shared = {
    check: "product",
    title: "Products",
    addTitle: "Product",
    url: "product",
    addApi: "product",
    editApi: "product",
    detailApi: "product",
    listApi: "product/list",
    statusApi: "product",
    deleteApi: "product",
    addCart:"cart",
    cartList:"cart/list",
    types: [...types],
  };
  
  export default shared;