import React, { useEffect, useState } from "react";
import Layout from '../../components/global/layout'
import { useSelector } from "react-redux";
import { Fragment } from "react";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import loader from "../../methods/loader";
import shared from "./shared";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import { IoCloseOutline, IoFlashOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
const SeeAll = () => {

  const [recommandedList, setRecommandedList] = useState([]);
  const [singleList, setSingleList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state?.user);
  function closeModal() {
    setIsOpen(false);
  }
  // Get the current date and time
  let currentDate = new Date();

  // Extract components
  let year = currentDate.getUTCFullYear();
  let month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  let day = String(currentDate.getUTCDate()).padStart(2, '0');
  let hours = String(currentDate.getUTCHours()).padStart(2, '0');
  let minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
  let seconds = '00'; // Fixed to '00' for this format
  let timezoneOffset = -currentDate.getTimezoneOffset(); // Get offset in minutes and convert to positive

  // Create the formatted date string
  let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${formatTimezoneOffset(timezoneOffset)}`;

  // Function to format timezone offset to 'HH:MM'
  function formatTimezoneOffset(offset) {
    let hours = Math.floor(offset / 60);
    let minutes = offset % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }


  const RecommendList = () => {
    let today = new Date();
    loader(true);
    let payload = {
      user: user?.id,
      startDate: moment(today).format("YYYY-MM-DD"),
    };
    ApiClient.get(`${shared?.listApi}`, payload).then((res) => {
      setRecommandedList(res?.data);
      loader(false);
    });
  };
  useEffect(() => {
    RecommendList();

  }, []);
  const handleAgenda = (ele) => {
  
    let payload = {
      user: user?.id.toString(),
      event: ele?.id.toString(),
      eventDate: ele?.start_date,
      category: ele?.category?.id.toString()

    };
    loader(true);
    ApiClient.post(`${shared?.addCalendarEvent}`, payload).then((res) => {
      toast(res?.message);

      loader(false);
    });
  };
  function openModal(ele) {
    setSingleList(ele)
    setIsOpen(true);
  }
  return (
    <>
      <Layout>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">


          {recommandedList?.map((ele, ind) => (
            <div key={ind} className="border border-neutral-300 g-white rounded-[24px] p-3">
              <div className="images_tage rounded-[24px] relative">
                <img className="w-full h-44 object-cover rounded-[24px]" src="../assets/img/lyfnew/oness.png" alt="Event Image" />
                <p className={`px-4 py-2 rounded-full text-[12px] text-neutral-100 absolute left-2 top-4 
                          ${ele?.category?.type === "wealth" ? "border-[#58A541] bg-[#58A541]" : ""}
                          ${ele?.category?.type === "self" ? "border-[#0079B3] bg-[#0079B3]" : ""}
                          ${ele?.category?.type === "social" ? "border-[#FFA03B] bg-[#FFA03B]" : ""}`}>
                  {ele?.category?.type}
                </p>
              </div>
              <div className="mt-4">
                <div className="flex items-center flex-wrap justify-between mt-2 mb-4 gap-2">
                  <p className="text-[12px] text-neutral-600 font-regular">
                    {moment(ele?.start_date ? ele?.start_date : "").format("DD/MM/YY")}
                  </p>
                  <p className="text-neutral-800 text-[14px] font-regular">{ele?.name}</p>
                </div>
                <p className="text-[14px] font-regular line-clamp-2" dangerouslySetInnerHTML={{ __html: ele?.description }}></p>
                <div className="flex items-center justify-between mt-4">
                  <div >
                    <button onClick={() => openModal(ele)} className="border border-neutral-200 rounded-full bg-primary px-6 py-3 text-[14px] font-normal text-neutral-100 focus:outline-none font-semibold font-three">
                      About
                    </button>
                  </div>
                  {ele?.start_date > formattedDate ? <p className="text-primary font-three font-semibold" onClick={() => handleAgenda(ele)}>
                    Add to agenda
                  </p> : ""}

                </div>
              </div>
            </div>

          ))}
        </div>


        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden  bg-white  text-left align-middle shadow-xl transition-all">

                    <div className="">
                      <div className="modal_inners">
                        <div className="imsg_data relative">
                          <IoCloseOutline
                            onClick={closeModal}
                            className="text-3xl text-neutral-100 cursor-pointer absolute right-5 top-2"
                          />
                          <img
                            className="h-62 w-full object-cover"
                            src="assets/img/lyf/eventsb.png"
                          />
                        </div>
                        <div className="px-10 py-4 flex justify-between gap-4 mb-8">
                          <div className="shrink-0">
                            <div className="text-[#004AAD]">
                              <p className="text-[20px] lg:text-[27px] font-bold flex items-start  leading-6">
                                {moment(singleList?.start_date).format("DD/MM/YY")}
                              </p>

                            </div>
                          </div>

                          <div className="flex flex-col gap-y-4">
                            <div className="text-[#004AAD]">
                              <p className="text-[20px] lg:text-[26px]  font-semibold">
                                {singleList?.name}
                              </p>
                              <p className="text-[16px] flex items-center gap-4">
                                {singleList?.address}
                              </p>
                            </div>

                            <div className="decri">
                              <p className="text-[#404040] text-[20px] font-semibold">
                                Description
                              </p>
                              <p className="text-[#404040] text-[15px] font-normal" dangerouslySetInnerHTML={{ __html: singleList?.description }}>

                              </p>
                            </div>

                            <div className="flex items-center gap-10">
                              {/* <p className="text-[#004AAD] text-[15px] font-normal">
                                        URL Link Space
                                      </p> */}
                              <p className="text-[#404040] text-[20px] font-semibold">
                                Cost: {singleList?.price}
                              </p>
                            </div>

                            <div className="flex items-center gap-10 text-[#004AAD]">

                              {singleList?.start_date > formattedDate ? <button className="border border-[#004AAD] rounded-full px-6 py-2 mt-2 cursor-pointer" onClick={() => handleAgenda(singleList)}>
                                Add to agenda
                              </button> : ""}
                              <button className="border border-[#004AAD] rounded-full px-6 py-2 mt-2 cursor-pointer">
                                Share with friends
                              </button>
                            </div>
                          </div>

                          <div className="shrink-0 text-[#004AAD]">
                            <div className="flex items-center gap-2 ">
                              <p className="text-2xl  flex items-center gap-2">
                                <LuUsers />
                                {/* <span className="text-[13px]">390</span> */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition> </Layout>
    </>
  )
}

export default SeeAll