import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import AuthLayout from "../../components/AuthLayout";
import environment from "../../environment";
import { toast } from "react-toastify";
import crendentialModel from "../../models/credential.model";
import methodModel from "../../methods/methods";
import FormControl from "../../components/common/FormControl";
import { useSelector } from "react-redux";
import datepipeModel from "../../models/datepipemodel";
import { RxEyeClosed } from "react-icons/rx";
import ImageUpload from "../../components/common/ImageUpload";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const Signup = () => {
  const history = useNavigate();
  const user: any = useSelector((state: any) => state?.user);
  const [form, setForm]: any = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    birthday: "",
    // role: "",
    university: "",
    gender: "",
    ethnicity: "",
    // image:""
    // customerRole: environment.customerRoleId,
  });

  // const formValidation = [
  //   { key: "birthday", required: true, message: "DOB is required" },
  //   // { key: "role", required: true, message: "Role is required" },
  //   { key: "university", required: true, message: "University is required" },
  //   { key: "gender", required: true, message: "Gender is required" },
  //   { key: "ethnicity", required: true, message: "Ethnicity is required" },
  //   { key: "email", required: true, message: "Email is required" },
  //   { key: "password", required: true, message: "Password is required" },
  // ];

  const [universities, setUniversities] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [remember, setRemember] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [groups, setGroups] = useState([]);
  const [images, setImages]: any = useState({ image: "" });
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const imageResult = (e: any, key: any = ""): any => {
    images[key] = e.value;
    setImages(images);
   
  };
  const setLogin = (data: any) => {
    localStorage.setItem("token", data.access_token);
    crendentialModel.setUser(data);
    let url = "/profile";
    let eventId = methodModel.getPrams("eventId");
    if (eventId) url = `/dashboard?eventId=${eventId}`;
    history(url);
  };

  // const getError = (key: string) => {
  //   return methodModel.getError(key, form, formValidation);
  // };

  const getGroups = () => {
    ApiClient.get("api/group/list", { status: "active" }).then((res: any) => {
      if (res.success) {
        setGroups(res.data);
      }
    });
  };
  const handleDateChange = (date :any) => {
    if (date) {
      const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`; // Format to mm-dd-yyyy
      setForm({ ...form, birthday: formattedDate });
    } else {
      setForm({ ...form, birthday: null });
    }
  };
  const hendleSubmit = (e: any) => {
    e.preventDefault();
    // setSubmitted(true);

    let url = "register";
    let eventId = methodModel.getPrams("eventId");
    let groupId = methodModel.getPrams("groupId");

    // if (!remember) return;
    // let invalid = methodModel.getFormError(formValidation, form );
    if (!form?.birthday) return;
    if (emailError) return;
    const image = images?.image;
    let data: any = {
      // role: environment.userRoleId,
      ...form,
      image,
    };

    // if (groupId) data.groupId = groupId;
    // if (data.customerRole == environment.glRoleId) {
    //   if (!data.groupId) {
    //     return;
    //   }
    // }

    loader(true);

    ApiClient.post(url, data).then(async (res) => {
      if (res.success) {
        setSubmitted(false);

        if (eventId || groupId) {
          await ApiClient.post("api/auto/login", { id: res.data._id }).then(
            async (res) => {
              setLogin(res.data);
            }
          );
        } else {
          let url = "/login";
          setTimeout(() => {
            toast.success("Please verify your email");
          }, 400);
          history(url);
        }
      }
      loader(false);
    });
  };

  useEffect(() => {
    let email = methodModel.getPrams("email");
    if (user && user?.loggedIn) {
      history("/dashboard");
    }

    if (email) {
      setForm({
        ...form,
        email: email,
        fullName: methodModel.getPrams("name"),
      });
    }

    // getGroups();
  }, []);

  useEffect(() => {
    getUniversitiesList();
    // getEthnicitiesList();
  }, []);

  const getUniversitiesList = () => {
    ApiClient.get("universities/list").then((res) => {
      if (res.success) {
        setUniversities(res.data);
      }
    });
  };

  const getEthnicitiesList = (e: any) => {
    ApiClient.get(`ethnicities/list?university=${e}&count=10&page=1&status=active`).then(
      (res) => {
        if (res.success) {
          setEthnicities(res.data);
        }
      }
    );
  };

  const universityOptions = universities?.map(({ id, name }) => {
    return { id: id, name: name };
  });

  const ethnicityOptions = ethnicities?.map(({ id, name }) => {
    return { id: id, name: name };
  });

  const roleOptions = [
    { id: "university_admin", name: "University Admin" },
    { id: "student", name: "Student" },
  ];

  const genderOptions = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
  ];

  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 18); // Subtract 18 years
  const handleUniverity = (e: any) => {
    setForm({ ...form, university: e.toString() });
    getEthnicitiesList(e);
  };
  const [emailError, setEmailError] = useState("");
  const validateEmail = (email: any) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(email);
  };
  const handleEmailChange = (e: any) => {
    const email = e?.target?.value;
    setForm({ ...form, email: e?.target?.value });
    if (email?.trim() === "") {
      setEmailError("Email address is required");
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <AuthLayout>
        <div className="w-full overflow-y-auto onlyscroll px-4 py-4 ">
          <form
            className="lg:h-[calc(80vh-100px)] xl:h-[calc(90vh-100px)]"
            onSubmit={hendleSubmit}
            autoComplete="off"
          >
            <div className="flex items-center  justify-between">
              <h3 className="text-[32px] font-regular text-[#111827]">
                Sign up
              </h3>
              {/* <img className='cursor-pointer' src="../assets/img/lyfnew/close.svg" /> */}
              {/* <Link to="/profile/edit" className="!px-4 text-sm font-normal bg-[#283A97]  text-neutral-100 h-10 flex items-center justify-center gap-2 !bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">

                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                          Edit Profile

                        </Link> */}
            </div>

            <div className="grid grid-cols-12 gap-4 mt-8 ">
              <div className="col-span-12 md:col-span-3 lg:col-span-3 2xl:col-span-2 ">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex items-end gap-4">
                    <div className="relative mb-4">
                      {/* <img src="../assets/img/person.jpg" className="h-28 w-28 rounded-full " /> */}
                      <p className="cursor-pointer ">
                        {/* <label htmlFor="fileInput">
                          <img
                            className="cursor-pointer"
                            src="../assets/img/lyfnew/edit.svg"
                            alt="Click to upload"
                          />
                        </label> */}
                        <input type="file" className="hidden" id="fileInput" />
                        <ImageUpload
                          model="users"
                          name="edit"
                          result={(e: any) => imageResult(e, "image")}
                          err=""
                          type="image"
                          required={false}
                          value={images.image}
                          multiple={false}
                        />
                        {/* <ImageUpload model="users" result={e=> imageResult(e, 'image')} value={images.image || form.image} multiple={false} /> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 md:col-span-9 lg:col-span-9 2xl:col-span-7">
                <div className="grid grid-cols-12 gap-2 2xl:gap-2 pl-6">
                  <div className="col-span-12">
                    <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                      First Name*
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setForm({ ...form, firstName: e.target.value })
                      }
                      value={form.firstName}
                      className="w-full border border-neutral-300 h-12 bg-white !rounded-[16px] px-4 py-1"
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="col-span-12">
                    <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                      Last Name*
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setForm({ ...form, lastName: e.target.value })
                      }
                      value={form.lastName}
                      className="w-full border border-neutral-300 h-12 bg-white !rounded-[16px] px-4 py-1"
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="col-span-12 bg_select">
                    <label className="text-neutral-800 font-regular  mb-2 flex  text-[14px]">
                      Birthday*
                    </label>
                      <div className="datepickers">
                        <DatePicker
                          selected={form.birthday ? new Date(form.birthday) : null} // Convert to Date object
                          onChange={handleDateChange}
                            className="w-full border border-neutral-300 h-12 bg-white !rounded-[16px] px-4 py-1"
                          dateFormat="MM-dd-yyyy" // Display format with full year
                          maxDate={currentDate} // Set maximum date
                          required
                          placeholderText="mm-dd-yyyy" // Placeholder for format guidance
                        />
                      </div>
                  </div>

                  <div className="col-span-12 bg_select">
                    <div className="grid grid-cols-1  gap-4">
                      <div>
                        <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                          University*
                        </label>

                        <FormControl
                          type="select"
                          className="w-full rounded-[16px]"
                          value={form.university}
                          onChange={handleUniverity}
                          options={universityOptions}
                            placeholder=" Select University"
                          required
                        />
                        {submitted && !form.university && (
                          <div className="invalid-feedback d-block">
                            University is required.
                          </div>
                        )}
                      </div>

                      <div>
                        <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                          Class*
                        </label>

                        <FormControl
                          type="select" 
                          className="w-full rounded-[16px]"
                          value={form.ethnicity}
                          onChange={(e: string) =>
                            setForm({ ...form, ethnicity: e.toString() })
                          }
                          options={ethnicities}
                               placeholder=" Select Class"
                          required
                          // error={getError("ethnicity")}
                        />
                        {submitted && !form.ethnicity && (
                          <div className="invalid-feedback d-block">
                            Class is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12">
                    <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                      University E-mail*
                    </label>

                    <div className="text-sm text-gray-500 rounded-[16px] border border-neutral-300 flex items-center px-4 h-12">
                      <img
                        className="cursor-pointer "
                        src="../assets/img/lyfnew/mail.svg"
                      />
                      <input
                        type="email"
                        // onChange={(e) => setForm({ ...form, email: e.target.value })}
                        value={form.email}
                        className="w-full h-10 bg-white !rounded-[16px] px-4 py-1"
                        placeholder=""
                        autoComplete="off"
                        required
                        // error={getError("email")}
                        onChange={handleEmailChange}
                      />
                    </div>

                    {emailError && (
                      <p className="text-red-500 text-xs">{emailError}</p>
                    )}
                  </div>

                  <div className="col-span-12">
                    <label className="text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]">
                      Password*
                    </label>

                    <div className="text-sm text-gray-500 rounded-[16px] border border-neutral-300 flex items-center px-4 h-12">
                      <img
                        className="cursor-pointer "
                        src="../assets/img/lyfnew/clock.svg"
                      />
                      <div className="relative w-full">
                        <input
                          type={eyes.password ? "text" : "password"}
                          className="w-full h-10 bg-white !rounded-[16px] px-4 py-1"
                          placeholder="Password"
                          onChange={(e) =>
                            setForm({ ...form, password: e.target.value })
                          }
                          value={form.password}
                          minLength={8}
                          autoComplete="off"
                          required
                        />
                        {eyes.password ? (
                          <RxEyeClosed
                            className="cursor-pointer font-[12px] right-3 absolute top-1/2 -translate-y-1/2 text-gray-600"
                            onClick={() =>
                              setEyes({ ...eyes, password: !eyes.password })
                            }
                          />
                        ) : (
                          <RxEyeClosed
                            className="cursor-pointer font-[12px] right-3 absolute top-1/2 -translate-y-1/2 text-gray-600"
                            onClick={() =>
                              setEyes({ ...eyes, password: !eyes.password })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className='absolute right-0 inset-y-0 flex items-center text-gray-500 text-sm'>
                            <i className={eyes.password ? 'fa fa-eye top-3 right-3 absolute text-gray-600' : 'fa fa-eye-slash top-3 right-3 absolute text-gray-600'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>

                          </div> */}

                  <div className="mt-2 mb-6 col-span-12">
                    <div className="flex items-center gap-4">
                      <button
                        type="submit"
                        onClick={() => {
                          setSubmitted(true);
                          
                        }}
                        className="px-6 text-sm font-normal text-neutral-100 h-12   gap-2 bg-[#004AAD] rounded-[30px] shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                      >
                        Create Account
                      </button>

                      <p className=" text-neutral-800  ">
                        Already have an Account?
                        <Link
                          className="underline cursor-pointer  decoration-8 underline-offset-4 font-semibold text-[#5C0BAD]"
                          to="/login"
                        >
                          {" "}
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {methodModel.getPrams('eventId')||methodModel.getPrams('groupId')?<>

                </>:<>
                <div className='mb-3'>
                    <FormControl
                    type='radio'
                    options={[
                      {id:environment.customerRoleId,name:'No'},
                      {id:environment.glRoleId,name:'Yes'},
                    ]}
                    label="Do You Want To Be A Group Leader"
                    value={form.customerRole}
                    onChange={(e:any)=>{
                      setForm({...form,customerRole:e,groupId:null})
                    }}
                    />
          </div>
          </>}
           */}

            {/* {form.customerRole==environment.glRoleId?<>
  <div className='mb-3 relative'>
           
           <FormControl
           type='select'
           options={groups}
           displayValue="name"
           placeholder="Select Group"
           label="Group"
           value={form.groupId}
           onChange={(e:any)=>{
             setForm({...form,groupId:e})
           }}
           />
           {submitted&&!form.groupId?<>
           <div className='text-red-600 text-sm'>Group is required</div>
           </>:<></>}
         </div>
</>:<></>} */}

            {/* <div className='flex'>
            <label className='flex items-center pointer'><input type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} className="mr-2 h-4 w-4" /> <span className='text-xs text-gray-600'>By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</span></label>
 
          </div>

          {submitted&&!remember?<>
           <div className='text-red-600 text-sm capitalize mt-3'>Please agree our Terms Of Use And Privacy Policy</div>
           </>:<></>} */}

            {/* <p className='text-sm mt-3 text-center'>Already have an account? <Link to="/login" className='text-orange-500 text-sm'>Sign In</Link></p> */}
          </form>
        </div>
      </AuthLayout>
    </>
  );
};

export default Signup;
