/*
 * @file: index.js
 * @description: It Contain rest functions for api call .
 * @author: Poonam
 */

import axios from "axios";
import querystring from "querystring";
import { setAuthorizationToken } from "../auth";
import { toast } from "react-toastify";
import loader from "../loader";
import environment from "../../environment";
import methodModel from "../methods";

var config = {
  headers: { "Content-Type": "application/json" },
};

var baseUrl = environment.api;

const handleError = (err, hideError ,status) => {
  let message = "";
  if (err) {
    if ((err && err.code == 401 ) || status === 403 ) {
      localStorage.removeItem("persist:admin-app");
      localStorage.removeItem("token");
      hideError = true;
      document.getElementById('logout')?.click()
      methodModel.route("/");
    }
    message = err && err.error && err.error.message;
    if (!message) message = err.message;
    if (!message) message = err.detail;

    if (!message) message = "Server Error";
  }
  if (!hideError) toast.error(message);
 
};

class ApiClient {
  static post(url1, payload, params = {}, base = "") {
    let url = baseUrl + url1;
    if (base) url = base + url1;

    setAuthorizationToken(axios);
    return new Promise(function (fulfill, reject) {
      let c = {
        ...config,
        params: params,
      };
      axios
        .post(url, JSON.stringify(payload), c)
        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);

          if (error && error.response) {
            let eres = error.response;
            handleError(eres.data);
            fulfill({ ...eres.data, success: false });
           
          } else {
            toast.error("Network Error");
            reject(error);
           
          }
        });
    });
  }

  static put(url1, payload, params = {}, base = "") {
    let url = baseUrl + url1;
    if (base) url = base + url1;
    setAuthorizationToken(axios);
    return new Promise(function (fulfill, reject) {
      let c = {
        ...config,
        params: params,
      };
      axios
        .put(url, JSON.stringify(payload), c)
        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);
          if (error && error.response) {
            let eres = error.response;
            handleError(eres.data);
            fulfill(eres.data);
          } else {
            toast.error("Network Error");
            reject(error);
          }
        });
    });
  }

  static get(url1, params = {}, base = "", hidError = "") {
    let url = baseUrl + url1;
    if (base) url = base + url1;

    let query = querystring.stringify(params);
    url = query ? `${url}?${query}` : url;
    setAuthorizationToken(axios,params.access_token);
    return new Promise(function (fulfill, reject) {
      axios
        .get(url, config)
        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);
          if (error && error.response) {
            let eres = error.response;
            handleError(eres.data, hidError);
            fulfill({ ...eres.data, success: false });
          } else {
            toast.error("Network Error");
            reject(error);
          }
        });
    });
  }
  // static get(url1, params = {}, base = "", hidError = "", cancelToken = null) {
  //   let url = baseUrl + url1;
  //   if (base) url = base + url1;

  //   let query = querystring.stringify(params);
  //   url = query ? `${url}?${query}` : url;
  //   setAuthorizationToken(axios, params.access_token);

  //   return new Promise(function (fulfill, reject) {
  //     let newconfig = config;
  //     if (cancelToken) {
  //       newconfig["cancelToken"] = cancelToken;
  //     }
  //     axios
  //       .get(url, config)
  //       .then(function (response) {
  //         fulfill(response && response.data);
  //       })
  //       .catch(function (error) {
  //         loader(false);
  //         if (error && error.response) {
  //           let eres = error.response;
  //           handleError(eres.data, hidError);
  //           fulfill({ ...eres.data, success: false });
  //         } else {
  //           toast.error("Network Error");
  //           reject(error);
  //         }
  //       });
  //   });
  // }
  static get(url1, params = {}, base = "", hidError = "", cancelToken = null) {
    let url = baseUrl + url1;
    if (base) url = base + url1;

    let query = querystring.stringify(params);
    url = query ? `${url}?${query}` : url;
    setAuthorizationToken(axios, params.access_token);

    return new Promise(function (fulfill, reject) {
      let newconfig = {...config , cancelToken }
      // if (cancelToken) {
      //   newconfig["cancelToken"] = cancelToken;
      // }
      axios
        .get(url, newconfig)
        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);
          if (error && error.response) {
            let eres = error.response;
         
            handleError(eres.data, hidError , eres?.status);
            fulfill({ ...eres.data, success: false });
          } else {
            if(error?.name === "CanceledError"){
              console.log(error)
            }
            else{
              toast.error("Network Error");
              reject(error);
            }      
          
          }
        });
    });
  }

  static delete(url1, params = {}, base = "") {
    let url = baseUrl + url1;
    if (base) url = base + url1;

    let query = querystring.stringify(params);
    url = query ? `${url}?${query}` : url;
    setAuthorizationToken(axios);
    return new Promise(function (fulfill, reject) {
      axios
        .delete(url, config)
        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);
          if (error && error.response) {
            let eres = error.response;
            handleError(eres.data);
            fulfill(eres.data);
          } else {
            toast.error("Network Error");
            reject(error);
          }
        });
    });
  }

  static allApi(url, payload, method = "get", params = {}) {
    if (method == "get") {
      return this.get(url, payload);
    } else if (method == "put") {
      return this.put(url, payload, params);
    }
    if (method == "post") {
      return this.post(url, payload, params);
    }
  }

  /*************** Form-Data Method ***********/
  static postFormData(url, params) {
    url = baseUrl + url;
    setAuthorizationToken(axios);
    return new Promise(function (fulfill, reject) {
      // var body = new FormData();
      // let oArr = Object.keys(params);
      // oArr.map((itm) => {
      //   body.append(itm, params[itm]);
      // });
      let body = params;

      axios
        .postForm(url, body, config)

        .then(function (response) {
          fulfill(response && response.data);
        })
        .catch(function (error) {
          loader(false);
          if (error && error.response) {
            let eres = error.response;
            handleError(eres.data);
            fulfill(eres.data);
          } else {
            toast.error("Network Error");
            reject(error);
          }
        });
    });
  }
}

export default ApiClient;
