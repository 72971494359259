
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import './style.scss';
import AuthLayout from '../../components/AuthLayout';
import crendentialModel from '../../models/credential.model';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Resetpassword = () => {
    const history = useNavigate();

    const user = useSelector((state: any) => state?.user)
    useEffect(() => {
        if (localStorage.getItem('token')) {
            history('/dashboard')
        }
    }, [])

    const formValidation = [
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
        { key: 'newPassword', minLength: 8 },
    ]

    const [form, setForm]: any = useState({ confirmPassword: '', newPassword: '', code: '', id: '' });
    const [submitted, setSubmitted] = useState(false)
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });

    const getError = (key: any) => {
        return methodModel.getError(key, form, formValidation)
    }

    useEffect(() => {
        let prm = {
            // email: methodModel.getPrams('email'),
            id: methodModel.getPrams('id'),
            code: methodModel.getPrams('code'),
        }

        setForm({ ...form, ...prm })
    }, [])

    const hendleSubmit = (e: any) => {
        e.preventDefault();
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        loader(true)
        let payload = {
            password: form.newPassword,
            code: form.code,
        }
        ApiClient.put('reset/password', {},payload).then(res => {
            if (res.success) {
                history('/login');
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
            }
            loader(false)
        })
    };


    return (
        <>

            <AuthLayout>
                <form
                    className="max-w-lg mx-auto overflow-y-auto px-4 py-4"
                    onSubmit={hendleSubmit}
                >
                    <div className=" mb-4">
                        <h3 className="mb-4 text-[18px] text-[32px] font-regular  text-neutral-800">New Password</h3>

                        <p className=' '>Please create a new password that you don’t use on any other site.</p>
                    </div>

                    <div className="mb-3">
                        <div className="inputWrapper mb-3">
                                        {/* <label>Code</label> */}
                                  <label className='text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]  '>Code</label>
                                    <input
                                        type="text"
                                        className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1"
                                        value={form.code}
                                        onChange={e => setForm({ ...form, code: e.target.value })}
                                        placeholder="Code"
                                        required
                                    />
                                            </div>
                        {/* <label>New Password<span className="start">*</span></label> */}

                        <div className="mb-3">
                        <label className='text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]  '>New Password</label>
                            <div className="inputWrapper">
                                <input
                                    type={eyes.password ? 'text' : 'password'}
                                    className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1"
                                    value={form.newPassword}
                                    min="12"
                                    onChange={e => setForm({ ...form, newPassword: e.target.value })}
                                    placeholder="New Password"
                                    required
                                />
                                <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                            </div>

                            {submitted && getError('newPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                        </div>
                        <div className="inputWrapper">
                            {/* <label>Confirm Password<span className="start">*</span></label> */}
                            <label className='text-neutral-800 font-regular  mb-2 inline-flex  text-[14px]  '>Confirm Password</label>
                            <div className="inputWrapper">
                                <input
                                    type={eyes.confirmPassword ? 'text' : 'password'}
                                    className="w-full rounded-[16px] border border-neutral-300 h-12 bg-white rounded-[24px] px-4 py-1"
                                    value={form.confirmPassword}
                                    maxLength={50}
                                    onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
                                    placeholder="Confirm Password"
                                    required
                                />
                                <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                            </div>
                            {submitted && getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}
                        </div>
                    </div>


                    <div className=" mt-6">

                        <button type="submit" className="w-full bg-[#5C0BAD] rounded-[30px] text-neutral-100 focus:ring-4 h-11 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center  mb-4">

                            Save
                        </button>
                    </div>

                    {/* <p className='accopuntt'> Just Remember?<a class="sign_up" href="/login"> Sign Up</a></p> */}
                </form>
            </AuthLayout>


        </>
    );
};

export default Resetpassword;
