import React from "react";
import ReactEcharts from "echarts-for-react";

const Barchat = () => {
  const getOption = () => {
    return {
      title: {
        text: "",
      },
      tooltip: {},
      xAxis: {
        type: "category",
        data: [
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Sales",
          type: "bar",
          data: [120, 200, 150, 80, 30, 60],
        },
      ],
    };
  };

  return (
    <div className="lg:h-[250px] 2xl:h-[350px]">
      <ReactEcharts
      option={getOption()}
      className="reactcharts_graph"
    />
    </div>
  );
};

export default Barchat;
