import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import { useSelector } from "react-redux";
import ImageUpload from "../../components/common/ImageUpload";
import datepipeModel from "../../models/datepipemodel";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const AddEdit = () => {
  const { id } = useParams();
  const [images, setImages] = useState({ image: "" });
  const [form, setform] = useState({
    id: "",
    fullName:'',
    email:'',
    gender:'',
    role:'',
    birthday:'',
    UniversityName:''
    // status: "active",
  });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state)=>state?.user)
  const formValidation = [
    { key: "email", required: true, message: "Email is required" },
    { key: "gender", required: true, message: "Gender is required" },
    { key: "role", required: true, message: "Role is required" },
  ];


  const handleSubmit = (e) => {
    e.preventDefault();
  
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      ...images,
      firstName:form.fullName,
      lastName:form.fullName,
    };
    if (value.id) {
      method = "put";
      url = `${shared.editApi}/${value.id}`;
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url,value, method).then((res) => {
      if (res.success) {
        // ToastsStore.success(res.message)
        history(`/${shared.url}`);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`${shared.detailApi}/${id}`, { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = form;

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });

          payload.id = id;
          setform({
            ...payload,
          });

          let img = images;
          Object.keys(img).map((itm) => {
            img[itm] = value[itm];
          });
          setImages({ ...img });
        }
        loader(false);
      });
    }
  }, [id]);

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
  };

  const getError = (key) => {
    return submitted
      ? methodModel.getError(key, form, formValidation)?.message
      : "";
  };
  const currentDate = new Date();
  const handleDateChange = (date ) => {
    if (date) {
      const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`; // Format to mm-dd-yyyy
      setform({ ...form, birthday: formattedDate });
    } else {
      setform({ ...form, birthday: null });
    }
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-2xl font-semibold text-[#111827]">
                  {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                </h3>
                <p class="text-sm font-normal text-[#75757A]">
                  Here you can see all about your {shared.addTitle}
                </p>
              </div>
            </div>

            <h3 className="ViewUser mb-3"></h3>
            <div className="grid grid-cols-2 gap-4">
              <div className=" mb-3">
                <FormControl
                  type="text"
                  label="Name"
                  value={form.fullName}
                  onChange={(e) => setform({ ...form, fullName: e })}
                  required
                />
              </div>
              <div className=" mb-3">
                {/* <FormControl
                  type="date"
                  name="date"
                  label="Birth Date"
                  value={datepipeModel.datetostring(form?.birthday)}
                  onChange={(e) => {
                    setform({ ...form, birthday: e });
                  }}
                  required
                  // error={
                  //   getDateErrr(form.birthday) && submitted
                  //     ? "Entered date is less then Current Date"
                  //     : ""
                  // }
                /> */}
                    <DatePicker
        selected={form.birthday ? new Date(form.birthday) : null} // Convert to Date object
        onChange={handleDateChange}
          className="relative border border-gray-200 bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-3"
        dateFormat="MM-dd-yyyy" // Display format with full year
        maxDate={currentDate} // Set maximum date
        required
        placeholderText="mm-dd-yyyy" // Placeholder for format guidance
      />
              </div>
              <div className=" mb-3">
                <FormControl
                  type="radio"
                  label="Profile Type"
                  value={form?.role}
                  onChange={(e) => {
                    setform({ ...form, role: e });
                  }}
                  options={[
                    {id:'user',name:'user'},
                    {id:'university_admin',name:'University Admin'},
                  ]}
                  required
                  error={getError('role')}
                />
              </div>
              <div className=" mb-3">
                <FormControl
                  type="radio"
                  label="Gender"
                  value={form?.gender}
                  onChange={(e) => {
                    setform({ ...form, gender: e });
                  }}
                  options={[
                    {id:'male',name:'Male'},
                    {id:'female',name:'Female'},
                  ]}
                  required
                  error={getError('gender')}
                />
              </div>
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="University Name"
                  label="University Name"
                  value={form.UniversityName}
                  onChange={(e) => setform({ ...form, UniversityName: e })}
                  required
                />
              </div>
              <div className=" mb-3">
                <FormControl
                  type="email"
                  name="Email Address"
                  label="Email Address"
                  value={form.email}
                  disabled={form.id?true:false}
                  onChange={(e) => setform({ ...form, email: e })}
                  required
                />
              </div>
              {!form.id?<>
                <div className=" mb-3">
                <FormControl
                  type="password"
                  label="Password"
                  value={form.password}
                  onChange={(e) => setform({ ...form, password: e })}
                  required
                />
              </div>
              </>:<></>}

              <div className="">
              <label className='lablefontcls'>Image</label><br></br>
              <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} multiple={false} />
            </div>
             
            
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="text-neutral-100 bg-[#283A97] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEdit;
