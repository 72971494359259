
  const shared = {
    check: "Quiz",
    title: "Quiz",
    addTitle: "Quiz",
    url: "Quiz",
    addApi: "quiz",
    editApi: "quiz",
    detailApi: "quiz",
    listApi: "recommanded/event",
    addCalendarEvent:"calendar/event",
    getCalendarList:"calendar/event/list",
    upcomingListApi: "events/list",
    statusApi: "task",
    deleteApi: "quiz",
  
    taskApi:"task/list",
    resourcesApi:"resource/list",
  
    
  };
  
  export default shared;
  