import { useEffect, useState , useRef} from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import crendentialModel from "../../models/credential.model";
import datepipeModel from "../../models/datepipemodel";
import Modal from "../../components/common/Modal";
import Members from "../../components/Events/Member";
import Table from "../../components/Table";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useNavigate } from "react-router-dom";
import environment from "../../environment";
import { useSelector } from "react-redux";
import { HiMiniPlusCircle, HiOutlineArrowRightCircle } from "react-icons/hi2";
import { HiOutlineChevronDown } from "react-icons/hi";
import { LiaEdit } from "react-icons/lia";
import { Swiper, SwiperSlide } from "swiper/react";
import { useFormik } from "formik";
import "swiper/css";
import "swiper/css/navigation";
import axios from "axios";
import { Navigation } from "swiper/modules";
import { MdChevronRight } from "react-icons/md";
import { TiHome } from "react-icons/ti";
import { IoMdCheckmark } from "react-icons/io";
import { PiCurrencyCircleDollarFill } from "react-icons/pi";

import { Calendar, theme } from "antd";
import "./calender.scss";
import shared from "./shared";
import { GoDotFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import AddTask from "./addTask";
import { toast } from "react-toastify";
import AddResources from "./addResources";
import * as Yup from "yup";
const Dashboard = () => {
  const [events, setEvents] = useState([]);

  const [categories, setCategories] = useState([]);
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    status: "",
    key: "",
    sorder: "",
    sortBy: "",
    type: "ongoing",
  });
  let [isOpenResources, setIsOpenResources] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [modalData, setModalData] = useState();
  const [challenges, setChallenges] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [vediosList, setVediosList] = useState([]);
  const [type, setType] = useState("");
  const [module, setModule] = useState("");
  const [images, setImages] = useState({ image: "" });
  const [category, setCategory] = useState("");
  const [showError, setShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const TaskListCancelTokenSource = useRef(null);
  const ResourcesListCancelTokenSource = useRef(null);
  const vedioListCancelTokenSource = useRef(null);
  const challengesListCancelTokenSource = useRef(null);
  const [form, setForm] = useState({
    discription: "",
    type: "",
    priortyTask: false,
    uploadDocument: [],
    insertLink: [],
    name: "",
  });
  const [resourcesForm, setResourcesForm] = useState({
    discription: "",
    type: "",
    priortyResources: false,
    uploadDocument: [],
    insertLink: [],
  });
  const user = useSelector((state) => state?.user);
  const history = useNavigate();
  // Resources Modal
  function closeResourcesModal() {
    setIsOpenResources(false);
    setIsOpenResources(false);
    setResourcesForm({
      type: "",
      priortyResources: false,
      uploadDocument: [],
      insertLink: [],
      discription: "",
    });
    setType("");
    setModule("");
    setImages({ image: "" });
    setCategory("");
    formik.setValues({ pros: "", cons: "" });
  }
  function openResourcesModal() {
    setIsOpenResources(true);
  }
  const formik = useFormik({
    initialValues: {
      pros: "",
      cons: "",
      name: "",
    },
    validationSchema: Yup.object({
      pros: Yup.string().required("Pros is Required"),
      cons: Yup.string().required("Cons is Required"),
    }),
    onSubmit: async () => {
      setSubmitted(true);
      const method = "post";
      const url = "resource";

      if (resourcesForm?.discription == "") {
        // setSubmitted(true);
        toast?.error("Please fill all fields");
        return;
      }

      let value = {
        ...resourcesForm,
        category: category.toString(),
        props: formik?.values?.pros,
        cons: formik?.values?.cons,
        name: formik?.values?.name,
      };

      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          setShowError(false);
          toast.success(res?.message);
          loader(false);
          setCategory("");
          setResourcesForm({
            type: "",
            priortyResources: false,
            uploadDocument: [],
            insertLink: [],
            discription: "",
          });
          formik.setValues({ pros: "", cons: "", name: "" });
          setImages({ image: "" });
          setIsOpenResources(false);
          ResourcesList();
        } else {
          setTimeout(() => {
            toast?.error(res?.message);
          }, 1000);
        }
      });
    },
  });
  //
  const TaskList = () => {
    let payload = {
      addedBy: user?.id,
      isTask: true,
      page: 1,
      count: 10,
      search: "",
      type: "",
      category: "",
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.taskList}`, payload,"","",TaskListCancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setTasks(res?.data);
      }
    });
  };
  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setForm({ ...form, uploadDocument: [e?.value] });
  };
  const options = categories?.map(({ id, name }) => {
    return { id: id, name: name };
  });
  const ResourcesList = () => {
    let payload = {
      addedBy: user?.id,
      isResource: true,
      page: 1,
      count: 10,
      search: "",
      type: "",
      category: "",
      status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.resourcesList}`, payload ,"","",ResourcesListCancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setResourcesList(res?.data);
      }
    });
  };
  function closeModal() {
    setIsOpen(false);
    setForm({
      type: "",
      priortyTask: false,
      uploadDocument: [],
      insertLink: [],
      discription: "",
    });
    setType("");
    setModule("");
    setImages({ image: "" });
    setCategory("");
  }

  function openModal() {
    setIsOpen(true);
    setForm({
      discription: "",
      type: "",
      priortyTask: false,
      uploadDocument: [],
      insertLink: [],
    });
  }
  const vedioList = () => {
    let payload = {
      userid: user?.id,
      // status: "active",
    };
    loader(true);
    ApiClient.get(`${shared?.videoList}`, payload ,"","",vedioListCancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setVediosList(res?.data);
      }
    });
  };
  const lastThreeTasks = tasks.slice(-3).reverse();
  const lastThreeResources = resourcesList.slice(-4).reverse();

  useEffect(() => {
    TaskListCancelTokenSource.current = axios.CancelToken.source();
    ResourcesListCancelTokenSource.current = axios.CancelToken.source();
    vedioListCancelTokenSource.current = axios.CancelToken.source();
    challengesListCancelTokenSource.current = axios.CancelToken.source();
    TaskList();
    ResourcesList();
    vedioList();
    getChallengesList();
    return () => {
      if (TaskListCancelTokenSource.current) {
        TaskListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (ResourcesListCancelTokenSource.current) {
        ResourcesListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (vedioListCancelTokenSource.current) {
        vedioListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
      if (challengesListCancelTokenSource.current) {
        challengesListCancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
   
  }, []);
  const getEvents = (p = {}) => {
    let f = {
      ...filters,
      ...p,
      email: user.email,
      // addedBy:user?._id||'',
    };
    if (user.customerRole?._id == environment.glRoleId)
      f.groupId = user.groupId?._id || "";
    setLoader(true);
    ApiClient.get("api/user-events", f).then((res) => {
      setLoader(false);
      if (res.success) {
  
        let data = res?.data || [];
        // let data = res?.data
        setEvents(
          data.map((itm) => {
            itm.id = itm._id;
            return {
              ...itm,
              // ...itm.eventDetails
            };
          })
        );
        
        setTotal(res.total || data.length);
      }
    });
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (form?.discription?.length == 0 && type == "" && module == "") {
      toast?.error("Please fill all fields  ");
      return;
    } else {
      let value = {
        ...form,
        category: category.toString(),
        name: "",
      };
      let url = "task";
      let method = "post";
      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          setShowError(false);
          toast.success(res?.message);
          loader(false);
          setCategory("");
          setForm({});
          setImages({ image: "" });
          setIsOpen(false);
          TaskList();
        } else {
          setTimeout(() => {
            toast?.error(res?.message);
          }, 1000);
        }
      });
    }
  };
  const getChallengesList = () => {
    let payload = {
      user_id: user?.id,
    };
    loader(true);
    ApiClient.get("user/challenges/list?status=active","","","",challengesListCancelTokenSource.current.token).then((res) => {
      if (res?.success == true) {
        loader(false);
        setChallenges(res?.data);
  
      }
    });
  };

  const handleTask = (ele) => {
    // localStorage.setItem('previousPath',2 );
    history("/task/mytask");
  };
  const handleVedio = (ele) => {
    history(
      `/subModules/${ele.category_type}/${ele.category_name}/${ele?.category_id}`
    );
  };
  const handleResources = () => {
    history("/resources/myresources");
  };
  useEffect(() => {
    // getEvents()
    // let eventId = methodModel.getPrams('eventId')
    // if (eventId) eventDetail(eventId)
  }, []);

  const ListHtml = ({ row }) => {
    let itm = row;
    return (
      <>
        <div className="border border-gray-100 shadow-lg  bg-white rounded p-4 flex flex-col  justify-between leading-normal">
          <div className="mb-6 flex flex-col gap-y-2">
            <p className="text-sm text-gray-600 flex items-center">
              <span className="material-symbols-outlined mr-1">
                calendar_today
              </span>
              {datepipeModel.datetime(itm?.date)}
            </p>
            <div className="text-gray-900 font-bold text-xl mb-2 capitalize">
              {itm?.title}
            </div>
            <p
              className="text-gray-700 text-base"
              dangerouslySetInnerHTML={{ __html: itm?.address }}
            ></p>
            <p className="text-sm">Max Member: {itm?.capacity}</p>
          </div>
          <div className="flex  gap-4">
            <button
              type="button"
              onClick={() => history(`/event/detail/${itm.id}`)}
              className="text-neutral-100 bg-orange-500 w-[49%] hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              View Event
            </button>
            {/* {itm.meetingStatus == 'completed' || filters.type != 'ongoing'? <></> : <>
                        <button type="button" onClick={() => endEvent(itm.id)}
                            className="text-neutral-100 bg-gradient-to-r w-[49%] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">End Event</button>
                    </>} */}
          </div>
          {/* <div className="flex items-center">
                            <img className="w-10 h-10 rounded-full mr-4" src="/img/jonathan.jpg" alt="Avatar of Jonathan Reinink"/>
                                <div className="text-sm">
                                    <p className="text-gray-900 leading-none">Jonathan Reinink</p>
                                    <p className="text-gray-600">Aug 18</p>
                                </div>
                        </div> */}
        </div>
      </>
    );
  };

  return (
    <>
      <Layout>
        <div className=" ">
          <div className="grid grid-cols-12 gap-6 ">
            <div className="col-span-12  lg:col-span-8  2xl:col-span-8 lg:px-8 2xl:px-2">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 2xl:gap-16 2xl:px-14">
                <div className="mb-4 2xl:mb-6 3xl:mb-8 ">
                  <div
                    className={`${
                      lastThreeTasks >= 0 ? "" : "border"
                    } border-neutral-400 p-5 2xl:p-6 rounded-[16px] h-64 2xl:h-auto `}
                  >
                    <div className="flex items-center gap-2 mb-4 2xl:mb-8">
                      <p className="flex items-center gap-2 text-[16px]  xl:text-[20px] 2xl:text-[32px]  text-neutral-800 font-semibold noto">
                        Tasks
                      </p>
                      <img src="../assets/img/lyfnew/arrow.svg" />
                    </div>

                    {/* add if no data avaiable */}

                    {lastThreeTasks >= 0 ? (
                      <>
                        {" "}
                        <div className="bg-[#F4EAFE] rounded-2xl p-4 flex items-center justify-center gap-4 h-40">
                          <div className="">
                            <p className="mb-4 text-[12px] 2xl:text-[14px]">
                              Add your first task
                            </p>
                            <div
                              className="bg-primary flex items-center mx-auto h-16 w-16 justify-center rounded-full cursor-pointer"
                              onClick={openModal}
                            >
                              <FiPlus className="text-2xl  text-white" />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {lastThreeTasks?.map((ele, ind) => {
                          return (
                            <div
                              className="border-b border-neutral-300 pb-2 mb-2 2xl:pb-3 2xl:mb-3 3xl:pb-4 3xl:mb-4 pb-2 mb-2 last:!mb-0 last:!pb-0 last:border-b-0"
                              onClick={() => handleTask(ele)}
                            >
                              <p
                                className={`text-[15px] font-medium text-[10px] xl:text-[12px] capitalize
                                ${
                                  ele?.category?.type === "wealth"
                                    ? "text-[#58A541]"
                                    : ""
                                }
                                ${
                                  ele?.category?.type === "self"
                                    ? "text-[#0079B3]"
                                    : ""
                                }
                                ${
                                  ele?.category?.type === "social"
                                    ? "text-[#FFA03B]"
                                    : ""
                                }`}
                              >
                                {ele?.category?.type}
                              </p>
                              <div className="flex items-center justify-between">
                                <p
                                  className="font-regular text-[12px] 2xl:text-[18px]"
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.discription,
                                  }}
                                ></p>
                                <img
                                  className="cursor-pointer"
                                  src="../assets/img/lyfnew/arrow.svg"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-4 2xl:mb-6 3xl:mb-8 ">
                  <div
                    className={`${
                      vediosList >= 0 ? "" : "border"
                    } border-neutral-400 p-5 2xl:p-6 rounded-[16px] h-64 2xl:h-auto `}
                  >
                    <div className="flex items-center gap-2 mb-4 2xl:mb-8">
                      <p className="flex items-center gap-2 text-[16px]  xl:text-[20px] 2xl:text-[32px] text-neutral-800 font-semibold noto">
                        Learn
                      </p>
                      <img src="../assets/img/lyfnew/arrow.svg" />
                    </div>
                    {vediosList >= 0 ? (
                      <>
                        {" "}
                        <div className="bg-[#F4EAFE] rounded-2xl p-4 flex items-center justify-center gap-4 h-40">
                          <div className="">
                            <p className="mb-4 text-[12px] 2xl:text-[14px]">
                              Watch your first module
                            </p>
                            <div className="bg-primary flex items-center mx-auto h-16 w-16 justify-center rounded-full cursor-pointer">
                              <FiPlus className="text-2xl  text-white" />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {vediosList?.map((ele, ind) => {
                          return (
                            <div
                              className="checksboxes w-full mb-4 2xl:mb-4"
                              onClick={() => handleVedio(ele)}
                            >
                              <label className="w-full border border-neutral-300 font-regular text-[12px] 2xl:text-[18px] bg-neutral-100 rounded-[8px] flex items-center cursor-pointer gap-2 px-4 2xl:py-4 py-3">
                                <input
                                  className="sr-only peer"
                                  name=""
                                  type="radio"
                                  checked
                                />
                                <div className="w-5 h-5 text-2xl text-neutral-100 flex items-center text-white  border-[#58A541]  peer-checked:bg-[#58A541] justify-center gap-2 transition duration-300 ease-in-out border-2 bg-white rounded-full peer-checked:text-neutral-100">
                                  <IoMdCheckmark className="" />
                                </div>

                                <p className="text-[12px] 2xl:text-[18px] line-clamp-1">
                                  {ele?.category_name}
                                </p>
                              </label>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-12 mt-4 3xl:mt-10 2xl:gap-16 2xl:px-14">
                <div className="sliders_data mb-4 2xl:mb-8">
                  <div className="flex items-center gap-2">
                    <p className="flex items-center gap-2 text-[16px]  xl:text-[20px] 2xl:text-[32px]  text-neutral-800 font-semibold noto">
                      Challenges
                    </p>
                    <img src="../assets/img/lyfnew/arrow.svg" />
                  </div>
                </div>

                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1100: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1300: {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {challenges?.map((ele) => {
                    // Calculate the total number of videos
                    const totalVideos = ele?.videos?.length || 0;

                    // Calculate the number of watched videos
                    const watchedVideos =
                      ele?.videos?.filter((v) => v.isWatched === true).length ||
                      0;

                    return (
                      <SwiperSlide key={ele.id}>
                        <div className="bg-[#FFFDF5] px-6 py-4">
                          <div className="border-b border-[#E3B30A]">
                            <p className="font-semibold">
                              {watchedVideos}/{totalVideos}
                            </p>
                            <div className="flex items-center justify-between mt-2 pb-3">
                              <p>{ele?.challenge_data?.name}</p>
                              <div className="flex items-center gap-2">
                                <PiCurrencyCircleDollarFill className="text-2xl" />
                                <p>{ele?.challenge_data?.coin}</p>
                              </div>
                            </div>
                          </div>
                          {ele?.videos?.map((elee) => {
                            return (
                              <div
                                className="flex flex-col gap-4 mt-3 custom-scrollbar-new pr-4"
                                key={elee.id}
                              >
                                <div className="flex justify-between items-center border-b border-neutral-300 py-2 2xl:py-3 3xl:py-4">
                                  <p className="line-clamp-1 w-52 text-[12px] 2xl:text-[18px]">
                                    {elee?.video_data?.name}
                                  </p>
                                  <div className="relative w-5 h-5 flex items-center justify-center">
                                    <div
                                      className={`relative w-full h-full rounded-full border-2 border-[#E7BC27] ${
                                        elee.isWatched ? "bg-wealth" : ""
                                      } overflow-hidden`}
                                    >
                                      <div className="absolute inset-y-0 left-0 bg-[#E7BC27] h-full" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </SwiperSlide>
                    );
                  })}

                  {/* {challenges?.map((ele) => {
                    return (
                      <SwiperSlide>
                        <div className="bg-[#FFFDF5] px-6 py-4">
                          <div className="border-b border-[#E3B30A]">
                            <p className="font-semibold">9/10</p>
                            <div className="flex items-center justify-between mt-2 pb-3">
                              <p>{ele?.challenge_data?.name}</p>
                              <div className="flex items-center gap-2">
                                <PiCurrencyCircleDollarFill className="text-2xl" />
                                <p>{ele?.challenge_data?.coin}</p>
                              </div>
                            </div>
                          </div>
                          {ele?.videos?.map((elee) => {
                            return (
                              <div
                                className="flex flex-col gap-4 mt-3 custom-scrollbar-new pr-4"
                                key={elee.id}
                              >
                                <div className="flex justify-between items-center border-b border-neutral-300 py-2 2xl:py-3 3xl:py-4">
                                  <p className="line-clamp-1 w-52 text-[12px] 2xl:text-[18px]">
                                    {elee?.video_data?.name}
                                  </p>
                                  <div className="relative w-5 h-5 flex items-center justify-center">
                                    <div className={`relative w-full h-full rounded-full border-2 border-[#E7BC27] ${ele?.isWatched == true ? "bg-wealth" :"" } overflow-hidden`}>
                                      <div className="absolute inset-y-0 left-0 bg-[#E7BC27] h-full" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </SwiperSlide>
                    );
                  })}    */}
                </Swiper>
              </div>
            </div>
            <div className="col-span-12  lg:col-span-4  2xl:col-span-4">
              <div className="2xl:ml-6 bg-[#F4EAFE] rounded-[32px] p-4 2xl:p-8 h-full">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="flex items-center gap-2 mb-4 2xl:mb-8">
                      <p className="flex items-center gap-2 text-[16px]  xl:text-[20px] 2xl:text-[32px]  text-neutral-800 font-semibold noto">
                        Attend
                      </p>
                      <img src="../assets/img/lyfnew/arrow.svg" />
                    </div>

                    <div className="w-full rounded-full ">
                      <Calendar className="antcalender" fullscreen={false} />
                    </div>
                  </div>

                  <div className="mt-6 2xl:mt-8">
                    <div className="flex items-center gap-2 mb-4 2xl:mb-8">
                      <p className="flex items-center gap-2 text-[16px]  xl:text-[20px] 2xl:text-[32px]  text-neutral-800 font-semibold noto">
                        Resources
                      </p>
                      <img
                        src="../assets/img/lyfnew/arrow.svg"
                        onClick={() => handleResources()}
                      />
                    </div>
                    {lastThreeResources >= 0 ? (
                      <>
                        {" "}
                        <div className="bg-[#ffffff] rounded-2xl p-4 flex items-center justify-center gap-4 h-40">
                          <div className="">
                            <p className="mb-4">Add your first Resource</p>
                            <div
                              onClick={openResourcesModal}
                              className="bg-primary flex items-center mx-auto h-16 w-16 justify-center rounded-full cursor-pointer"
                            >
                              <FiPlus className="text-2xl  text-white" />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {lastThreeResources?.map((ele, index) => {
                          return (
                            <div className="flex ">
                              <GoDotFill className="text-2xl flex items-center gap-2 font-semibold mb-3 text-primary" />

                              <p
                                className="flex items-center gap-2 font-semibold mb-3 text-primary"
                                // dangerouslySetInnerHTML={{
                                //   __html: ele?.discription,
                                // }}
                              >
                                {ele?.name}
                              </p>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <AddTask
        module={module}
        setCategory={setCategory}
        setShowError={setShowError}
        setForm={setForm}
        options={options}
        setCategories={setCategories}
        showError={showError}
        imageResult={imageResult}
        HandleSubmit={HandleSubmit}
        form={form}
        images={images}
        category={category}
        type={type}
        isOpen={isOpen}
        closeModal={closeModal}
        lastThreeTasks={lastThreeTasks}
      />
      <AddResources
        images={images}
        setCategory={setCategory}
        openResourcesModal={openResourcesModal}
        isOpenResources={isOpenResources}
        options={options}
        category={category}
        submitted={submitted}
        showError={showError}
        setImages={setImages}
        setCategories={setCategories}
        resourcesForm={resourcesForm}
        setResourcesForm={setResourcesForm}
        formik={formik}
        setShowError={setShowError}
        setIsOpenResources={setIsOpenResources}
        closeResourcesModal={closeResourcesModal}
      />
      {modalData ? (
        <>
          <Modal
            title={modalData.title}
            body={
              <>
                <Members eventId={modalData.id} eventDetail={modalData} />
              </>
            }
            result={(e) => {
              setModalData("");
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
