const User = (state = { isLoggedIn: false }, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...action.payload, isLoggedIn: true };
      break;
    case "LOGOUT_SUCCESS":
      return { isLoggedIn: false };
    default:
      return state;
  }
};

export default User;
