const types=[
  {id:'self',name:'Self'},
  {id:'social',name:'Social'},
  {id:'wealth',name:'Wealth'},
]
const shared = {
    check: "Quiz",
    title: "Quiz",
    addTitle: "Quiz",
    url: "Quiz",
    addApi: "quiz",
    editApi: "quiz",
    detailApi: "quiz",
    taskList:"task/list",
    recommandedTasklistApi: "recommanded/task",
    upcomingListApi: "events/list",
    statusApi: "task",
    deleteApi: "user/task",
     taskApi:"task/list",
    resourcesApi:"resource/list",
    archivedList :"archived/task/list",
    moveTask:"archived/task",
    archiveTask:"archive/task",
  removedTask:"removed/task",
  addToMyTask:"moved/task",
    types: [...types],
userDelete :"user/task"
    
  };
  
  export default shared;